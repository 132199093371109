import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AddSection from '../components/add_section'
import Button from '../components/button'
import ConfirmationDialog from '../components/dialog/confirmation_dialog'
import Input from '../components/input'
import Modal from '../components/modals'
import { Sidebar } from '../components/navigation/sidebar'
import app_api from '../config/api'
import { bgColor } from '../data/dummy_data'
import { classNames } from '../helpers/classname'
import { examSchema } from '../schema'
import { Switch } from '@headlessui/react'
import moment from 'moment'
import Select from "react-select"
const examStatus = [
  { id: 0, name: 'active' },
  { id: 1, name: 'draft' },
]

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    name: '',
    previewMode: false,
    is_active: true,
    duration: '',
    status: '',
    expireInDays:''
  },
}

export default function Exam() {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [exams, setExams] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const navigate = useNavigate()
  // const [singleExam, setSingleExam] = useState([])

  useEffect(() => {
    try {
    } catch (err) {}
  }, [])

  const onEditOpen = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: exams[index],
    }))
  }

  const onDeleteOpen = (id, index) => {
    setModal((prev) => ({ ...prev, id: id, index: index }))
    setConfirmationDialog(true)
  }

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const createExam = () => {
    app_api
      .post('/exam', { ...modal.data, is_active: true })
      .then((res) => {
        cleanModalData()
        getExamData()
      })
      .catch((err) => {
        setError(err.toString)
      })
  }

  const updateExam = (id) => {
    app_api
      .patch(`/exam/${id}`, modal.data)
      .then((res) => {
        let updatedExamData = [...exams]
        updatedExamData[modal.index] = modal.data
        setExams(updatedExamData)
        cleanModalData()
      })
      .catch((err) => {})
  }

  const onDeleteLocation = () => {
    const { id, index } = modal
    app_api
      .patch(`/exam/archive/${id}`, {archive: 1})
      .then((res) => {
        let updateExam = [...exams]
        updateExam.splice(index, 1)
        setExams(updateExam)
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
      })
  }

  const onModalHandleChange = (name, value) => {
    setModal((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [name]: value,
      },
    }))
  }

  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    const { name, previewMode, is_active, duration, status, expireInDays } = data
    return (
      <Formik
        initialValues={data}
        validationSchema={examSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          if (type === 'add') {
            app_api
              .post('/exam', { ...values, is_active: true })
              .then((res) => {
                cleanModalData()
                getExamData()
                setSubmitting(false)
              })
              .catch((err) => {
                setError(err.toString)
              })
          } else {
            delete values.questions
            app_api
              .patch(`/exam/${edit_id}`, { ...values, previewMode: enabled })
              .then((res) => {
                let updatedExamData = [...exams]
                updatedExamData[modal.index] = modal.data
                setEnabled(res.data.previewMode)
                setExams(updatedExamData)
                cleanModalData()
                getExamData()
                setSubmitting(false)
              })
              .catch((err) => {})
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <Modal
            title={type === 'add' ? 'Add Exam' : 'Edit Exam'}
            open={state}
            setOpen={() => {
              setModal((prev) => ({ ...prev, state: false }))
              cleanModalData()
            }}
          >
            <form onSubmit={handleSubmit}>
              <div className="text-left mt-4">
                <Input
                  name="name"
                  label="Name"
                  placeholder="Please enter exam name"
                  value={values.name}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // onChange={(event) =>
                  //   onModalHandleChange(event.target.name, event.target.value)
                  // }
                />
                {touched.name && (
                  <p className="text-red-700 error_msg">{errors.name}</p>
                )}
                <div className="mt-4"></div>
                <Input
                  type="number"
                  name="duration"
                  label="Duration"
                  placeholder="Duration in minutes"
                  value={values.duration}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // onChange={(event) =>
                  //   onModalHandleChange(event.target.name, event.target.value)
                  // }
                />
                {touched.duration && (
                  <p className="text-red-700 error_msg">{errors.duration}</p>
                )}
                <div className="mt-4"></div>
                <label
                  htmlFor="status"
                  className="block text-sm font-medium text-gray-700"
                >
                  Status
                </label>
                <Select
                  id="status"
                  name="status"
                  className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  value={values.status
                    ? examStatus.map(l => ({
                      value: examStatus.find(e => e.name == values.status.toLowerCase())?.id,
                      label: examStatus.find(e => e.name == values.status.toLowerCase())?.name.toUpperCase()
                    })) : null
                  }
                  placeholder="Please select the status of the exam"
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={(option)=>{
                    setValues({
                      ...values,
                      status: option.label.toLowerCase()
                    })
                  }}
                  options={
                    examStatus?.map((e) => ({
                      ...e,
                      label: e.name.toUpperCase(),
                      value: e.id
                    }))
                  }
                >
                </Select>
                {touched.status && (
                  <p className="text-red-700 error_msg">{errors.status}</p>
                )}
                <div className="mt-4"></div>
                <label
                  htmlFor="expireInDays"
                  className="block text-sm font-medium text-gray-700"
                >
                  Validity:
                </label>
                <Input
                      name="expireInDays"
                      type="number"
                      placeholder="Number of days"
                      value={values.expireInDays}
                      className="mb-2"
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                {touched.expireInDays && (
                  <p className="text-red-700 error_msg">{errors.expireInDays}</p>
                )}
                <div className="mt-4"></div>
                <Switch.Group
                  as="div"
                  className="flex items-center justify-between"
                >
                  <span className="flex flex-grow flex-col">
                    <Switch.Label
                      as="span"
                      className="text-sm font-medium text-gray-900"
                      passive
                    >
                      Exam Preview
                    </Switch.Label>
                  </span>
                  <Switch
                    checked={enabled}
                    onChange={setEnabled}
                    className={classNames(
                      enabled ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 toggleButton'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        enabled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </Switch.Group>

                <div className="mt-4 sm:mt-6">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    // onClick={() => {
                    //   type === 'add' ? createExam() : updateExam(edit_id)
                    // }}
                  >
                    {isSubmitting
                      ? type === 'add'
                        ? 'Adding...'
                        : 'Saving...'
                      : type === 'add'
                      ? 'Add Exam'
                      : 'Update Exam'}
                  </Button>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }

  const getExamData = () => {
    app_api
      .get('/exam')
      .then((res) => {
        setLoading(false)
        setError(null)
        let data = res.data.filter(l => l.archive == 0)
        setExams(data)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getExamData()
  }, [])
  return (
    <Sidebar>
      <ConfirmationDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteLocation}
      />
      {renderModal()}
      <div className="px-4 pt-2 sm:px-6 lg:px-8 disable-text-selection">
        <AddSection
          title="Exams"
          buttonText="Add"
          onClick={() =>
            setModal((prev) => ({ ...prev, type: 'add', state: true }))
          }
          description="You can add new exams"
        />
        <div
          role="list"
          className="grid grid-cols-1 gap5 mt-3 sm:grid-cols-2 sm:gap-12 lg:grid-cols-3 "
        >
          {exams.map((exam, index) => {
            const bg = bgColor[exams.length % (index + 1)]
            return (
              <div
                onClick={() => {
                  navigate(`/exam/${exam.id}`)
                }}
              >
                <div
                  key={exam.name}
                  className="flex w-[350px] rounded-md shadow-sm mr-4"
                >
                  <div className="flex hover:bg-gray-300 justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md ">
                    <div
                      className="flex-1 px-4 py-2 text-sm truncate hover:bg-gray-200"
                      style={{ cursor: 'pointer' }}
                    >
                      <div className="flex justify-between items-center">
                        <p className="font-semibold text-lg hover:text-gray-600">
                          {exam.name}
                        </p>
                        <p className="text-gray-500 mt-2">
                          {exam?.status == 'active' ? (
                            <div className="flex flex-row items-center">
                              <div className='w-4 h-4 bg-green-600 rounded-full -mt-2'>
                              </div>
                              
                            </div>
                          ) : (
                            <div className="flex flex-row items-center">
                              <div className='w-4 h-4 bg-red-700 rounded-full -mt-2'>
                              </div>
                            </div>
                          )}
                        </p>
                      </div>
                      <p className="text-gray-500 mt-2 ml-1">
                        Questions:&nbsp;
                        <span className="font-semibold">
                          {exam.questions?.length || 0}
                        </span>{' '}
                      </p>
                      <p className="text-gray-500 ml-1 mt-2">
                        Duration:&nbsp;
                        <span className="font-semibold">
                          {exam?.duration}min
                        </span>{' '}
                      </p>

                      <p className="text-gray-500 ml-1 mt-2">
                        Last Edited:&nbsp;
                        <span className="font-semibold">
                          {moment(exam?.updatedAt).format('DD MMM YYYY hh:mm A')}
                        </span>
                      </p>
                      <hr className="mt-3"></hr>
                      <div className=" text-end  mb-1">
                        <button
                          type="button"
                          className="inline-flex w-100 text-gray-400 bg-transparent bg-white rounded-full  focus:outline-none mt-2"
                        >
                          <div className="flex items-center justify-between w-100">
                            <div className="hover:text-red-400 text-red-600 inline-flex items-center text-xs hover:bg-red-700 hover:text-white px-2 py-2 rounded-lg"
                            onClick={(e) => {
                              e.stopPropagation()
                              onDeleteOpen(exam.id, index)
                            }}
                            >
                              <span className='mr-1'>Delete</span>
                              <TrashIcon
                                className="w-4 h-4"
                                aria-hidden="true"
                              />
                            </div>

                            <div className="hover:text-gray-400 inline-flex items-center text-xs  text-gray-700 px-2 py-2 hover:bg-indigo-800 hover:text-white rounded-lg"
                            onClick={(e) => {
                              e.stopPropagation()
                              onEditOpen(exam.id, index)
                              setEnabled(exam.previewMode)
                            }}
                            >
                              <span className='mr-1'>Edit</span>
                              <PencilIcon
                                className="w-5 h-4 "
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* single exam details */}

                  {/* <div className="w-[300px] right-0 top-0 h-[750px] fixed rounded border-l  p-4 overscroll-y-none  bg-white rounded-3xl ">
                    <h1 className="text-xl text-center font-bold">
                      Exam Details
                    </h1>
                    <div>
                      <div className="flex flex-row justify-between mt-4">
                        <h1 className="font-bold text-gray-900 text-capitalize">
                          <span className="text-sm mt-4 mb-2 font-semibold">
                            Name:
                          </span>
                          <br></br>
                          <span className="text-lg rounded-md text-blue-900 w-max py-1">
                            {singleExam?.name}
                          </span>
                        </h1>
                      </div>

                      <h4 className="text-md mt-4 mb-2 font-semibold">
                        Questions:
                      </h4>
                      <button
                        type="button"
                        className="text-white rounded-md px-4 bg-blue-900 text-md py-1 mb-2"
                      >
                        {singleExam?.questions?.length}
                      </button>
                      <h4 className="text-md mt-3 mb-2 font-semibold">
                        Duration:
                      </h4>
                      <button
                        type="button"
                        className="text-white rounded-md px-4 bg-blue-900 text-md py-1 mb-2"
                      >
                        {singleExam?.duration + ' Min'}
                      </button>
                    </div>
                    <label className="mt-3 font-semibold">Status:</label>
                    <br></br>
                    {singleExam?.is_active == true ? (
                      <div className="flex flex-row">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#09bf1b"
                          viewBox="0 0 24 24"
                          strokeWidth={0}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z"
                          />
                        </svg>
                        Active
                      </div>
                    ) : (
                      <div className="flex flex-row">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#ee3425"
                          viewBox="0 0 24 24"
                          strokeWidth={0}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z"
                          />
                        </svg>
                        Draft
                      </div>
                    )}
                    <label className="mt-3 font-semibold">Preview:</label>
                    <br></br>
                    <Switch.Group
                      as="div"
                      className="flex items-center justify-between"
                    > */}
                  {/* <span className="flex flex-grow flex-col">
              <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                
              </Switch.Label>
            </span> */}
                  {/* <Switch
                        checked={singleExam?.previewMode}
                        onChange={null}
                        disabled={true}
                        className={classNames(
                          singleExam?.previewMode
                            ? 'bg-indigo-600'
                            : 'bg-gray-200',
                          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 toggleButton'
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            singleExam?.previewMode
                              ? 'translate-x-5'
                              : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                          )}
                        />
                      </Switch>
                    </Switch.Group> */}

                  {/* <div>
            <h4 className="text-md mt-3 mb-2 font-semibold">Last Edit</h4>
            <p className="rounded-md w-max bg-yellow-200 px-2 text-yellow-800 font-semibold text-sm">
              {moment(singleExam?.updatedAt).format('DD.MM.YYYY hh:mm A')}
            </p>
          </div>

          <h4 className="text-md mt-4 mb-2 font-semibold">Actions</h4>
          <div className="flex flex-wrap justify-between">
          <button
              type="button"
              className="inline-flex items-center text-gray-900 bg-blue-200 border border-gray-300 focus:outline-none hover:bg-gray-200 duration-500 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-2 py-1 mr-2 mb-2 "
            >
              <DocumentDuplicateIcon className="w-5 mr-2" />
              Duplicate
            </button>
            <button
              type="button"
              className="inline-flex items-center text-white bg-red-700 border border-gray-300 focus:outline-none hover:bg-red-500 duration-500 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-2 py-1 mr-2 mb-2"
            >
              <TrashIcon className="w-4 mr-2" />
              Delete
            </button>
          </div> */}
                  {/* <button
            type="button"
            className="mt-6 w-100 pt-auto text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2 mr-2 mb-2"
          >
            Save Changes
          </button> */}
                  {/* </div> */}

                  {/*End single exam details */}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Sidebar>
  )
}
