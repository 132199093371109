import React from 'react'
import Button from '../components/button'
import logo from '../assets/icons/logo.png'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import { ResetPasswordSchema } from '../schema'

const Resetpassword = ({ loading, error, reset }) => {
  const navigate = useNavigate()
  const NavigateTo = () => {
    const item = JSON.parse(localStorage.getItem('moldKey'))
    if (item.role == 'student') {
      navigate('/my-exams')
    } else {
      navigate('/leads')
    }
  }
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          marginTop: '2%',
          marginLeft: '2%',
        }}
      >
        <button
          className="mt-1 ml-1 w-8 h-8"
          style={{
            backgroundColor: '#3730A3',
            color: 'white',
            borderRadius: '20px',
          }}
          type="button"
          onClick={NavigateTo}
        >
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
              />
            </svg>
          </span>
        </button>
      </div>
      <section className="bg-white">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a
            href="#"
            className="flex items-center mb-6 text-2xl font-semibold text-gray-900"
            style={{
              justifyContent: 'center',
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{
                width: '30%',
              }}
            />
          </a>
          <div className="w-full p-6 md:mt-0 sm:max-w-md sm:p-8 shadow rounded-md">
            <h2
              className="mb-1 text-5xl font-bold leading-tight tracking-tight md:text-2xl flex justify-center items-center"
            >
              Change Password
            </h2>
            <div className='w-full flex justify-center items-center text-center'>
              <span className='text-yellow-600 mr-2 ml-2'>
                Password should be 10 characters, Should contain 1 special characters and at least 1 capital letter.
              </span>
            </div>
            <Formik
              initialValues={{ password: '', confirmPassword: '' }}
              validationSchema={ResetPasswordSchema}
              onSubmit={reset}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                values,
                errors,
                touched,
                isValid,
              }) => (
                <form
                  className="mt-4 space-y-4 lg:mt-5 md:space-y-5"
                  onSubmit={handleSubmit}
                  noValidate={true}
                  autoComplete="off"
                >
                  {/* <div>
                <label
                  for="email"
                  className="block mb-2 text-sm font-medium text-gray-500"
                >
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="name@company.com"
                  required=""
                />
              </div> */}

                  <div>
                    {error !== null && (
                      <p className="text-red-700 error_msg error_text">
                        {error}
                      </p>
                    )}
                  </div>
                  <div className="mt-5">
                    <label
                      htmlFor="currentPassword"
                      className="block mb-2 text-xl font-medium text-gray-900"
                    >
                      Current Password
                    </label>
                    <input
                      type="password"
                      name="currentPassword"
                      id="currentPassword"
                      autoComplete="off"
                      value={values.currentPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="••••••••"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 placeholder-gray-400"
                      required={true}
                    />
                    {touched.currentPassword && (
                      <p className="text-red-700 error_msg">
                        {errors.currentPassword}
                      </p>
                    )}
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="password"
                      className="block mb-2 text-xl font-medium text-gray-900"
                    >
                      New Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      autoComplete="off"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="••••••••"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 placeholder-gray-400"
                      required={true}
                    />
                    {touched.password && (
                      <p className="text-red-700 error_msg">
                        {errors.password}
                      </p>
                    )}
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="confirm-password"
                      className="block mb-2 text-xl font-medium text-gray-900"
                    >
                      Confirm password
                    </label>
                    <input
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      autoComplete="off"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="••••••••"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 placeholder-gray-400"
                      required={true}
                    />
                    {touched.confirmPassword && (
                      <p className="text-red-700 error_msg">
                        {errors.confirmPassword}
                      </p>
                    )}
                  </div>
                  <Button
                    type="submit"
                    className="text-white mt-4"
                    loading={loading}
                  >
                    Reset Password
                  </Button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </section>
      {/* <div classNameName='flex justify-center items-middle w-screen'>
        <div classNameName="text-center">
          <img
            classNameName="h-22  w-48"
            src="https://www.nrichlearning.com.au/wp-content/uploads/2022/09/e-nrich-logo-b.png"
            alt="Your Company"
          />
        </div>
      </div> */}
    </div>
  )
}
export default Resetpassword
