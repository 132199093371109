import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../button'
import {
  ClockIcon,
  QuestionMarkCircleIcon,
  ReceiptPercentIcon,
} from '@heroicons/react/20/solid'
import test from '../../assets/icon8.svg'
import app_api from '../../config/api'
import logo from '../../assets/icons/logo.png'
import moment from 'moment'

export default function ExamDialog(props) {
  const navigate = useNavigate()
  const [loading, setLoader] = useState(false)
  const {
    title = 'Your exam is ready',
    description = 'Before starting the exam, please note that if you close the browser tab, the exam will be automatically submitted. However, you can take the exam as many times as you want until the time validity of the exam expires.',
    confirmationButtonText = 'Start Your Exam',
    open,
    setOpen,
    onDelete,
    data,
  } = props

  const cancelButtonRef = useRef(null)

  const goToExam = () => {
    if (data?.expires_at == null) {
      app_api.patch(`/enrol-exam/start/${data?.id}`).then((res) => {
        setLoader(false)
        navigate(`${data.id}/${data.exam.id}/questions`)
        setOpen(false)
      })
    } else {
      setLoader(false)
      navigate(`${data.id}/${data.exam.id}/questions`)
      setOpen(false)
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-4 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex flex flex-state deleteUI">
                  <div className="flex items-center justify-center flex-shrink-0 w-40 mx-auto rounded-full sm:mx-0 sm:w-40">
                    <img src={logo} className='w-64'/>
                  </div>
                  <div className="mt-3 text-center flex-grow sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-m font-semibold text-gray-500 text-justify">Please note the following important instructions:</p>
                      <div className='text-left flex flex-col text-sm py-2'>
                          <p>1. The time starts when you click the <b>"Start your exam"</b> button.</p>
                          <p>2. The exam will auto submitted when the Time allowed {data?.exam?.duration + ' Mins'} finishes.</p>
                          <p>3. You can preview, see the answers and justification for each answer after the first attempt.</p>
                          <p>4. You can move to the next question by clicking the <b>"Next"</b> button.</p>
                          <p>5. You can go back to the previous question by clicking the <b>"Previous"</b> button.</p>
                          <p>6. You can take the exam as many times as you like until <b>{moment(data?.exam?.resettable_expiry).format('DD MMM YYYY hh:mm A')} </b></p>
                      </div>
                      <div className="flex flex-row justify-between mx-auto mt-3">
                        <p className="text-sm text-gray-500 inline-flex w-50 ml-6  mt-3">
                          <ClockIcon className="w-12 h-12 p-1.5 my-auto text-black mr-2" />{' '}
                          <span className="text-start">
                            Time allowed <br></br>
                            <b className="text-black text-lg">
                              {data?.exam?.duration + ' Mins'}
                            </b>
                          </span>
                        </p>
                        <p className="text-sm text-gray-500 inline-flex w-50  mt-3">
                          <QuestionMarkCircleIcon className="w-12 h-12 p-1.5 my-auto text-black mr-2" />{' '}
                          <span className="text-start">
                            No. of Questions <br></br>
                            <b className="text-black text-lg">
                              {data?.exam?.questions?.length}{' '}
                            </b>
                          </span>
                        </p>
                      </div>
                      <div className="flex flex-row justify-between mt-3 mx-auto">
                        <p className="text-sm text-gray-500 inline-flex w-50 ml-6  mt-3">
                          <ReceiptPercentIcon className="w-12 h-12 p-1.5 my-auto  text-black mr-2" />{' '}
                          <span className="text-start">
                            Total Marks <br></br>
                            <b className="text-black text-lg">
                              {data?.total_marks}
                            </b>
                          </span>
                        </p>
                      </div>
                      <p className="text-m text-gray-500 mt-4 font-bold">
                        All The Best 👍🏻
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-2 sm:mt-4 sm:flex deleteUI_button">
                  <Button
                    onClick={() => {
                      setLoader(true)
                      goToExam()
                    }}
                    type="button"
                    loading={loading}
                    className="px-4 py-2 text-base font-medium text-white bg-green-700 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 mt-3 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:text-sm"
                  >
                    {data?.expires_at
                      ? 'Resume your Exam'
                      : confirmationButtonText}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
