import React, { useEffect, useState } from 'react'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { Link, useNavigate, useParams } from 'react-router-dom'
import logo from '../assets/icons/logo.png'
import { Button } from '../components/tables/paginationButtons'
import app_api from '../config/api'
import moment from 'moment'
import {
  PencilIcon,
  XCircleIcon,
  TrashIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/24/outline'
import {
  CircleStackIcon,
  PrinterIcon,
  ShareIcon,
} from '@heroicons/react/20/solid'
export const Preview = (props) => {
  const navigate = useNavigate()
  const params = useParams()

  const [eLoading, setELoading] = useState(true)
  const [eError, seteError] = useState(null)
  const [enrolment, setEnrolment] = useState({})

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [exam, setExam] = useState({})
  const [questions, setQuestions] = useState([])

  const [rLoading, setRLoading] = useState(true)
  const [rError, setRError] = useState(null)
  const [responses, setResponses] = useState([])

  useEffect(() => {
    app_api
      .get(`enrol-exam/${params.enrolment_id}`)
      .then((res) => {
        if (res.data.hasAttempted) {
          setELoading(false)
          seteError(null)
          setEnrolment(res.data)
        } else {
          navigate('/my-exams')
        }
      })
      .catch((err) => {
        setELoading(false)
        seteError(
          err?.response?.data?.message || 'Error getting enrolment details'
        )
      })
    app_api
      .get(`user-response/enrol-exam/${params.enrolment_id}`)
      .then((res) => {
        setResponses(res.data)
        setRLoading(false)
      })
      .catch((err) => {
        setRLoading(false)
        setRError(err?.response?.data?.message || 'Error getting responses')
      })
  }, [])

  const NavigateTo = () => {
    const item = JSON.parse(localStorage.getItem('moldKey'))
    if (item) {
      navigate('/my-exams')
    } else {
      navigate('/')
    }
  }

  function convert_from_sqrt_to_styled(text) {
    const pattern = /\$sq\((.*?)\)/g

    const replacedText = text?.replace(pattern, (match, number) => {
      return `<span style="white-space: nowrap; font-family: Georgia;">
              &radic;<span style ="border-top:1px solid black;">&nbsp;${number}&nbsp;</span>
            </span>`
    })
    return replacedText
  }

  return (
    <>
      <div className="border-b">
        <div className="flex items-center  justify-between  mr-8 py-2 ml-8 w-5/12">
          <div onClick={NavigateTo}>
            <img
              className="h-12 w-auto cursor-pointer"
              src={logo}
              alt="Your Company"
            />
          </div>
          <div className="text-lg font-bold text-capitalize">
            {exam?.name} (
            <span style={{ color: '#3730a3' }}>
              {((enrolment.total_score / enrolment.out_of_score) * 100).toFixed(
                2
              )}
              %
            </span>
            )
          </div>
        </div>
      </div>
      <div className="d-flex fontStandard">
        <div className="w-9/12">
          <div className="w-full md:w-10/12 mx-auto previewStandard justification_cs">
            {responses.map((q, idx) => (
              <div className="bg-white border  shadow-md mr-3 ml-3 mt-5 mb-3 rounded-lg p-3 ">
                <div className="text-sm">Question {idx + 1}</div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: convert_from_sqrt_to_styled(q.question),
                  }}
                ></div>
                <div
                  key={idx}
                  className={`relative flex items-start py px-2 mt-3 rounded-sm 
                    ${q.is_correct == 1 ? 'bg-green-600' : ''}
                    ${q.is_correct == 0 ? 'bg-red-500' : ''}
                    `}
                >
                  <label
                    htmlFor={q.answer}
                    className="border-l border-r  w-full p-2 pl-4"
                  >
                    {q.answer ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: convert_from_sqrt_to_styled(q.answer),
                        }}
                      ></span>
                    ) : (
                      <span>None</span>
                    )}
                  </label>
                </div>
                <div
                  key={idx}
                  className="relative flex items-start mt-3 rounded-sm w-full"
                >
                  <label htmlFor={q.correct_answer} className="w-full pl-0">
                    <b>Correct Answer:</b>
                    <span
                      className="ml-2"
                      dangerouslySetInnerHTML={{
                        __html: convert_from_sqrt_to_styled(q.correct_answer),
                      }}
                    ></span>
                  </label>
                </div>
                {q?.justification && (
                  <>
                    <p className="">
                      <b>Justification:</b>{' '}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: convert_from_sqrt_to_styled(q?.justification),
                        }}
                      ></span>
                    </p>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="w-3/12 right-0 top-0 h-[750px] fixed rounded border-l  p-4 overscroll-y-none  bg-white rounded-3xl ">
          <h1 className="text-xl text-center font-bold">Exam Summary</h1>
          <div>
            <div className="flex flex-row justify-between mt-4"></div>
          </div>

          <h4 className="text-md mt-4 mb-2 font-semibold">Actions</h4>
          <div className="flex flex-wrap justify-between">
            <div className="mt-2">
              <a
                className="bg-blue-200 font-medium rounded-md text-sm text-black p-2 cursor-pointer "
                href={`${process.env.REACT_APP_STATIC_URL}${enrolment?.grade_card}`}
                target="_blank"
                // onClick={() => goToExam(ex?.id, ex?.exam?.id, ex.hasAttempted)}
              >
                View Scorecard
              </a>
              &nbsp;&nbsp;
              <a
                className="bg-blue-200 font-medium text-sm rounded-md text-black p-2 cursor-pointer"
                href={`${process.env.REACT_APP_STATIC_URL}${enrolment?.certificate_url}`}
                target="_blank"
                // onClick={() => goToExam(ex?.id, ex?.exam?.id, ex.hasAttempted)}
              >
                View Certificate
              </a>
            </div>
          </div>
          <button
            type="button"
            className="mt-6 w-100 pt-auto text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2 mr-2 mb-2 flex justify-center items-center"
            onClick={NavigateTo}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
              />
            </svg>
            <span className="ml-2">Go Back</span>
          </button>
        </div>
      </div>
    </>
  )
}
