import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import app_api from '../config/api'
import moment from 'moment'
import logo from '../assets/icons/logo.png'

function threeDigit(number) {
  if (number)
    return number > 999
      ? number.toString()
      : ('000' + number.toString()).substr(-3)
  return 0
}

const credit_card_surcharge = 0.02

const InvoiceView = () => {
  const params = useParams()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [enrolment, setEnrolment] = useState({})

  useEffect(() => {
    app_api
      .get(`/enrolment/${params.enrolment_id}`)
      .then((res) => {
        setLoading(false)
        setEnrolment(res.data)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message)
      })
  }, [])

  return (
    <div>
      <div className="flex justify-between">
        <div
          className={
            enrolment.status === 'tentative'
              ? 'my-5 p-4 col-8 ml-16 invoice'
              : 'my-5 p-4 col-8  mx-auto invoice'
          }
          style={{ boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}
        >
          <div className="d-flex justify-content-between ">
            <div style={{ width: '200px' }} className="">
              <img className="w-100" src={logo} />
              <h3
                className="text-md font-semibold my-2 ml-3 text-center"
                style={{ color: '#636977' }}
              >
                NRICHRWINV-{threeDigit(enrolment?.id)}{' '}
              </h3>
            </div>
            <div>
              <p className="fs-7 text-gray-500 leading-relaxed">
                NRICH Learning, Ringwood <br />
                55 Bedford Road
                <br />
                Ringwood Victoria 3135 <br />
                Australia
              </p>
            </div>
          </div>
          <div className=" my-4 d-flex  justify-content-between bg-blue-900 p-4 rounded-xl text-white">
            <div>
              <p className="fs-6 text-gray-300">Bill to:</p>
              <p className="fs-7 capitalize">
                {enrolment?.contact?.parentFirstName +
                  ' ' +
                  enrolment?.contact?.parentLastName}
                <br />
                {enrolment?.contact?.addressLine1}
                {/* <br /> */}
                {/* {enrolment?.contact?.addressLine2} */}
                <br />
                {enrolment?.contact?.parentMobileNo}
                <br />
              </p>
            </div>
            <div className=" d-flex  justify-content-between">
              <div>
                <p className="fs-7 text-gray-300 mb-3">Date</p>
                <p className="fs-7 text-gray-300 mb-3">Due Date</p>
                <p className="fs-7 text-gray-300 mb- fw-bold">
                  {enrolment?.status === 'tentative' ? (
                    <>Amount Due</>
                  ) : (
                    <>Amount Paid</>
                  )}
                </p>
              </div>
              <div className="ms-5 ps-5 me-3">
                <p className="fs-7 mb-3">
                  {moment(enrolment.createdAt).format('DD MMM YYYY')}
                </p>
                <p className="fs-7 mb-3">
                  {moment(enrolment.createdAt)
                    .add(7, 'days')
                    .format('DD MMM YYYY')}
                </p>
                <p className="fs-7 mb- fw-bold">
                  $
                  {enrolment?.service?.cost +
                    credit_card_surcharge * enrolment?.service?.cost}
                </p>
              </div>
            </div>
          </div>

          <div className="mb-5 pb-4">
            <table className="table">
              <thead>
                <tr>
                  <th className="rounded-l-lg text-gray-600 font-semibold ">
                    Service
                  </th>
                  <th className="text-gray-600 font-semibold text-end">
                    Unit Price
                  </th>
                  <th className="rounded-r-lg text-gray-600 font-semibold text-end">
                    Line Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="capitalize py-3 font-semibold">
                    {enrolment?.service?.name}
                    {/* Verbal Reasoning 1 */}
                  </td>
                  <td className="capitalize py-3 font-semibold text-end">
                    {enrolment?.service?.cost}
                    {/* 1500 */}
                  </td>
                  <td className="capitalize py-3 font-semibold text-end">
                    {enrolment?.service?.cost}
                    {/* 1500 */}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="d-flex justify-content-end">
              <div>
                <div
                  className="d-flex  ms-auto justify-content-between"
                  style={{ width: '400px' }}
                >
                  <div className="text-end">
                    <p className="mb-2 fs-7 font-semibold">Subtotal</p>
                    <p className=" mb-2 fs-7 font-semibold">
                      Credit Card fees({credit_card_surcharge * 100}%)
                    </p>
                  </div>
                  <div className="text-end">
                    <p className="mb-2 fs-7 font-semibold">
                      ${enrolment?.service?.cost}
                    </p>
                    <p className="mb-2 fs-7 font-semibold">
                      ${credit_card_surcharge * enrolment?.service?.cost}
                    </p>
                  </div>
                </div>

                <div
                  className="d-flex ms-auto justify-content-between"
                  style={{ borderTop: '1px solid grey', width: '400px' }}
                >
                  <div className="text-end ">
                    <h2 className="fs-6 ms-5 fw-bold">
                      {enrolment?.status === 'tentative' ? (
                        <>Amount Due</>
                      ) : (
                        <>Amount Paid</>
                      )}
                    </h2>
                  </div>
                  <div className="text-end">
                    <h2 className="fs-6 fw-bold">
                      $
                      {enrolment?.service?.cost +
                        credit_card_surcharge * enrolment?.service?.cost}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p style={{ fontSize: '13px' }} className="mt-5">
                <span className="text-gray-600 ">Note:</span>
                <br></br>
                All invoices must be paid within 7 days of issue. You can pay by
                credit card by clicking on the link provided in the invoice.
                Please note, payment done by Credit Card will incur an
                additional charge of 2% of the total payment. To avoid
                surcharge, you may pay us directly into our account or pay by
                cash. Our account details are given below. Please make sure that
                you put the invoice number in the reference when doing a bank
                transfer.<br></br>
                <br></br>
                Account Name: Seema Bharti<br></br>
                BSB: 083004<br></br>
                Account Number: 321444809<br></br>
                <br></br>
              </p>
            </div>
          </div>
          <div className="text-center font-semibold w-100">
            Thanks for your business!
          </div>
          <div
            className="col-12 d-flex justify-content-end align-items-center"
            // style={{ backgroundColor: '#F8F8F8' }}
          >
            <div className="">
              <img src="" />
            </div>
            <div className=" w-100 my-4 ">
              {enrolment?.status === 'tentative' && (
                <a
                  href={enrolment?.payment_link_url}
                  disabled=""
                  type="button"
                  className="btn btn-success mr-2 w-100"
                  style={{ backgroundColor: '#8BC220' }}
                >
                  Pay Online
                </a>
              )}
            </div>
          </div>
        </div>
        {enrolment?.status === 'tentative' && (
          <>
            <div className="w-[300px] right-0 top-0 h-[750px] fixed rounded border-l  p-4 overscroll-y-none  bg-white rounded-3xl ">
              <h1 className="text-xl text-center text-red-600 font-bold mt-10">
                Awaiting Payment
              </h1>
              <div className=" d-flex mt-8 justify-content-between">
                <div className="w-[100px]">
                  <p className="fs-7  mb-3">Date</p>
                  <p className="fs-7 inline-flex mb-3">Due Date</p>
                  <p className="fs-7  mb- fw-bold">Amount Due</p>
                </div>
                <div className="ms-2 ps-2 me-3">
                  <p className="fs-7 mb-3">
                    {moment(enrolment.createdAt).format('DD MMM YYYY')}
                  </p>
                  <p className="fs-7 mb-3">
                    {moment(enrolment.createdAt)
                      .add(7, 'days')
                      .format('DD MMM YYYY')}
                  </p>
                  <p className="fs-7 mb- fw-bold text-end">
                    $
                    {enrolment?.service?.cost +
                      credit_card_surcharge * enrolment?.service?.cost}
                  </p>
                </div>
              </div>

              <div className=" w-100 my-4 ">
                {enrolment?.status === 'tentative' && (
                  <a
                    href={enrolment?.payment_link_url}
                    disabled=""
                    type="button"
                    className="btn btn-success mr-2 w-100"
                    style={{ backgroundColor: '#8BC220' }}
                  >
                    Pay Online
                  </a>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default InvoiceView
