import { useState, useEffect, useCallback, useMemo } from 'react'
import Modal from '../components/modals'
import Input from '../components/input'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import {
  batch_columns,
  enrolment_columns,
} from '../components/tables/tableheader'
import Label from '../components/label'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmationDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import {
  enrolmentSchema,
  truestEnrolmentSchema,
  falsEnrolmentSchema,
  truEnrolmentSchema,
} from '../schema'
import Select from 'react-select'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import HalfGeneralSlideover from '../components/half-general-slideover'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    serviceId: '',
    batchId:64,
    contactId: '',
    refererId: '',
    parentFirstName: '',
    parentLastName: '',
    parentEmail: '',
    parentMobileNo: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postCode: '',
    studentsFirstName: '',
    studentsLastName: '',
    studentsEmail: '',
    studentsMobileNo: '',
    dob: '',
    grade: '',
    studentCountry: '',
    studentState:'',
  },
}
const StatusOptions = [{
  id: 'confirmed',
  name: 'Confirmed'
},
{
  id: 'tentative',
  name: 'Tentative'
}]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Enrolments = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [showcontact, setShowContact] = useState(false)
  const [error, setError] = useState(null)
  const [enrolmentsOnline, setEnrolmentsOnline] = useState([])
  const [enrolmentsOffline, setEnrolmentsOffline] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [defaultState, setDefaultState] = useState([])
  const [cLoading, setCLoading] = useState(true)
  const [cError, setCError] = useState(null)
  const [contacts, setContacts] = useState([])
  const [referer, setReferer] = useState([])
  const [bLoading, setBLoading] = useState(true)
  const [bError, setBError] = useState(null)
  const [batch, setBatch] = useState([])
  const [sLoading, setSLoading] = useState(true)
  const [sError, setSError] = useState(null)
  const [service, setService] = useState([])
  const [showBatch, setShowBatch] = useState(true)
  const [studentStates, setStudentState] = useState([])
  const [showAssigned, setShowAssigned] = useState(true)
  const [studentCity, setStudentCity] = useState([])
  const [tabs, setTabs] = useState([
    { val: 0, name: 'Offline', href: '#', current: true },
    { val: 1, name: 'Online', href: '#', current: false },
  ])
  const [statusFilter, setStatusFilter] = useState('')
  const [TabData, setTabData] = useState([])
  const [pagingData, setPagingData]= useState({
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: "",
    sort: {
      order: "",
      key: "",
    },
  })

  useEffect(() => {
      console.log(defaultState)
      let payload = {
        countryCode: defaultState[0]?.countryCode,
        isoCode: defaultState[0]?.isoCode
      }
      console.log(payload)
      app_api
        .post('/state-city/city',payload)
        .then((res) => {
          let data = res.data
          if(payload.isoCode === 'VIC'){
            data.push({
              countryCode: "AU",
              latitude: "-37.66955000",
              longitude: "144.88675000",
              name: "Lyndhurst",
              stateCode: "VIC"
            })
          }
          setStudentCity(data)
          setLoading(false)
          setError(null)
        })
        .catch((err) => {
          setLoading(false)
          setError(err?.response?.data?.message || 'error getting data')
        })
  },[defaultState])
  
  useEffect(() => {
    setPagingData({
      ...pagingData,
      total: TabData.length
    })
  },[TabData])

  const renderTable = useCallback(() => {
    return (
      <>
        <Table
          onPaginationChange={onPaginationChange}
          pagingData={tableData}
          columns={enrolment_columns({ })}
          data={
            TabData
          }
          onEditClose={cleanModalData}
        />
      </>
    )
  }, [TabData])

  const { pageIndex, pageSize, sort, query, total } = pagingData;

  const tableData = useMemo(
    () => ({ pageIndex, pageSize, sort, query, total }),
    [pageIndex, pageSize, sort, query, total]
  );

  const onPaginationChange = (page) => {
    let newService = [...enrolmentsOnline]
    setTabData(newService.slice((page-1) * pageSize))
    const newTableData = {...tableData};
    newTableData.pageIndex = page;
    setPagingData(newTableData)
  };

  useEffect(() => {
    if(pageIndex != 1){
      const newService = [...enrolmentsOnline]
      setTabData(newService.slice((pageIndex - 1) * pageSize))
    }
  }, [enrolmentsOnline])

  let enrolType = 'offline'
  var today = new Date().toISOString().split('T')[0];

  const onNewContactChange = (e) => {
    setShowContact(e.target.value === 'true')
    if (e.target.value === 'true') {
      setModal((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          contactId: '',
        },
      }))
    }
  }

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  // const onDeleteEnrolment = () => {
  //   const { id, index } = modal
  //   app_api
  //     .delete(`/enrolment/${id}`)
  //     .then((res) => {
  //       let updateEnrolment = [...enrolments]
  //       updateEnrolment.splice(index, 1)
  //       setEnrolments(updateEnrolment)
  //       setConfirmationDialog(false)
  //     })
  //     .catch((err) => {
  //       setError(err.toString())
  //     })
  // }

  const getEnrolmentData = () => {
    app_api
      .get('/enrolment')
      .then((res) => {
        setLoading(false)
        setError(null)
        let data = res.data
        setEnrolmentsOnline(data)
        setTabData(data)
        setPagingData({
          ...pagingData,
          total: data.length
        })
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getServiceData = () => {
    app_api
      .get('/service')
      .then((res) => {
        let data = res.data.filter(l => l.archive == 0)
        setSLoading(false)
        setSError(null)
        setService(data)
      })
      .catch((err) => {
        setSLoading(false)
        setSError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getContactData = () => {
    app_api
      .get('/contact')
      .then((res) => {
        setCLoading(false)
        setCError(null)
        setContacts(res.data)
      })
      .catch((err) => {
        setCLoading(false)
        setCError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getRefererData = () => {
    app_api
      .get('/referer')
      .then((res) => {
        let data = res.data.filter(l => l.archive == 0)
        setCLoading(false)
        setCError(null)
        setReferer(data)
      })
      .catch((err) => {
        setCLoading(false)
        setCError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getBatchData = () => {
    app_api
      .get('/batch')
      .then((res) => {
        let data = res.data.filter(l => l.archive == 0)
        setBLoading(false)
        setBError(null)
        setBatch(data)
      })
      .catch((err) => {
        setBLoading(false)
        setBError(err?.response?.data?.message || 'error getting data')
      })
  }

  const setShowBatchfunction = (e) => {
    if (e.target.value === 'true') {
      enrolType = 'offline'
    } else {
      enrolType = 'online'
    }
    setShowBatch(e.target.value === 'true')
    if (e.target.value === 'true') {
      setModal((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          batchId: '',
        },
      }))
    }
  }

  const toggleAssignedTab = (idx) => {
    let thistabs = tabs.map((t, index) => {
      if (index === idx) {
        return { ...t, current: true }
      } else {
        return { ...t, current: false }
      }
    })
    setTabs(thistabs)
    if (idx) {
      setShowAssigned(false)
    } else {
      setShowAssigned(true)
    }
  }

  const getStateDataByBackend = () => {
    app_api
      .post('/state-city/state')
      .then((res) => {
        let data = res.data
        let defaultState = data.filter(l => l.name == 'Victoria')
        console.log(defaultState)
        setDefaultState(defaultState)
        setStudentState(data)
        console.log(data)
        setLoading(false)
        setError(null)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }
  

  const getCities = (countryCode, isoCode) => {
    let payload = {
      countryCode: countryCode.toString(),
      isoCode: isoCode.toString()
    }
    console.log(payload)
    app_api
      .post('/state-city/city',payload)
      .then((res) => {
        let data = res.data
        if(isoCode === 'VIC'){
          data.push({
            countryCode: "AU",
            latitude: "-37.66955000",
            longitude: "144.88675000",
            name: "Lyndhurst",
            stateCode: "VIC"
          })
        }
        setStudentCity(data)
        console.log(data)
        setLoading(false)
        setError(null)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getEnrolmentData()
    getServiceData()
    getContactData()
    getRefererData()
    getBatchData()
    getStateDataByBackend()
  }, [])

  const renderModal = () => {
    const { type, state, data, edit_id } = modal
    const {
      contactId,
      refererId,
      batchId,
      is_active,
      parentFirstName,
      parentLastName,
      parentEmail,
      parentMobileNo,
      addressLine1,
      addressLine2,
      city,
      postCode,
      studentsFirstName,
      studentsLastName,
      studentsEmail,
      studentsMobileNo,
      dob,
      grade,
      studentCountry,
      studentState
    } = data

    data.serviceId = data.service?.map((e) => e.id)
    return (
      <Formik
        initialValues={data}
        validationSchema={
          showBatch? showcontact? truEnrolmentSchema: falsEnrolmentSchema: showcontact? truestEnrolmentSchema: falsEnrolmentSchema
        }
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) =>
        {
          if (type === 'add') {
            let {
              parentEmail,
              parentFirstName,
              parentLastName,
              parentMobileNo,
              studentsEmail,
              studentsFirstName,
              studentsLastName,
              studentsMobileNo,
              dob,
              grade,
              addressLine1,
              addressLine2,
              city,
              postCode,
              batchId,
              serviceId,
              contactId,
              refererId,
              studentCountry,
              studentState
            } = values
            if (showcontact) {
              app_api
                .post('/contact', {
                  parentEmail: values.parentEmail,
                  parentFirstName: values.parentFirstName,
                  parentLastName: values.parentLastName,
                  parentMobileNo: values.parentMobileNo.toString(),
                  studentsEmail: values.studentsEmail,
                  studentsFirstName: values.studentsFirstName,
                  studentsLastName: values.studentsLastName,
                  studentsMobileNo: values.studentsMobileNo.toString(),
                  dob: values.dob,
                  grade: values.grade,
                  addressLine1: values.addressLine1,
                  addressLine2: values.addressLine2,
                  city: values.city,
                  postCode: values.postCode.toString(),
                  state: values.studentState,
                  country: 'Australia',
                })
                .then((res) => {
                  getContactData()
                  if (showBatch) {
                    app_api
                      .post('/enrolment', {
                        enrolment_type: 'offline',
                        batchId: 64,
                        serviceId: values.serviceId,
                        contactId: parseInt(res.data.id),
                        refererId: parseInt(refererId),
                        sendEmail: true
                      })
                      .then((res) => {
                        //setsubmiting false
                        cleanModalData()
                        getEnrolmentData()
                        setSubmitting(false)
                        resetForm()
                      })
                      .catch((err) => {
                        setError(err.toString)
                      })
                  } else {
                    app_api
                      .post('/enrolment', {
                        // batchId: parseInt(batchId),
                        enrolment_type: 'online',
                        serviceId: values.serviceId,
                        contactId: parseInt(res.data.id),
                        refererId: parseInt(refererId),
                        sendEmail: true
                      })
                      .then((res) => {
                        //setsubmiting false
                        cleanModalData()
                        getEnrolmentData()
                        setSubmitting(false)
                        resetForm()
                      })
                      .catch((err) => {
                        setError(err.toString)
                      })
                  }
                })
                .catch((err) => {
                  setError(
                    err?.response?.data?.message || 'error creating contact'
                  )
                  if(err.response?.data?.statusCode == 424){
                    toast.error('Contact already exists')
                    // cleanModalData()
                    getEnrolmentData()
                    setSubmitting(false) 
                  }
                  else{
                    toast.error(err.response.data.message)
                    cleanModalData()
                    getEnrolmentData()
                    setSubmitting(false)
                  }
                })
            } else {
              if (showBatch) {
                app_api
                  .post('/enrolment', {
                    enrolment_type: 'offline',
                    batchId: 64,
                    serviceId: values.serviceId,
                    contactId: parseInt(contactId),
                    refererId: parseInt(refererId),
                    sendEmail: true
                  })
                  .then((res) => {
                    //setsubmiting false
                    cleanModalData()
                    getEnrolmentData()
                    setSubmitting(false)
                    resetForm()
                  })
                  .catch((err) => {
                    setError(err.toString)
                  })
              } else {
                app_api
                  .post('/enrolment', {
                    enrolment_type: 'online',
                    serviceId: values.serviceId,
                    contactId: parseInt(contactId),
                    refererId: parseInt(refererId),
                    sendEmail: true
                  })
                  .then((res) => {
                    //setsubmiting false
                    cleanModalData()
                    getEnrolmentData()
                    setSubmitting(false)
                    resetForm()
                  })
                  .catch((err) => {
                    setError(err.toString)
                  })
              }
            }
          } else {
            app_api
              .patch(`/enrolment/${edit_id}`, values)
              .then((res) => {
                cleanModalData()
                getEnrolmentData()
              })
              .catch((err) => {})
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          values,
          touched,
          isValid,
          setSubmitting,
          isSubmitting,
          errors,
        }) => (
          <HalfGeneralSlideover
            title={type === 'add' ? 'Add Enrolment' : 'Edit Enrolment'}
            open={state}
            setOpen={() => {
              setModal((prev) => ({ ...prev, state: false }))
              cleanModalData()
            }}
          >
            <form noValidate onSubmit={handleSubmit}>
              <div className="text-left">
                <div className="mt-3">
                  <label
                    htmlFor="enrol-online"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Enrolment Type
                  </label>
                  <div className="relative flex items-start mt-2">
                    <div className="flex h-5 items-center">
                      <input
                        id="enrol-online"
                        aria-describedby="comments-description"
                        name="enrol-online"
                        value="false"
                        type="radio"
                        // onBlur={handleBlur}
                        // onChange={handleChange}
                        // defaultChecked={values.newContact === true}
                        onClick={setShowBatchfunction}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="enrol-online"
                        className="font-medium text-gray-700"
                      >
                        Online
                      </label>
                    </div>
                  </div>
                  <div className="relative flex items-start mt-2 mb-4">
                    <div className="flex h-5 items-center">
                      <input
                        id="enrol-offline"
                        aria-describedby="comments-description"
                        value="true"
                        name="enrol-online"
                        type="radio"
                        // defaultChecked={values.newContact === false}
                        onClick={setShowBatchfunction}
                        defaultChecked
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="enrol-offline"
                        className="font-medium text-gray-700"
                      >
                        Offline
                      </label>
                    </div>
                  </div>
                </div>
                {/* {showBatch && (
                  <div className="mt-3">
                     <div className="flex">
                        <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium">Batch </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                    <Select
                    className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="batchId"
                    id="batchId"
                    isSearchable
                    loadingMessage="Getting Batch..."
                    placeholder="Select a Batch"
                    value={
                      values.batchId
                        ?  batch?.map(l => ({
                            value: batch?.find((e) => e.id == values.batchId)?.id,
                            label: batch?.find((e) => e.id == values.batchId)?.name,
                          }))
                        : null
                    }
                    options={batch?.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.id,
                    }))}
                    onChange={(option) => {
                      setValues({
                        ...values,
                        batchId: option.value,
                      })
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                  />
                
                    {touched.batchId && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.batchId}
                      </p>
                    )}
                  </div>
                )} */}
                <div className="mt-4">
                  <div className="flex">
                    <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium">Service </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <Select
                    className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="serviceId"
                    id="serviceId"
                    isSearchable
                    loadingMessage="Getting service..."
                    placeholder="Select a service"
                    isMulti={true}
                    value={
                      values.serviceId
                      ?  values.serviceId?.map(l => ({
                          value: l,
                          label: service.find((e) => e.id == l)
                            ?.name,
                        }))
                      : null
                    }
                    options={service?.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.id,
                    }))}
                    onChange={(option) => {
                      setValues({
                        ...values,
                        serviceId: option.map((l) => l.value),
                      })
                      console.log(option.map(l => l.id))
                      console.log(values.serviceId)
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                  />
                  {/* <select
                    id="batchId"
                    name="serviceId"
                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    value={values.serviceId}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // onChange={(event) =>
                    //   onModalHandleChange(
                    //     event.target.name,
                    //     Number(event.target.value)
                    //   )
                    // }
                  >
                    <option value="">Select a service</option>
                    {service?.map((s, index) => (
                      <option key={index} value={s.id}>
                        {s?.name}
                      </option>
                    ))}
                  </select> */}
                  {touched.serviceId && (
                    <p className="text-red-700 mb-2 error_msg">
                      {errors.serviceId}
                    </p>
                  )}
                </div>

                <div className="relative flex items-start mt-4">
                  <div className="flex h-5 items-center">
                    <input
                      id="new-contact"
                      aria-describedby="comments-description"
                      name="new-contact"
                      value="true"
                      type="radio"
                      // onBlur={handleBlur}
                      // onChange={handleChange}
                      // defaultChecked={values.newContact === true}
                      onClick={onNewContactChange}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="new-contact"
                      className="font-medium text-gray-700"
                    >
                      Create a new contact
                    </label>
                  </div>
                </div>
                <div className="relative flex items-start mt-2 mb-4">
                  <div className="flex h-5 items-center">
                    <input
                      id="existing-contact"
                      aria-describedby="comments-description"
                      value="false"
                      name="new-contact"
                      type="radio"
                      // defaultChecked={values.newContact === false}
                      onClick={onNewContactChange}
                      defaultChecked
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="existing-contact"
                      className="font-medium text-gray-700"
                    >
                      Choose from existing
                    </label>
                  </div>
                </div>
                {showcontact && (
                  <div>
                    <div className="mt-4"></div>
                    <div className="flex">
                      <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium"> Parent's First Name</label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Input
                      name="parentFirstName"
                      // label="Parent's First Name"
                      placeholder='Enter parent first name'
                      value={values.parentFirstName}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="mb-2"
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                    />
                    {touched.parentFirstName && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.parentFirstName}
                      </p>
                    )}
                    <div className="mt-4"></div>
                    <div className="flex">
                      <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium"> Parent's Last Name</label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Input
                      name="parentLastName"
                      // label="Parent's Last Name"
                      placeholder='Enter parent last name'
                      value={values.parentLastName}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="mb-2"
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                    />
                    {touched.parentLastName && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.parentLastName}
                      </p>
                    )}
                    <div className="mt-4"></div>
                    <div className="flex">
                      <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium">Email </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Input
                      name="parentEmail"
                      // label="Email"
                      type="email"
                      placeholder='Enter parent email address'
                      disabled={type !== 'add'}
                      value={values.parentEmail}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="mb-2"
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                    />
                    {touched.parentEmail && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.parentEmail}
                      </p>
                    )}
                    <div className="mt-4"></div>
                    <div className="flex">
                      <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium"> Mobile No.</label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Input
                      name="parentMobileNo"
                      // label="Mobile No."
                      placeholder='Enter parent phone number'
                      type="tel"
                      value={values.parentMobileNo}
                      autoComplete="off"
                      onBlur={(e) => {
                        setValues({
                          ...values,
                          parentMobileNo: e.target.defaultValue,
                        })
                      }}
                      onChange={handleChange}
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                    />
                    {touched.parentMobileNo && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.parentMobileNo}
                      </p>
                    )}
                    <div className="mt-4"></div>
                    <div>
                      <div className="flex">
                        <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium"> Address 1</label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                      <div className="mt-1">
                        <textarea
                          rows={4}
                          name="addressLine1"
                          placeholder='Enter address line 1'
                          id="addressLine1"
                          className="px-3 py-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          value={values.addressLine1}
                          // onChange={(event) =>
                          //   onModalHandleChange(event.target.name, event.target.value)
                          // }
                          autoComplete="off"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched.addressLine1 && (
                          <p className="text-red-700 mb-2 error_msg">
                            {errors.addressLine1}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-4"></div>
                    <div>
                      <label
                        className="block text-sm font-medium text-gray-700 mt-4"
                        htmlFor="addressLine2"
                      >
                        Address 2
                      </label>
                      <div className="mt-1">
                        <textarea
                          rows={4}
                          name="addressLine2"
                          id="addressLine2"
                          placeholder='Enter address line 2 ( Optional )'
                          className="px-3 py-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          value={values.addressLine2}
                          autoComplete="off"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          // onChange={(event) =>
                          //   onModalHandleChange(event.target.name, event.target.value)
                          // }
                        />
                        
                      </div>
                    </div>
                    <div className="mt-4"></div>
                    <div className="flex">
                      <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium"> City</label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Select
                      className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="studentCity"
                      id="studentCity"
                      isSearchable
                      loadingMessage="Getting City..."
                      placeholder="Select City..."
                      value={
                        values.city
                          ? studentCity?.map((l) => ({
                            value: studentCity?.find(
                              (e) => e.name == values.city
                            )?.isoCode,
                              label: studentCity?.find(
                                (e) => e.name == values.city
                              )?.name,
                            }))
                          : null
                      }
                      options={studentCity.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.name,
                      }))}
                      onChange={(option) =>
                      {
                        setValues({
                          ...values,
                          city: option.value,
                        })
                      }}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                    />
                      {touched.city && (
                        <p className="text-red-700 error_msg">{errors.city}</p>
                      )}
                    <div className='mt-4'>
                      <div className="flex">
                        <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium"> State</label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                    <Select
                    className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="studentState"
                    id="studentState"
                    isSearchable
                    loadingMessage="Getting studentState..."
                    placeholder="Select State..."
                    value={
                      values.studentState
                        ? studentStates?.map((l) => ({
                            value: studentStates?.find((e) => e.name == values.studentState)?.isoCode,
                            label: studentStates?.find((e) => e.name == values.studentState)?.name,
                          }))
                        : defaultState ?
                            studentState?.map((l) => ({
                            value: studentState?.find((e) => e.name == defaultState[0].name)?.isoCode,
                            label: studentState?.find((e) => e.name == defaultState[0].name)?.name
                            }))
                        : null
                    }
                    options={studentStates.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.name,
                    }))}
                    onChange={(option) => {
                      getCities(
                        option.countryCode,
                        option.isoCode,
                      )
                      setValues({
                        ...values,
                        studentState: option.value,
                      })
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                  />
                    {touched.studentState &&
                      <p className="text-red-700 error_msg">
                        {errors.studentState}
                      </p>
                    }
                  </div>
                    <div className="mt-4"></div>
                    <div className="flex">
                      <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium"> Postcode</label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Input
                      name="postCode"
                      // label="Postcode"
                      type="text"
                      className="mb-1.5"
                      autoComplete="off"
                      onBlur={handleBlur}
                      placeholder='Enter Postcode...'
                      onChange={handleChange}
                      value={values.postCode}
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                    />
                    {touched.postCode && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.postCode}
                      </p>
                    )}
                    <div className="mt-4"></div>
                    <div className="flex">
                      <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium"> Student's First Name</label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Input
                      name="studentsFirstName"
                      // label="Student's First Name "
                      value={values.studentsFirstName}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="mb-2"
                      placeholder='Enter student first name'
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                    />
                    {touched.studentsFirstName && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.studentsFirstName}
                      </p>
                    )}
                    <div className="mt-4"></div>
                    <div className="flex">
                      <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium">Student's Last Name </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Input
                      name="studentsLastName"
                      // label="Student's Last Name"
                      value={values.studentsLastName}
                      className="mb-2"
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder='Enter student last name'
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                    />
                    {touched.studentsLastName && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.studentsLastName}
                      </p>
                    )}
                    <div className="mt-4"></div>
                    <div className="flex">
                      <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium">Student's Email </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Input
                      name="studentsEmail"
                      // label="Student's Email"
                      type="email"
                      value={values.studentsEmail}
                      className="mb-2"
                      placeholder='Enter student email address'
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.studentsEmail && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.studentsEmail}
                      </p>
                    )}
                    <div className="mt-4"></div>
                    <div className="flex">
                      <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium">Student's Phone No.</label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Input
                      name="studentsMobileNo"
                      // label="Student's Phone No."
                      type="tel"
                      value={values.studentsMobileNo}
                      placeholder='Enter student phone number'
                      autoComplete="off"
                      onBlur={(e) => {
                        setValues({
                          ...values,
                          studentsMobileNo: e.target.defaultValue,
                        })
                      }}
                      onChange={handleChange}
                    />
                    {touched.studentsMobileNo && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.studentsMobileNo}
                      </p>
                    )}
                    <div className="mt-4"></div>
                    <div className="flex">
                      <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium">Student's Date Of Birth. </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Input
                      name="dob"
                      // label="Student's Date Of Birth."
                      type="date"
                      max={today}
                      value={values.dob}
                      placeholder='Enter student date of birth'
                      className="mb-2"
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.dob && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.dob}
                      </p>
                    )}
                    <div className="mt-4"></div>
                    <div className="flex">
                      <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium">Grade </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Input
                      name="grade"
                      // label="Grade"
                      type="text"
                      value={values.grade}
                      placeholder='Enter student grade'
                      className="mb-2"
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.grade && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.grade}
                      </p>
                    )}
                  </div>
                )}
                {!showcontact && (
                  <div className="mt-4">
                    <div className="flex">
                      <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium">Contact </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Select
                    className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="contactId"
                    id="contactId"
                    isSearchable
                    loadingMessage="Getting contacts..."
                    placeholder="Select a contacts"
                    value={
                      values.contactId
                      ?  contacts?.map(l => ({
                        value: contacts?.find((e) => e.id == values.contactId)?.id,
                        label: contacts?.find((e) => e.id == values.contactId)?.studentsFirstName,
                      }))
                        : null
                    }
                    options={contacts.map((l) => ({
                      ...l,
                      label: l.studentsFirstName,
                      value: l.id,
                    }))}
                    onChange={(option) => {
                      setValues({
                        ...values,
                        contactId: option.value,
                      })
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                  />
               
                    {touched.contactId && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.contactId}
                      </p>
                    )}
                  </div>
                )}
                <div className="mt-4">
                  <label
                    htmlFor="refererId"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Referer
                  </label>
                  <Select
                    className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="refererId"
                    id="refererId"
                    isSearchable
                    loadingMessage="Getting referer..."
                    placeholder="Select a referer"
                    value={
                      values.refererId
                      ?  referer?.map(l => ({
                        value: referer?.find((e) => e.id == values.refererId)?.id,
                        label: referer?.find((e) => e.id == values.refererId)?.name,
                      }))
                        : null
                    }
                    options={referer?.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.id,
                    }))}
                    onChange={(option) => {
                      setValues({
                        ...values,
                        refererId: option.value,
                      })
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                  />
                  {/* <select
                    id="refererId"
                    name="refererId"
                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    value={values.refererId}
                    // onChange={(event) =>
                    //   onModalHandleChange(
                    //     event.target.name,
                    //     Number(event.target.value)
                    //   )
                    // }
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <option value="">Select a Referer</option>
                    {referer?.map((c, index) => (
                      <option key={index} value={c.id}>
                        {`${c?.name}`}
                      </option>
                    ))}
                  </select> */}
                </div>
                <div className="mt-4 sm:mt-6">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    // onClick={() => {
                    //   type === 'add' ? createEnrolment() : updateEnrolment(edit_id)
                    // }}
                  >
                    {isSubmitting
                      ? type === 'add'
                        ? 'Adding...'
                        : 'Saving...'
                      : type === 'add'
                      ? 'Add Enrolment'
                      : 'Update Enrolment'}
                  </Button>
                </div>
              </div>
            </form>
          </HalfGeneralSlideover>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
      {renderModal()}
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky min-h-screen">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">Enrolments</h1>
            <p className="my-2 text-sm text-gray-700">
              A list of all the enrolments.
            </p>
          </div>
         {/* <div className="flex justify-between items-center sm:mt-0 sm:ml-16 sm:flex-none gap-4">
            <div className="float-right">
              <div className="hidden sm:block">
                <nav
                  className="isolate flex divide-x divide-gray-200 rounded-lg shadow-2xl"
                  aria-label="Tabs"
                >
                  {tabs.map((tab, tabIdx) => (
                    <a
                      key={tab.name}
                      onClick={() => {
                        toggleAssignedTab(tabIdx)
                      }}
                      className={classNames(
                        tab.current
                          ? 'text-gray-900'
                          : 'text-gray-500 hover:text-gray-700',
                        tabIdx === 0 ? 'rounded-l-lg' : '',
                        tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                        'group relative min-w-0 flex-1 overflow-hidden bg-white py-2.5 px-3 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer'
                      )}
                      aria-current={tab.current ? 'page' : undefined}
                    >
                      <span>{tab.name}</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          tab.current ? 'bg-indigo-500' : 'bg-transparent',
                          'absolute inset-x-0 bottom-0 h-0.5'
                        )}
                      />
                    </a>
                  ))}
                </nav>
              </div>
            </div>
                        </div>*/}
          <div className="sm:mt-0 sm:ml-16 sm:flex-none">
            <Button
              onClick={() =>
                setModal((prev) => ({ ...prev, type: 'add', state: true }))
              }
              className="flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mt-0 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Add
            </Button>
          </div>
        </div>
        {/* <div className="absolute mt-5 w-7/12 flex justify-end items-center text-right right-0 mr-2">
          <div className="w-1/4 ml-1">
            <Select
              className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              name="status"
              id="status"
              isSearchable
              loadingMessage="Getting status..."
              placeholder="Status"
              isClearable={true}
              options={StatusOptions?.map((l) => ({
                ...l,
                label: l.name,
                value: l.id,
              }))}
              onChange={(option) =>
              {
                setStatusFilter(option)
              }}
              isLoading={loading}
            />
          </div>
        </div> */}
        {/* {showAssigned ? (
          <Table
            columns={enrolment_columns()}
            data={enrolmentsOffline ? enrolmentsOffline.filter((l) =>
            {
              if (statusFilter) { return l?.status == statusFilter.name }
              else return true
            }) : null}
            onEditClose={cleanModalData}
          />
        ) : (
          <Table
            columns={enrolment_columns()}
              data={enrolmentsOnline ? enrolmentsOnline.filter((l) =>
              {
                if (statusFilter) { return l?.status == statusFilter.id }
                else return true
              })
                : null}
            onEditClose={cleanModalData}
          />
        )} */}
        {renderTable()}
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false} 
        autoClose={2000}
        hideProgressBar={false}/>
    </Sidebar>
  )
}

export default Enrolments
