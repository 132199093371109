import {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react'
import Input from '../components/input'
import Button from '../components/button'
import app_api from '../config/api/'
import Table from '../components/tables/table'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmDialog from '../components/dialog/confirmation_dialog'
import EmailConfirmation from '../components/dialog/emailConfirmation_dialog'
import { Formik } from 'formik'
import { leadsSchema, familyLeadsSchema, locationSchema } from '../schema'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import { lead_column } from '../components/tables/tableheader'
import {
  ArrowDownIcon,
  XMarkIcon,
  CheckIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  PencilIcon
} from '@heroicons/react/24/outline'
import BigSlideover from '../components/big-slideover'
import { Switch } from '@headlessui/react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { classNames } from '../helpers/classname'
import ColumnSlideOver from '../components/column_slide_over'
import moment from 'moment'
import { MdOutlineFileDownload } from 'react-icons/md'
import { CiFilter } from 'react-icons/ci'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    parentFirstName: '',
    parentLastName: '',
    parentEmail: '',
    parentMobileNo: '',
    address1: '',
    address2: '',
    studentState: '',
    city: '',
    postCode: '',
    studentsFirstName: '',
    studentsLastName: '',
    studentsEmail: '',
    grade: '',
    center: '',
    contactType: 'Phone',
    study_subjects: '',
    help: '',
    groupSizeOptionsValue:'',
    source: 'Manually added',
  },
}

const contactTypeList = [
  { id: 'Phone', name: 'Phone' },
  { id: 'Email', name: 'Email' },
]

const groupSizeOptions = [
  {
    id: 'individual',
    name: '1 on 1 Class',
  },
  {
    id: 'group',
    name: 'Group Class',
  },
]

const initialLocationModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    name: '',
    address: '',
    pointOfContact: '',
    is_active: true,
    accountName: '',
    BSB: '',
    accountNumber: '',
    abn: '',
    gst: '',
    phoneNumber: '',
    emailAddress: '',
    xeroAPIKey: '',
    state: '',
    city: '',
    postCode: '',
    state: '',
    entityName: '',
    entityTypeName: '',
    status: '',
    entityTypeCode: '',
    googleurl: '',
    facebook: '',
    pointOfContact: '',
    brandingThemeID: '',
    sendGridAccount: null
  },
}

const LeadsPage = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [studentEnabled, setStudentEnabled] = useState('Prospective')
  const [parentEnabled, setParentEnabled] = useState('Prospective')
  const [error, setError] = useState(null)
  const [category, setCategory] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [duplicateConfirmation, setDuplicateConfirmation] = useState(false)
  const [location, setLocation] = useState([])
  const [centerFilter, setCenterFilter] = useState(null)
  const [contactTypeFilter, setContactTypeFilter] = useState(null)
  const [sourceTypeFilter, setSourceTypeFilter] = useState(null)
  const [source, setSource] = useState([])
  const [openSlider, setOpenSlider] = useState(false)
  const [defaultState, setDefaultState] = useState([])
  const [GradeList, setGradeList] = useState([])
  const [studentState, setStudentState] = useState([])
  const [studentCity, setStudentCity] = useState([])
  const [postCodeError, setPostCodeError] = useState('')
  const [sum, setSum] = useState(0)
  const [type, setType] = useState(false)
  const [subjectList, setSubjectList] = useState([])
  const [selectGrade, setSelectGrade] = useState()
  const [TabData, setTabData] = useState([])
  const [tuition, setTuition] = useState([])
  const [schemaCondition, setSchemaCondition] = useState(true)
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [emailData, setEmailData] = useState()
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')

  const [locationModal, setLocationModal] = useState(initialLocationModalState)
  const [LocationManager, setLocationManager] = useState([])
  const [abnStatus, setAbnStatus] = useState()
  const [entityName, setEntityName] = useState()
  const [gstValue, setGstValue] = useState()
  const [abnError, setAbnError] = useState('')

  const [columns, setColumns] = useState([])
  const [columnFilter, setColumnsFilter] = useState([])
  let columnName = 'leads'
  let user_id = JSON.parse(localStorage.getItem('moldKey')).id
  let title = 'Category Columns'
  let description = 'Customize columns according to your preferences'
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false)
  const [filterData, setFilterData] = useState({
    startDate: null,
    endDate: null,
    location: '',
    communication: '',
    source: '',
    loading: false
  })
  const [downloadButtonLoading, setDownloadButtonLoading] = useState(false)


  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  const HandelFilters = () => {
    setFilterData({
      ...filterData,
      loading: true,
    })
    setIsFilterSidebarOpen(!isFilterSidebarOpen)
    setStartDate(filterData.startDate)
    setEndDate(filterData.endDate)
    setCenterFilter(filterData.location)
    setContactTypeFilter(filterData.communication)
    setSourceTypeFilter(filterData.source)
  }

  const handleClearFilters = () => {
    setStartDate()
    setEndDate()
    setCenterFilter([])
    setContactTypeFilter(null)
    setSourceTypeFilter(null)
    setIsFilterSidebarOpen(!isFilterSidebarOpen)
  }

  useEffect(() => {
    let payload = {
      countryCode: defaultState[0]?.countryCode,
      isoCode: defaultState[0]?.isoCode
    }
    app_api
      .post('/state-city/city',payload)
      .then((res) => {
        let data = res.data
        if(payload.isoCode === 'VIC'){
          data.push({
            countryCode: "AU",
            latitude: "-37.66955000",
            longitude: "144.88675000",
            name: "Lyndhurst",
            stateCode: "VIC"
          })
        }
        setStudentCity(data)
        
        setError(null)
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
},[defaultState])

  const onDeleteOpen = (id, index) => {
    setModal((prev) => ({ ...prev, id: id, index: index }))
    setConfirmationDialog(true)
  }

  // const onMergeData = async (payload) => {
  //   const { edit_id, index } = modal
  //   if(payload == true){
  //     let value = emailData
  //     await app_api
  //     .patch(`/contact/mergeLead/${edit_id}`, {
  //       ...value,
  //       studentActive: studentEnabled,
  //       parentActive: parentEnabled,
  //       user_email: JSON.parse(localStorage.getItem('moldKey')).email
  //     })
  //     .then((res) => {
  //       cleanModalData()
  //       getCategoryData()
  //       if(value.familyId != null && value.studentId != null){
  //         toast.success('Merged successfully')
  //       }
  //       else{
  //         toast.success('Updated successfully')
  //       }
        
  //     })
  //     .catch((err) => {
  //       toast.error(err?.response?.data?.message)
  //     })
  //   }
  // }

  // const checkParentActive =  (payload) => {
  //   const { edit_id, index } = modal
  //   if(payload == 'Active'){
  //     let value = emailData
  //     app_api
  //     .post(`/contact/checkParentActive/${edit_id}`, {
  //       ...value,
  //       parentActive: 'Active'
  //     })
  //     .then((res) => {
  //       if(res.data.statusCode == 2000)
  //       {
  //         setDuplicateConfirmation(true)
  //       }
  //       else{
  //         return true
  //       }        
  //     })
  //     .catch((err) => {
  //       toast.error(err?.response?.data?.message)
  //     })
  //   }
  // }


  const onEditModal = async (id, index) => {
    let payload = {
      user_email: JSON.parse(localStorage.getItem('moldKey')).email
    }
    await app_api
    .patch(`/contact/leadMarkAsRead/${id}`, payload)
    .then((res) => {
      getCategoryData()
    })
    .catch((err) => {
    })
    await app_api
      .get(`/leads/${id}`)
      .then((res) => {
        console.log(res.data)
        payload = {
          parentFirstName: res.data[0]?.familyMaster?.firstName,
          parentLastName: res.data[0]?.familyMaster?.lastName,
          parentEmail: res.data[0]?.familyMaster?.email,
          parentMobileNo: res.data[0]?.familyMaster?.mobileNo,
          address1: res.data[0]?.familyMaster?.address1,
          address2: res.data[0]?.familyMaster?.address2,
          city: res.data[0]?.familyMaster?.city,
          postCode: res.data[0]?.familyMaster?.postCode,
          studentsFirstName: res.data[0]?.studentMaster?.studentsFirstName,
          studentsLastName: res.data[0]?.studentMaster?.studentsLastName,
          studentsEmail: res.data[0]?.studentMaster?.studentsEmail,
          grade: res.data[0]?.studentMaster?.grade,
          vce: res.data[0]?.studentMaster?.gradeMaster?.vce,
          parentCenter: res.data[0]?.familyMaster?.center,
          contactType: res.data[0]?.contactType,
          study_subjects: res.data[0]?.study_subjects,
          help: res.data[0]?.help,
          source: res.data[0]?.source,
          familyId: res.data[0]?.familyId,
          studentId: res.data[0]?.studentId,
          groupSizeOptionsValue: res.data[0]?.groupSizeOptionsValue,
          studentState: res.data[0]?.familyMaster?.parentState,
          status:res.data[0].status,
          center:res.data[0].studentMaster?.locations.map(l=>l.locationId)
        }
        if(res.data[0]?.studentId == null){
          setSchemaCondition(false)
        }
        setEmailData(payload)
        setStudentEnabled(res.data[0]?.studentMaster?.active)
        setParentEnabled(res.data[0]?.familyMaster?.active)
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
    let stateData
    await app_api
      .post('/state-city/state')
      .then((res) => {
        let data = res.data
        stateData = data
        
        setError(null)
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
    if (payload.studentState) {
      let countryCodeName = stateData.find(
        (l) => l.name == payload.studentState
      )?.countryCode
      let isoCodeName = stateData.find(
        (l) => l.name == payload.studentState
      )?.isoCode
      getCities(countryCodeName, isoCodeName)
    }
    setModal((prev) => ({
      ...prev,
      edit_id: id,
      index: null,
      type: 'edit',
      state: true,
      data: payload,
    }))
    setType(true)
  }

  const handleClick = (num) => {
    let payload = num
    app_api
      .patch(`/user-setting/${user_id}/${columnName}`, payload)
      .then((res) => {
        setOpenSlider(false)
        getColumns()
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

    const getColumns = () => {
    app_api
      .get(`/user-setting/${user_id}/${columnName}`)
      .then((res) => {
        let data = res.data
        setLoading(false)
        setError(null)

        setColumnsFilter(data)
        let dynamic_columns = data.map((key) => {
          if (key.active == true) {
            return {
              Header: key.header == 'Centre' ? 'Location' : key.header,
              accessor: key.table
                ? key.table + '.' + key.column
                : key.column,
            }
          }
        })
        let columns = [...dynamic_columns]
        columns = columns.filter(function (element) {
          return element !== undefined
        })
        setColumns(columns)
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
  }


  const getTuitionData = () => {
    app_api
      .get('tuition-master/all')
      .then((res) => res.data)
      .then((res) => {
        let data = res.data
        
        setError(null)
        setTuition(data)
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const checkPostCode = (postCode, state) => {
    console.log(postCode, state)
    if (state != undefined || postCode != undefined) {
      let booleanValue
      if (state == 'Australian Capital Territory') {
        if (postCode.startsWith('02'))
          booleanValue =
            parseInt(postCode) >= parseInt('0200') &&
            parseInt(postCode) <= parseInt('0299')
        else if (parseInt(postCode) >= 2600 && parseInt(postCode) <= 2618)
          booleanValue = true
        else if (parseInt(postCode) >= 2900 && parseInt(postCode) <= 2920)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'New South Wales') {
        if (parseInt(postCode) >= 1000 && parseInt(postCode) <= 2599)
          booleanValue = true
        else if (parseInt(postCode) >= 2619 && parseInt(postCode) <= 2899)
          booleanValue = true
        else if (parseInt(postCode) >= 2921 && parseInt(postCode) <= 2999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'Northern Territory') {
        if (postCode.startsWith('08'))
          booleanValue =
            parseInt(postCode) >= parseInt('0800') &&
            parseInt(postCode) <= parseInt('0899')
        if (postCode.startsWith('09'))
          booleanValue =
            parseInt(postCode) >= parseInt('0900') &&
            parseInt(postCode) <= parseInt('0999')
        else booleanValue = false
      }
      if (state == 'Queensland') {
        if (parseInt(postCode) >= 4000 && parseInt(postCode) <= 4999)
          booleanValue = true
        else if (parseInt(postCode) >= 9000 && parseInt(postCode) <= 9999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'South Australia') {
        if (parseInt(postCode) >= 5000 && parseInt(postCode) <= 5999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'Tasmania') {
        if (parseInt(postCode) >= 7000 && parseInt(postCode) <= 7999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'Victoria') {
        if (parseInt(postCode) >= 3000 && parseInt(postCode) <= 3999)
          booleanValue = true
        else if (parseInt(postCode) >= 8000 && parseInt(postCode) <= 8999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'Western Australia') {
        if (parseInt(postCode) >= 6000 && parseInt(postCode) <= 6797)
          booleanValue = true
        else if (parseInt(postCode) >= 6800 && parseInt(postCode) <= 6999)
          booleanValue = true
        else booleanValue = false
      }
      if (postCode == '') {
        booleanValue = true
      }

      if (booleanValue) {
        console.log('run')
        setPostCodeError(null)
        return true
      } else {
        setPostCodeError('Invalid postcode')
      }
    }
    else{
      return true
    }
  }

  const handleParentCheckedChanges = () => {
    if(parentEnabled == 'Prospective'){
      setParentEnabled('Active')
      // if(type){
      //   checkParentActive('Active')
      // }
    }
    else{
      setParentEnabled('Prospective')
    }
  }

  const handleStudentCheckedChanges = () => {
    console.log(studentEnabled)
    if(studentEnabled == 'Prospective'){
      setStudentEnabled('Active')
    }
    else{
      setStudentEnabled('Prospective')
    }
  }

  const cleanModalData = () => {
    setSchemaCondition(true)
    setModal(initialModalState)
  }

  const downloadData = async() => {
    setDownloadButtonLoading(true)
    let payload = []
    let Temppayload = {}
    if(endDate){
      console.log(true)
      Temppayload.endDate = endDate
    }
    if(startDate){
      console.log(true)
      Temppayload.startDate = startDate
    }
    if(centerFilter){
      console.log(true)
      Temppayload.center = centerFilter.map(value => value.id)
    }else{
      Temppayload.center = location.map(value => value.id)
    }
    if(sourceTypeFilter){
      console.log(true)
      Temppayload.source = sourceTypeFilter.value
    }
    if(contactTypeFilter){
      console.log(true)
      Temppayload.contactType = contactTypeFilter.value
    }
    payload.push(Temppayload)
    console.log(payload)
    if (startDate && !endDate) {
      toast.warning("Please select both start date and end date.")
    }else{
    app_api
      .post(`leads/download`, payload)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data])) 
        const link = document.createElement('a')
        link.href = process.env.REACT_APP_STATIC_URL+res.data.path
        link.setAttribute('download', "yourfilename.csv")
        document.body.appendChild(link)
        link.click()
        setDownloadButtonLoading(false)
        link.remove()
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
    }
  }

  const onDeleteCategory = () => {
    const { id, index } = modal
    let payload = {
      updatedBy: JSON.parse(localStorage.getItem('moldKey')).email
    }
    app_api
      .post(`leads/deletefamilychild/${id}`, payload)
      .then((res) => {
        toast.success('Deleted Successfully')
        getCategoryData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  useEffect(() => {
    let sum = 0
    category.map((l) => {
      sum += l.markAsRead  
    })
    setSum(sum)
  },[category])

  useEffect(() => {
    getCategoryData()
  }, [page, limit, searchFilter, centerFilter, endDate, startDate,sourceTypeFilter, contactTypeFilter])


  useEffect(() => {
  }, [page])

  const getCategoryData = () => {
    let email = JSON.parse(localStorage.getItem('moldKey')).email
    let locationValues = centerFilter && centerFilter.length > 0
    ? centerFilter.map(value => value.id)
    : location.map(value => value.id);
    let url = `/leads/all?page=${page}&size=${limit}&searchFilter=${searchFilter}&email=${email}`
    if (locationValues.length > 0) {
      url += `&location=${locationValues.join(',')}`;
    }
    // if(centerFilter?.value) url = url + `&location=${centerFilter.value}`
    if(sourceTypeFilter?.value) url = url + `&source=${sourceTypeFilter.value}`
    if(contactTypeFilter?.value) url = url + `&communication=${contactTypeFilter?.value}`
    if(startDate != null && endDate != null) url = url+`&start=${startDate}&end=${endDate}`
      app_api
        .get(url)
        .then((res) => res.data)
        .then((res) => {
          let temp_source = [
            ...new Set(
              res.data.map((l) => {
                if (l.source == null) {
                  return 'No Source'
                } else {
                  return l.source
                }
              })
            ),
          ]
          setSource(temp_source)
          setCategory(res.data)
          setTabData(res.data)
          setFilterData({
            ...filterData,
            loading: false,
          })
          if(res.count <= limit) setPage(0)
          if (res.count < 15) { setLimit(res.count) } else { setLimit(15) }
          if(isNaN(res.count / limit)){setTotalPages(1)}else{setTotalPages(res.count / limit)}
          setCount(res.count)
        })
        .catch((err) => {
          
          setError(err?.response?.data?.message || 'error getting data')
        })
  }

  const getLocationData = () =>
  {
    let email = JSON.parse(localStorage.getItem('moldKey')).email
    let url = `location/all?&email=${email}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) =>{
        
        setError(null)
        setLocation(res.data)
      })
      .catch((err) =>
      {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getGradeData = () => {
    app_api
      .get('/grade-master')
      .then((res) => {
        let data = res.data
        
        setError(null)
        setGradeList(data)
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getSubjectData = () => {
    app_api
      .get('subject-master/all')
      .then((res) => res.data)
      .then((res) => {
        let data = res.data
        
        setError(null)
        setSubjectList(data)
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getLocationData()
    getSubjectData()
    getGradeData()
    getTuitionData()
    getStateDataByBackend()
    getColumns()
  }, [])

  const renderTable = useCallback(() => {
    return (
      <>
       <Table
          progPage={page}
          totalCount={count}
          columns={lead_column({onEditModal,onDeleteOpen,columns,onEditLocationOpen})}
          data={TabData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        />
      </>
    )
  }, [TabData,columns])

  const getStateDataByBackend = () => {
    app_api
      .post('/state-city/state')
      .then((res) => {
        let data = res.data
        let defaultState = data.filter(l => l.name == 'Victoria')
        setDefaultState(defaultState)
        setStudentState(data)
        
        setError(null)
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getCities = (countryCode, isoCode) => {
    let payloadCity = {}
    if (countryCode && isoCode) {
      payloadCity = {
        countryCode: countryCode.toString(),
        isoCode: isoCode.toString(),
      }
    }
    app_api
      .post('/state-city/city', payloadCity)
      .then((res) => {
        let data = res.data
        if (isoCode === 'VIC') {
          data.push({
            countryCode: 'AU',
            latitude: '-37.66955000',
            longitude: '144.88675000',
            name: 'Lyndhurst',
            stateCode: 'VIC',
          })
        }
        setStudentCity(data)
        
        setError(null)
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getLocationManagerData = () =>
  {
    app_api
      .get('users/manager-locations')
      .then((res) =>
      {
        let data = res.data.filter(l => l.is_active == true);
        setLoading(false)
        setError(null)
        setLocationManager(data)
      })
      .catch((err) =>
      {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }
  
  const getAbnValue = (abnValue) =>
  {
    let guid = "220fe5cd-f965-4f2a-93ce-61fa1626d8cb";
    const url = `https://abr.business.gov.au/json/AbnDetails.aspx?abn=${abnValue}&callback=callback&guid=${guid}`;
    fetch(url)
      .then(response => response.text())
      .then(data =>
      {
        const jsonData = extractJsonData(data);
        if (jsonData.Abn)
        {
          setGstValue(jsonData.Gst);
          setAbnStatus(jsonData.AbnStatus)
          setEntityName(jsonData.EntityName)
          setAbnError(null);
        }
        else
        {
          setAbnError('Business not found');
        }

      })
      .catch(error =>
      {
        console.error('An error occurred:', error);
      });

  }
  function extractJsonData (responseText)
  {
    const startIndex = responseText.indexOf('{');
    const endIndex = responseText.lastIndexOf('}');
    const jsonString = responseText.substring(startIndex, endIndex + 1);
    const jsonData = JSON.parse(jsonString);
    return jsonData;
  }

  const onEditLocationOpen = (id, index) =>{
    const data = location.filter((e)=>e.id == id)
    setLocationModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: data[0],
    }))
    if(data[0].state){
      let countryCodeName = studentState.find(l => l.name == data[0].state)?.countryCode
      let isoCodeName = studentState.find(l => l.name == data[0].state)?.isoCode
      getCities(countryCodeName, isoCodeName)
    }
  }

  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    return (
      <Formik
        initialValues={data}
        validationSchema={schemaCondition == true ? leadsSchema : familyLeadsSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          if (type === 'add') {
            app_api
              .post('contact/lead/Manually%20Added', {
                ...values,
                studentActive: studentEnabled,
                parentActive: parentEnabled,
                user_email: JSON.parse(localStorage.getItem('moldKey')).email
              })
              .then((res) => {
                cleanModalData()
                getCategoryData()
                setSubmitting(false)
                toast.success('Successfully added')
              })
              .catch((err) => {
                if (err.response.data.message) {
                  toast.error(err.response.data.message)
                } else {
                  toast.error('Something Went Wrong')
                }
                cleanModalData()
                setSubmitting(false)
              })
          } else {
            app_api
              .patch(`contact/lead/update/${edit_id}`, {
                ...values,
                studentActive: studentEnabled,
                parentActive: parentEnabled,
                user_email: JSON.parse(localStorage.getItem('moldKey')).email
              })
              .then((res) => {
                setSubmitting(false)
                console.log(res)
                if (res.data.statusCode == 202){
                  setDuplicateConfirmation(true)
                  setSubmitting(false)
                }
                else
                  {
                    if (res.data.statusCode == 2000){
                      setDuplicateConfirmation(true)
                    }
                    else{
                      cleanModalData()
                      getCategoryData()
                      toast.success('Updated successfully')
                      setSubmitting(false)
                    }
                  }
              })
              .catch((err) => {
                toast.error(err.response.data.message)
              })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          setFieldTouched,
          handleChange,
          handleSubmit,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <BigSlideover
            title={type === 'add' ? 'Manually Add a Lead' : 'Edit a Lead'}
            open={state}
            setOpen={() => cleanModalData()}
            data={data}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Parent First Name
                      </label>
                      <span className="text-red-500 mx-1 ">*</span>
                    </div>
                    <Input
                      name="parentFirstName"
                      value={values.parentFirstName}
                      autoComplete="off"
                      disabled={values.status === "Enrolled" || values.status === "Retired" }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={()=>setFieldTouched('parentFirstName',false)}
                      placeholder="Enter Parent First Name..."
                    />
                    {touched.parentFirstName && (
                      <p className="text-red-700 error_msg">
                        {errors.parentFirstName}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Parent Last Name
                      </label>
                      <span className="text-red-500 mx-1">*</span>
                    </div>
                    <Input
                      name="parentLastName"
                      // label="Parent Last Name"
                      value={values.parentLastName}
                      autoComplete="off"
                      disabled={values.status === "Enrolled" || values.status === "Retired" }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={()=>setFieldTouched('parentLastName',false)}
                      placeholder="Enter Parent Last Name..."
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                    />
                    {touched.parentLastName && (
                      <p className="text-red-700 error_msg">
                        {errors.parentLastName}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Parent Email
                      </label>
                      <span className="text-red-500 mx-1">*</span>
                    </div>
                    <Input
                      name="parentEmail"
                      // label="Parent Email"
                      value={values.parentEmail}
                      autoComplete="off"
                      disabled={values.status === "Enrolled" || values.status === "Retired" }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={()=>setFieldTouched('parentEmail',false)}
                      placeholder="Enter Parent Email..."
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                    />
                    {touched.parentEmail && (
                      <p className="text-red-700 error_msg">
                        {errors.parentEmail}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Parent's Mobile No.
                      </label>
                      <span className="text-red-500 mx-1">*</span>
                    </div>
                    <Input
                      name="parentMobileNo"
                      type="tel"
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          parentMobileNo: e.target.value,
                        })
                      }}
                      onFocus={()=>setFieldTouched('parentMobileNo',false)}
                      disabled={values.status === "Enrolled" || values.status === "Retired" }
                      value={values.parentMobileNo}
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                      placeholder="Enter parent phone number"
                    />
                    {touched.parentMobileNo && (
                      <p className="text-red-700 error_msg">
                        {errors.parentMobileNo}
                      </p>
                    )}
                  </div>
                </div>
                <div className='mt-4'>
                <Switch.Group
                  as="div"
                  className="flex items-center justify-start"
                >
                  <span className="flex flex-col">
                    <Switch.Label
                      as="span"
                      className="text-sm font-medium text-gray-900"
                      passive
                    >
                      Activate Parent
                    </Switch.Label>
                  </span>
                  <Switch
                    checked={parentEnabled == 'Active'} 
                    onChange={()=>{
                      handleParentCheckedChanges()
                    }}
                    disabled={values.status === "Enrolled" || values.status === "Retired" }
                    className={classNames(
                      parentEnabled == 'Active' ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        parentEnabled == 'Active' ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                  <div className="col-span-2">
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    Preferred Communication
                  </label>
                  <span className="text-red-500 mx-1">*</span>
                </div>
                <Select
                  className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="contactType"
                  id="contactType"
                  isDisabled={values.status === "Enrolled" || values.status === "Retired" }
                  isSearchable
                  placeholder="Select a preferred communication"
                  value={
                    values.contactType
                      ? contactTypeList?.map((l) => ({
                          value: contactTypeList?.find(
                            (e) => e.name == values.contactType
                          )?.id,
                          label: contactTypeList?.find(
                            (e) => e.name == values.contactType
                          )?.name,
                        }))
                      : null
                  }
                  options={contactTypeList?.map((l) => ({
                    ...l,
                    label: l.name,
                    value: l.id,
                  }))}
                  onChange={(option) => {
                    setValues({
                      ...values,
                      contactType: option.value,
                    })
                  }}
                  onBlur={handleBlur}
                  styles={{
                    singleValue: (provided) => ({
                      ...provided,
                      fontWeight: values.status === "Enrolled" || values.status === "Retired" ? 'bold' : 'normal',
                      fontSize: values.status === "Enrolled" || values.status === "Retired" ? '16px' : 'inherit', 
                    })
                  }}
                />
                </div>
                <div className="col-span-2">
                    <div className="flex">
                      <label
                        htmlFor="batchId"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Parent Location
                      </label>
                      <span className="text-red-500 mx-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="parentCenter"
                      id="parentCenter"
                      isSearchable
                      loadingMessage="Getting location..."
                      placeholder="Select a location"
                      value={ 
                        values.parentCenter
                          ? location?.map((l) => ({
                              value: location?.find(
                                (e) => e.id == values.parentCenter
                              )?.id,
                              label: location?.find(
                                (e) => e.id == values.parentCenter
                              )?.name,
                            }))
                          : null              
                      }
                      options={location?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          parentCenter: option.value,
                        })
                      }}
                      isDisabled={values.status === "Enrolled" || values.status === "Retired" }
                      onFocus={()=>setFieldTouched('parentCenter',false)}
                      onBlur={handleBlur}
                      styles={{
                        singleValue: (provided) => ({
                          ...provided,
                          fontWeight: values.status === "Enrolled" || values.status === "Retired" ? 'bold' : 'normal',
                          fontSize: values.status === "Enrolled" || values.status === "Retired" ? '16px' : 'inherit', 
                        })
                      }}
                    />
                    {touched.parentCenter && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.parentCenter}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-4"></div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    Address Line 1
                  </label>
                  {/*  <span className="text-red-500 mx-1">*</span> */}
                </div>
                <Input
                  name="address1"
                  value={values.address1}
                  disabled={values.status === "Enrolled" || values.status === "Retired" }
                  type="textarea"
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter your address...."
                  // onChange={(event) =>
                  //   onModalHandleChange(event.target.name, event.target.value)
                  // }
                />
                {touched.address1 && (
                  <p className="text-red-700 error_msg">{errors.address1}</p>
                )}
                <div className="mt-4"></div>
                <Input
                  name="address2"
                  label="Address Line 2"
                  value={values.address2}
                  disabled={values.status === "Enrolled" || values.status === "Retired" }
                  type="textarea"
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter your address...."
                  // onChange={(event) =>
                  //   onModalHandleChange(event.target.name, event.target.value)
                  // }
                />
                <div className="mt-4"></div>

                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        City
                      </label>
                      {/*  <span className="text-red-500 mx-1">*</span> */}
                    </div>
                    <Select
                      className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 disabled:font-bold disabled:text-base shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="city"
                      id="city"
                      isSearchable
                      loadingMessage="Getting City..."
                      placeholder="Select City..."
                      value={
                        values.city
                          ? studentCity?.map((l) => ({
                              value: studentCity?.find(
                                (e) => e.name == values.city
                              )?.isoCode,
                              label: studentCity?.find(
                                (e) => e.name == values.city
                              )?.name,
                            }))
                          : null
                      }
                      options={studentCity.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.name,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          city: option.value,
                        })
                      }}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      isDisabled={values.status === "Enrolled" || values.status === "Retired" }
                      maxMenuHeight={110}
                      styles={{
                        singleValue: (provided) => ({
                          ...provided,
                          fontWeight: values.status === "Enrolled" || values.status === "Retired" ? 'bold' : 'normal',
                          fontSize: values.status === "Enrolled" || values.status === "Retired" ? '16px' : 'inherit', 
                        })
                      }}
                    />
                    {touched.city && (
                      <p className="text-red-700 error_msg mt-2">
                        {errors.city}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        State
                      </label>
                      {/*  <span className="text-red-500 mx-1">*</span> */}
                    </div>
                    <Select
                      className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="studentState"
                      id="studentState"
                      isSearchable
                      loadingMessage="Getting State..."
                      placeholder="Select State..."
                      value={
                        values.studentState
                          ? studentState?.map((l) => ({
                              value: studentState?.find(
                                (e) => e.name == values.studentState
                              )?.isoCode,
                              label: studentState?.find(
                                (e) => e.name == values.studentState
                              )?.name,
                            }))
                          : defaultState ?
                            studentState?.map((l) => ({
                            value: studentState?.find((e) => e.name == defaultState[0].name)?.isoCode,
                            label: studentState?.find((e) => e.name == defaultState[0].name)?.name
                            }))
                          : null
                      }
                      options={studentState.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.name,
                      }))}
                      onChange={(option) => {
                        getCities(option.countryCode, option.isoCode)
                        checkPostCode(values.postCode, option.value)
                        setValues({
                          ...values,
                          studentState: option.value
                        })
                      }}
                      onBlur={handleBlur}
                      isDisabled={values.status === "Enrolled" || values.status === "Retired" }
                      menuPlacement="auto"
                      maxMenuHeight={110}
                      styles={{
                        singleValue: (provided) => ({
                          ...provided,
                          fontWeight: values.status === "Enrolled" || values.status === "Retired" ? 'bold' : 'normal',
                          fontSize: values.status === "Enrolled" || values.status === "Retired" ? '16px' : 'inherit', 
                        })
                      }}
                    />
                    {touched.studentState && (
                      <p className="text-red-700 error_msg mt-2">
                        {errors.studentState}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-4"></div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    Postcode
                  </label>
                  {/*  <span className="text-red-500 mx-1">*</span> */}
                </div>
                <Input
                  name="postCode"
                  id="postCode"
                  value={values.postCode}
                  type="text"
                  maxLength={4}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      postCode: e.target.value,
                    })
                    checkPostCode(e.target.value, values.studentState)
                  }}
                  disabled={values.status === "Enrolled" || values.status === "Retired" }
                  placeholder="Enter your postcode"
                  // onChange={(event) =>
                  //   onModalHandleChange(event.target.name, event.target.value)
                  // }
                />
                {postCodeError && (
                      <p className="text-red-700 error_msg">{postCodeError}</p>
                )}
                {schemaCondition && (
                  <>
                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Student First Name
                      </label>
                      <span className="text-red-500 mx-1">*</span>
                    </div>
                    <Input
                      name="studentsFirstName"
                      // label="Student First Name"
                      value={values.studentsFirstName}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Student First Name..."
                      onFocus={()=> setFieldTouched('studentsFirstName',false)}
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                      disabled={values.status === "Enrolled" || values.status === "Retired" }
                    />
                    {touched.studentsFirstName && (
                      <p className="text-red-700 error_msg">
                        {errors.studentsFirstName}
                      </p>
                    )}
                  </div>

                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Student Last Name
                      </label>
                      <span className="text-red-500 mx-1">*</span>
                    </div>
                    <Input
                      name="studentsLastName"
                      value={values.studentsLastName}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          studentsLastName: e.target.value,
                        })
                      }}
                      placeholder="Enter Student Last Name..."
                      disabled={values.status === "Enrolled" || values.status === "Retired" }
                      onFocus={()=> setFieldTouched('studentLastName',false)}
                    />
                    {touched.studentsLastName && (
                      <p className="text-red-700 error_msg">
                        {errors.studentsLastName}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-4"></div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    Student Email
                  </label>
                </div>
                <Input
                  name="studentsEmail"
                  value={values.studentsEmail}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Student Last Name..."
                  disabled={values.status === "Enrolled" || values.status === "Retired" }
                  onFocus={()=> setFieldTouched('studentEmail',false)}

                />
                {touched.studentsEmail && (
                  <p className="text-red-700 error_msg">
                    {errors.studentsEmail}
                  </p>
                )}
                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Type of Class
                      </label>
                      <span className="text-red-500 mx-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="groupSizeOptionsValue"
                      id="groupSizeOptionsValue"
                      isSearchable
                      loadingMessage="Getting Type of Class..."
                      placeholder="Select type of class..."
                      value={
                        values.groupSizeOptionsValue
                          ? groupSizeOptions?.map((l) => ({
                              value: groupSizeOptions?.find(
                                (e) => e.name == values.groupSizeOptionsValue
                              )?.id,
                              label: groupSizeOptions?.find(
                                (e) => e.name == values.groupSizeOptionsValue
                              )?.name,
                            }))
                          : null
                      }
                      options={groupSizeOptions?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          groupSizeOptionsValue: option.label,
                        })
                      }}
                      onBlur={handleBlur}
                      onFocus={()=> setFieldTouched('groupSizeOptionsValue',false)}
                      isDisabled={values.status === "Enrolled" || values.status === "Retired" }
                      styles={{
                        singleValue: (provided) => ({
                          ...provided,
                          fontWeight: values.status === "Enrolled" || values.status === "Retired" ? 'bold' : 'normal',
                          fontSize: values.status === "Enrolled" || values.status === "Retired" ? '16px' : 'inherit', 
                        })
                      }}

                    />
                    {touched.groupSizeOptionsValue && (
                      <p className="text-red-700 error_msg">
                        {errors.groupSizeOptionsValue}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Grade
                      </label>
                      <span className="text-red-500 mx-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="grade"
                      id="grade"
                      isSearchable
                      loadingMessage="Getting Grade..."
                      placeholder="Select Grade"
                      value={
                        values.grade
                          ? GradeList?.map((l) => ({
                              value: GradeList?.find(
                                (e) => e.id == values.grade
                              )?.id,
                              label: GradeList?.find(
                                (e) => e.id == values.grade
                              )?.grade,
                            }))
                          : null
                      }
                      options={GradeList?.map((l) => ({
                        ...l,
                        label: l.grade,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          grade: option.value,
                          vce: option.vce,
                        })
                        setSelectGrade(option.vce)
                      }}
                      onBlur={handleBlur}
                      onFocus={()=> setFieldTouched('grade',false)}
                      isDisabled={values.status === "Enrolled" || values.status === "Retired" }
                      styles={{
                        singleValue: (provided) => ({
                          ...provided,
                          fontWeight: values.status === "Enrolled" || values.status === "Retired" ? 'bold' : 'normal',
                          fontSize: values.status === "Enrolled" || values.status === "Retired" ? '16px' : 'inherit', 
                        })
                      }}
                    />
                    {touched.grade && (
                      <p className="text-red-700 error_msg">{errors.grade}</p>
                    )}
                  </div>
                </div>
                </>)}
                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                  <div className="col-span-2">
                    <div className="flex">
                      <label
                        htmlFor="batchId"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Location
                      </label>
                      <span className="text-red-500 mx-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="center"
                      id="center"
                      isSearchable
                      loadingMessage="Getting location..."
                      placeholder="Select a location"
                      isMulti
                      isClearable
                      value={ 
                        values?.center
                          ? values?.center?.map((l) => ({
                              value: l,
                              label: location?.find(
                                (e) => e.id == l
                              )?.name,
                            }))
                          : null              
                      }
                      options={location?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          center: option.map(l=>l.value),
                        })
                      }}
                      isDisabled={values.status === "Enrolled" || values.status === "Retired" }
                      onFocus={()=>setFieldTouched('center',false)}
                      onBlur={handleBlur}
                      styles={{
                        singleValue: (provided) => ({
                          ...provided,
                          fontWeight: values.status === "Enrolled" || values.status === "Retired" ? 'bold' : 'normal',
                          fontSize: values.status === "Enrolled" || values.status === "Retired" ? '16px' : 'inherit', 
                        })
                      }}
                    />
                    {touched.center && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.center}
                      </p>
                    )}
                  </div>
                  {schemaCondition && (<>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Like to study subject
                      </label>
                      <span className="text-red-500 mx-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="study_subjects"
                      id="study_subjects"
                      isSearchable
                      placeholder="Select a subject"
                      options={subjectList
                        .filter((l) => {
                          if (values.vce == l.vce) {
                            return true
                          } else {
                            return false
                          }
                        })
                        .map((l) => ({
                          ...l,
                          label: l.subject,
                          value: l.subject,
                        }))}
                      value={
                        values.study_subjects
                          ? subjectList.map((l) => ({
                              value: subjectList.find(
                                (e) => e.subject == values.study_subjects
                              )?.id,
                              label: subjectList.find(
                                (e) => e.subject == values.study_subjects
                              )?.subject,
                            }))
                          : null
                      }
                      isDisabled={values.status === "Enrolled" || values.status === "Retired" }
                      onChange={(option) => {
                        console.log(option)
                        setValues({
                          ...values,
                          study_subjects: option.subject,
                        })
                      }}
                      onFocus={()=> setFieldTouched('study_subjects',false)}
                      onBlur={handleBlur}
                      styles={{
                        singleValue: (provided) => ({
                          ...provided,
                          fontWeight: values.status === "Enrolled" || values.status === "Retired" ? 'bold' : 'normal',
                          fontSize: values.status === "Enrolled" || values.status === "Retired" ? '16px' : 'inherit', 
                        })
                      }}
                    /> {touched.study_subjects && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.study_subjects}
                      </p>
                    )}
                  </div>
                  </>)}
                </div>
                {schemaCondition && (<>
                <div className="mt-4"></div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    Purpose of attending tuition
                  </label>
                  <span className="text-red-500 mx-1">*</span>
                </div>
                <Select
                  className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="help"
                  id="help"
                  isSearchable
                  placeholder="Select a purpose"
                  options={tuition.map((l) => ({
                    ...l,
                    label: l.tuition,
                    value: l.id,
                  }))}
                  value={
                    values.help
                      ? tuition?.map((l) => ({
                          value: tuition?.find((e) => e.tuition == values.help)
                            ?.id,
                          label: tuition?.find((e) => e.tuition == values.help)
                            ?.tuition,
                        }))
                      : null
                  }
                  onChange={(option) => {
                    setValues({
                      ...values,
                      help: option.label,
                    })
                  }}
                  onFocus={()=> setFieldTouched('help',false)}
                  isDisabled={values.status === "Enrolled" || values.status === "Retired" }
                  onBlur={handleBlur}
                  styles={{
                    singleValue: (provided) => ({
                      ...provided,
                      fontWeight: values.status === "Enrolled" || values.status === "Retired" ? 'bold' : 'normal',
                      fontSize: values.status === "Enrolled" || values.status === "Retired" ? '16px' : 'inherit', 
                    })
                  }}
                />
                {touched.help && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.help}
                      </p>
                )}
                <div className='mt-4'>
                <Switch.Group
                  as="div"
                  className="flex items-center justify-start"
                >
                  <span className="flex flex-col">
                    <Switch.Label
                      as="span"
                      className="text-sm font-medium text-gray-900"
                      passive
                    >
                      Activate Student
                    </Switch.Label>
                  </span>
                  <Switch
                    checked={studentEnabled == 'Active'}
                    onChange={()=>{handleStudentCheckedChanges()}}
                    disabled={values.status === "Enrolled" || values.status === "Retired" }                    
                    className={classNames(
                      studentEnabled == 'Active' ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        studentEnabled == 'Active' ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </Switch.Group>
                </div>
                </>)}
                <div className="mt-4 sm:mt-6">
                  <Button type="submit" disabled={isSubmitting || postCodeError || values.status === "Enrolled" || values.status === "Retired"}>
                    {isSubmitting
                      ? type === 'add'
                        ? 'Adding...'
                        : 'Saving...'
                      : type === 'add'
                      ? 'Add Lead'
                      : 'Update Lead'}
                  </Button>
                </div>
              </div>
            </form>
          </BigSlideover>
        )}
      </Formik>
    )
  }

  const renderLocationModal = () =>{
    const { type, state, edit_id, data } = locationModal
    return (
      <Formik
        initialValues={data}
        validationSchema={locationSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) =>{
          if (type === 'add')
          {
            app_api
              .post('/location', {
                ...values, gst: gstValue, abnStatus: abnStatus,
                entityName: entityName,
              })
              .then((res) =>
              {
                getLocationData()
                cleanModalData()
                setSubmitting(false)
                toast.success('Successfully added location')
                resetForm()
              })
              .catch((err) =>
              {
                toast.error(err.response.data.message)
                getLocationData()
                // cleanModalData()
                setSubmitting(false)
              })
          } else
          {
            app_api
              .patch(`/location/${edit_id}`, { ...values, gst: gstValue })
              .then((res) =>
              {
                let updatedLocationData = [...location]
                updatedLocationData[modal.index] = modal.data
                setLocation(updatedLocationData)
                getLocationData()
                toast.success('Successfully updated location')
                cleanModalData()
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {
                toast.error(err.response.data.message)
               })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          setFieldTouched,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
          resetForm,
        }) => (
          <BigSlideover
            open={state}
            setOpen={() =>{
              setLocationModal((prev) => ({ ...prev, state: false }))
            }}
            title={'Location'}
            handleClick={''}
            data={''}
            >
            <form onSubmit={handleSubmit} noValidate>
              <div className="text-left mt-4">
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Name</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  name="name"
                  label="Name"
                  value={values.name}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder='Enter Name...'
                  onFocus={()=>setFieldTouched('name',false)}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
                {touched.name && (
                  <p className="text-red-700 mb-1 error_msg">{errors.name}</p>
                )}
                  </div>
                  <div className="col-span-2">
                <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Point of Contact</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <Select
                      className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="pointOfContact"
                      id="pointOfContact"
                      isSearchable
                      loadingMessage="Getting User..."
                      placeholder="Select a user..."
                    value={
                      values.pointOfContact
                        ? LocationManager?.map((l) => ({
                          value: LocationManager?.find(
                            (e) => e.name == values.pointOfContact
                          )?.id,
                          label: LocationManager?.find(
                            (e) => e.name == values.pointOfContact
                          )?.name,
                        }))
                        : null
                    }
                      options={LocationManager.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.name,
                    }))}
                    onChange={(option) =>
                    {
                      console.log(option)
                      setValues({
                        ...values,
                        pointOfContact: option.value,
                        userId: option.id
                      })
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    onFocus={()=>setFieldTouched('city',false)}
                    maxMenuHeight={110}                    
                  />
                    {touched.city && (
                      <p className="text-red-700 error_msg mt-2">{errors.city}</p>
                    )}
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className="col-span-2">
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Phone / Mobile Number</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <input
                      name="phoneNumber"
                      label="Mobile / Phone Number"
                      placeholder='Enter Mobile / Phone Number'
                      type="tel"
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      value={values.phoneNumber}
                      autoComplete="off"
                      onFocus={()=>setFieldTouched('phoneNumber',false)}
                      onBlur={(e) =>
                      {
                        setValues({
                          ...values,
                          phoneNumber: e.target.defaultValue,
                        })
                      }}
                      onChange={handleChange}
                    />
                    {touched.phoneNumber && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.phoneNumber}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Email Address</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="emailAddress"
                  label="Email Address"
                  type="text"
                  value={values.emailAddress}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={()=>setFieldTouched('emailAddress',false)}
                  placeholder="Enter Email Address..."
                />
                {touched.emailAddress && (
                  <p className="text-red-700 error_msg">{errors.emailAddress}</p>
                )}
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Address Line 1</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="address"
                  label="Address"
                  type="text"
                  value={values.address}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={()=>setFieldTouched('address',false)}
                  placeholder="Enter Address..."
                />
                {touched.address && (
                  <p className="text-red-700 error_msg">{errors.address}</p>
                )}
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                  <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >City</label>
                    <span className='text-red-700 ml-1'>*</span>
                  </div>
                  <Select
                      className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="studentCity"
                      id="studentCity"
                    isSearchable
                      loadingMessage="Getting City..."
                      placeholder="Select City..."
                    value={
                      values.city
                        ? studentCity?.map((l) => ({
                          value: studentCity?.find(
                            (e) => e.name == values.city
                          )?.isoCode,
                          label: studentCity?.find(
                            (e) => e.name == values.city
                          )?.name,
                        }))
                        : null
                    }
                      options={studentCity.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.name,
                    }))}
                    onChange={(option) =>
                    {
                      setValues({
                        ...values,
                        city: option.value,
                        postCode: ''
                      })
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                    onFocus={()=>setFieldTouched('city',false)}
                  />
                    {touched.city && (
                      <p className="text-red-700 error_msg mt-2">{errors.city}</p>
                    )}
                  </div>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >State</label>
                    <span className='text-red-700 ml-1'>*</span>
                  </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="state"
                      id="state"
                      isSearchable
                      loadingMessage="Getting State..."
                      placeholder="Select a State..."
                      value={
                        values.state
                          ? studentState?.map((l) => ({
                            value: studentState?.find((e) => e.name == values.state)?.isoCode,
                            label: studentState?.find((e) => e.name == values.state)?.name,
                          }))
                          : defaultState ?
                            studentState?.map((l) => ({
                            value: studentState?.find((e) => e.name == defaultState[0].name)?.isoCode,
                            label: studentState?.find((e) => e.name == defaultState[0].name)?.name
                            }))
                          : null
                      }
                      options={studentState.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.name,
                      }))}
                      onChange={(option) =>
                      {
                        getCities(
                          option.countryCode,
                          option.isoCode,
                        )
                        setValues({
                          ...values,
                          state: option.value,
                        })
                        checkPostCode(values.postCode, option.value)
                      }}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                      onFocus={()=>setFieldTouched('state',false)}
                    />
                    {touched.state && (
                      <p className="text-red-700 error_msg">{errors.state}</p>
                    )}
                  </div></div>
                <div className='mt-4'></div>

                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Postcode</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="postCode"
                  label="postCode"
                  type="text"
                  value={values.postCode}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      postCode: e.target.value,
                    })
                    checkPostCode(e.target.value, values.state)
                  }}
                  placeholder="Enter postcode"
                  maxLength={4}
                  onFocus={()=>setFieldTouched('postCode',false)}
                />
                {touched.postCode && (
                  <p className="text-red-700 error_msg">{errors.postCode}</p>
                )}
                {postCodeError && (
                      <p className="text-red-700 error_msg">{postCodeError}</p>
                )}
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Account Name</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="accountName"
                  label="Account Name"
                  type="text"
                  value={values.accountName}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Account Name..."
                  onFocus={()=>setFieldTouched('accountName',false)}
                />
                {touched.accountName && (
                  <p className="text-red-700 error_msg">{errors.accountName}</p>
                )}
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >BSB</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="BSB"
                      label="BSB"
                      type="text"
                      value={values.BSB}
                      autoComplete="off"
                      maxLength="6"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Bank BSB..."
                      onFocus={()=>setFieldTouched('BSB',false)}
                    />
                    {touched.BSB && (
                      <p className="text-red-700 error_msg">{errors.BSB}</p>
                    )}
                  </div>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Account Number</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="accountNumber"
                      label="Account Number"
                      type="text"
                      value={values.accountNumber}
                      autoComplete="off"
                      maxLength="11"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Bank Account Number..."
                      onFocus={()=>setFieldTouched('accountNumber',false)}
                    />
                    {touched.accountNumber && (
                      <p className="text-red-700 error_msg">{errors.accountNumber}</p>
                    )}
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Google My Business</label>
                  {/*<span className='text-red-700 ml-1'>*</span>*/}
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="googleurl"
                  label="Account Number"
                  type="url"
                  value={values.googleurl}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="https://www.google.com/"
                  onFocus={()=>setFieldTouched('googleurl',false)}
                />
                  </div>
                  <div className='col-span-2'>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Facebook</label>
                  {/*<span className='text-red-700 ml-1'>*</span>*/}
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="facebook"
                  label="Account Number"
                  type="url"
                  value={values.facebook}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={()=>setFieldTouched('facebook',false)}
                  placeholder="https://www.facebook.com/"
                />
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >ABN</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <div className='flex'>
                  <input
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="abn"
                    type="text"
                    value={values.abn}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={()=>setFieldTouched('abn',false)}
                    placeholder="Enter Australian Business Number..."
                  />
                  <p className="w-8 rounded-r-md border-y border-r border-gray-300  py-1 appearance-none " onClick={() => getAbnValue(values.abn)}><MagnifyingGlassIcon className='w-full drop-shadow-md' /></p>
                </div>{touched.abn && (
                  <p className="text-red-700 error_msg">{errors.abn}</p>
                )}
                {abnError && (
                  <p className="text-red-700 error_msg">{abnError}</p>
                )}
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >GST Register Date</label>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="gst"
                  label="GST"
                  type="text"
                  value={values.gstValue}
                  autoComplete="off"
                  onBlur={handleBlur}
                  disabled
                  // onChange={handleChange}
                  placeholder={gstValue ? moment(gstValue).format('DD MM YYYY') : null}
                  onFocus={()=>setFieldTouched('gst',false)}
                />
                {touched.gst && (
                  <p className="text-red-700 error_msg">{errors.gst}</p>
                )}
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >ABN Status</label>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="abnStatus"
                  type="text"
                  value={values.abnStatus}
                  autoComplete="off"
                  onBlur={handleBlur}
                  disabled
                  // onChange={handleChange}
                  placeholder={abnStatus}

                />
                  </div><div className='col-span-2'>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Entity Name</label>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="entityName"
                  type="text"
                  value={values.entityName}
                  autoComplete="off"
                  onBlur={handleBlur}
                  disabled
                  placeholder={entityName}
                />
                  </div></div>
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Xero API Key</label>
                  {/* <span className='text-red-700 ml-1'>*</span> */}
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="xeroAPIKey"
                  label="Xero API Key"
                  type="text"
                  value={values.xeroAPIKey}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Xero API Key..."
                  onFocus={()=>setFieldTouched('xeroAPIKey',false)}
                />
                {touched.xeroAPIKey && (
                  <p className="text-red-700 error_msg">{errors.xeroAPIKey}</p>
                )}
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Xero Invoice Branding Theme ID</label>
                  {/* <span className='text-red-700 ml-1'>*</span> */}
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="brandingThemeID"
                  type="text"
                  value={values.brandingThemeID}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Xero Invoice Branding Theme ID..."
                  onFocus={()=>setFieldTouched('brandingThemeID',false)}
                />
                {touched.brandingThemeID && (
                  <p className="text-red-700 error_msg">{errors.brandingThemeID}</p>
                )}
                <div className="mt-4 sm:mt-6"></div>

                <div className="mt-4 sm:mt-6">
                  <Button type="submit"
                    disabled={isSubmitting || postCodeError}>
                    {isSubmitting ? (type === 'add' ? 'Adding...' : 'Saving...') : (type === 'add' ? 'Add Location' : 'Update Location')}
                  </Button>
                </div>
              </div>

            </form>
          </BigSlideover>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
      <ConfirmDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteCategory}
      />
       {/* <EmailConfirmation
        setOpen={setDuplicateConfirmation}
        open={duplicateConfirmation}
        onMerge={onMergeData}
        data={emailData}
      /> */}
      {renderModal()}
      {renderLocationModal()}
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky min-h-screen">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <div className='flex justify-start items-center'>
              <h1 className="text-3xl font-semibold text-gray-900">Leads</h1><d className='text-white mt-1 text-md ml-2 bg-red-700 w-7 h-7 rounded-full flex justify-center'><p>{sum}</p></d><p className='ml-2 text-sm text-gray-400 mt-1'>leads not read</p>
            </div>
          </div>
          <div className="sm:mt-0 sm:ml-16 sm:flex-none">
            <div className="flex justify-end items-start">
              <div className="ml-2"></div>
              <Button
                onClick={() =>{
                  setModal((prev) => ({ ...prev, state: true, type: 'add',
                  data: {
                    ...prev.data,
                    studentState: 'Victoria',
                  },
                  }))
                  setStudentEnabled(false)
                  setParentEnabled(false)
                  setSchemaCondition(true)
                }
                }
                className="flex justify-center items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 mt-0 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                Add
              </Button>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="flex items-center justify-center">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) :
          <>
            <div className="relative mx-2">
              <div className="w-full flex md:mt-10 mt-16 absolute justify-between gap-2">
                <div></div>
                <div className="flex justify-between gap-4 ">
                  <div
                    onClick={() => setIsFilterSidebarOpen(!isFilterSidebarOpen)}
                    className="rounded-full py-2 px-2 cursor-pointer border shadow-sm bg-indigo-800 hover:bg-indigo-700 text-white"
                  >
                    {filterData.loading ? (
                      <div className="flex items-center justify-center">
                        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status">
                        </div>
                      </div>) : <CiFilter className="w-6 h-6" />}
                  </div>
                  <div
                    className="rounded-full py-2 px-2 cursor-pointer border shadow-sm bg-indigo-800 hover:bg-indigo-700 text-white"
                    onClick={() => {
                      downloadData();
                    }}
                  >
                    {downloadButtonLoading ? (
                      <div className="flex items-center justify-center">
                        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status">
                        </div>
                      </div>) : <MdOutlineFileDownload className="w-6 h-6" />}
                  </div>
                </div>
              </div>

              {/* Filter sidebar */}
              {isFilterSidebarOpen && (
                <div className='h-full'>
                  <div className="absolute bg-white border rounded-lg shadow-lg w-full  md:w-1/2 lg:w-1/4 md:top-24 md:right-0 top-28 z-10">
                    <div className='flex justify-between p-2'>
                      <span className=" text-sm text-gray-700 ">Filters</span>
                      <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                        setFilterData({
                          startDate: null,
                          endDate: null,
                          location: '',
                          communication: '',
                          source: '',
                          loading: false
                        })
                        handleClearFilters()
                      }}> Reset All </span>
                    </div>
                    <hr className='' />
                    <div className="w-full flex flex-col p-2">
                      <div className='flex justify-between mb-2'>
                        <span className=" text-sm text-gray-700 ">Date Range</span>
                        <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                          setFilterData({
                            ...filterData,
                            startDate: null,
                            endDate: null,
                          })
                        }}> Reset </span>
                      </div>
                      <div className="w-full flex gap-2 justify-between">
                        <div className="flex customDatePickerWidth items-center">
                          <DatePicker
                            selected={null}
                            onChange={(dates) => {
                              const [start, end] = dates
                              // setStartDate(start)
                              // setEndDate(end)
                              setFilterData({ ...filterData, startDate: start, endDate: end })
                            }}
                            showIcon
                            isClearable
                            startDate={filterData.startDate}
                            endDate={filterData.endDate}
                            dateFormat="MMM dd, yyyy"
                            selectsRange
                            className="text-center block py-2 px-2 border w-full appearance-none rounded-md placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            placeholderText="Select Date Range"
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                    </div>
                    <hr className='' />
                    <div className="w-full p-2">
                      <div className='flex justify-between mb-2'>
                        <span className=" text-sm text-gray-700 ">Location</span>
                        <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                          setFilterData({
                            ...filterData,
                            location: '',
                          })
                        }}> Reset </span>
                      </div>
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="location"
                        id="location"
                        isSearchable
                        loadingMessage="Getting Location..."
                        placeholder="Location"
                        isClearable={true}
                        isMulti
                        options={location?.map((l) => ({
                          ...l,
                          label: l.name,
                          value: l.id,
                        }))}
                        value={filterData.location}
                        onChange={(option) => {
                          setFilterData({
                            ...filterData,
                            location: option,
                          })
                          // setLocationFilter(option)
                        }}
                      />
                    </div>
                    <hr className='' />
                    <div className="w-full p-2">
                      <div className='flex justify-between mb-2'>
                        <span className=" text-sm text-gray-700 ">Communication</span>
                        <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                          setFilterData({
                            ...filterData,
                            communication: '',
                          })
                        }}> Reset </span>
                      </div>
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="preferred-communication"
                        id="preferred-communication"
                        isSearchable
                        loadingMessage="Getting Options..."
                        placeholder="Communication"
                        isClearable={true}
                        options={contactTypeList?.map((l) => ({
                          ...l,
                          label: l.name,
                          value: l.id,
                        }))}
                        onChange={(option) => {
                          // setContactTypeFilter(option)
                          setFilterData({
                            ...filterData,
                            communication: option,
                          })
                        }}
                        value={filterData.communication}
                      />
                    </div>
                    <hr className='' />
                    <div className="w-full p-2">
                      <div className='flex justify-between mb-2'>
                        <span className=" text-sm text-gray-700 ">Source</span>
                        <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                          setFilterData({
                            ...filterData,
                            source: '',
                          })
                        }}> Reset </span>
                      </div>
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="source"
                        id="source"
                        isSearchable
                        loadingMessage="Getting Source..."
                        placeholder="Source"
                        isClearable={true}
                        options={source?.map((l) => ({
                          ...l,
                          label: l,
                          value: l,
                        }))}
                        onChange={(option) => {
                          setFilterData({
                            ...filterData,
                            source: option,
                          })
                        }}
                        value={filterData.source}
                      />
                    </div>
                    <hr className='' />
                    <div className='w-full p-2'>
                      <button className="block w-full text-left hover:bg-gray-100 flex gap-2 border rounded-md py-2 px-2 shadow-sm" onClick={() => setOpenSlider(true)}>
                        <PencilIcon className='w-4 text-indigo-800 hover:text-indigo-700 ' />
                        <span className='text-gray-500 text-sm'>Customize</span>
                      </button>
                    </div>
                    <hr className='' />
                    <div className="flex justify-between gap-2 p-2">
                      <div className="">
                        <Button
                          type="button"
                          className={'bg-red-500 hover:bg-red-700'}
                          onClick={() => {
                            setFilterData({
                              startDate: null,
                              endDate: null,
                              location: '',
                              communication: '',
                              source: '',
                              loading: false
                            })
                            handleClearFilters()
                          }}
                        >
                          Reset All
                        </Button>
                      </div>
                      <div className="">
                        <Button
                          type="button"
                          onClick={() => { HandelFilters() }}
                        >
                          Apply Now
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {renderTable()}
            {/* <Table
          progPage={page}
          totalCount={count}
          columns={lead_column({onEditModal,onDeleteOpen})}
          data={TabData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        /> */}
            <nav
              className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
              aria-label="Pagination"
            >
              <div>
                <p className="text-sm text-gray-700">
                  Showing{' '}
                  <select
                    onChange={(e) => {
                      setLimit(e.target.value)
                      setPage(0)
                    }}
                    value={limit}
                  >
                    <option value={count}>{count}</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </select>{' '}
                  of <span className="font-medium">{count}</span> results
                </p>
              </div>
              <div className="flex items-center md:justify-end">
                <span
                  onClick={goPrev}
                  className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                >
                  Previous
                </span>
                <span className="text-sm">
                  Page{' '}
                  <input
                    className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
                    type="number"
                    value={page + 1}
                    onChange={(e) => setPage(e.target.value - 1)}
                    max={totalPages}
                    min="1"
                  />
                  <span className='ml-2'></span>/ {Math.ceil(totalPages)}
                </span>
                <span
                  onClick={goNext}
                  className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                >
                  Next
                </span>
              </div>
            </nav>
          </>}
      </div>
      <ColumnSlideOver
        open={openSlider}
        setOpen={setOpenSlider}
        title={title}
        description={description}
        data={columnFilter}
        handleClick={handleClick}
      />
      <ToastContainer
        closeOnClick
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false} 
        autoClose={1000}
        hideProgressBar={false}/>
    </Sidebar>
  )
}

export default LeadsPage
