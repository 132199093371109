import { ArrowUpCircleIcon } from '@heroicons/react/24/outline';
import React, { useState, useEffect } from 'react'
import PinInput from 'react-pin-input'

const steps = [
  { id: '0', name: 'Summary', href: '#section0' },
  { id: '1', name: ' Download Google Authenticator', href: '#section1' },
  { id: '2', name: 'Scan QR Code', href: '#section2', },
  { id: '3', name: 'Login Attempt', href: '#section3', },
  { id: '4', name: 'Enter MFA Code', href: '#section4', },
];

const ScrollablePage = () => {
  const generateRandomNumber = () => {
    return Math.floor(Math.random() * 900000) + 100000;
  };
  const [digits] = useState(generateRandomNumber)
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="fixed top-0 left-0 w-full bg-white">
        <nav aria-label="Progress">
          <ol
            role="list"
            className="overflow-hidden rounded-md flex rounded-none border-l border-r border-gray-200"
          >
            {steps.map((step) => (
              <li key={step.id} className="relative flex-1">
                <div className={'border border-gray-200 lg:border-0'}>
                  <a href={step.href} aria-current="step">
                    <span
                      className="absolute left-0 top-0 h-full w-1 lg:bottom-0 lg:top-auto md:h-fit w-full"
                      aria-hidden="true"
                    />
                    <span className={'flex flex-col lg:flex-row items-center justify-center px-2 md:px-6 py-2 text-sm font-medium h-20 md:h-fit w-full md:w-auto lg:w-auto lg:flex-shrink-0 lg:gap-2'}>
                      <span className="text-xs sm:text-sm md:text-base md:whitespace-nowrap">
                        {step.id}
                      </span>
                      <span className="text-xs sm:text-sm font-medium md:text-base md:whitespace-nowrap inline-flex ml-2 md:ml-4 lg:ml-0">
                        {step.name}
                      </span>
                    </span>

                  </a>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="flex flex-col sm:justify-between justify-center gap-2 lg:gap-2">
        <div
          id="section0"
        >
          <div className="mt-20 md:mt-8 lg:mt-0 lg:gap-5 md:pr-16 lg:mx-16 px-4 flex flex-col lg:flex-row sm:justify-center justify-end items-center md:min-h-screen">
            <div className="w-full lg:w-3/5 order-1 md:order-1">
              <h1 className="mt-2 font-bold mb-4 sm:text-center lg:text-left text-indigo-800 text-3xl lg:text-4xl font-medium">Google Authenticator Setup</h1>

              {/* Summarized Content */}
              <div>
                <a href='#section1' aria-current="step">
                  <h3 className="text-yellow-600 font-Inter text-lg mt-2 font-bold">
                    Step 1. Download Google Authenticator:
                  </h3></a>
                <ul className="mt-2 text-gray-700 text-base font-normal leading-6 font-Inter text-justify list-disc px-4">
                  <li>Open your app store (Play Store for Android or App Store for iOS).</li>
                  <li>Search and download "Google Authenticator."</li>
                  <li>Install and open the app on your mobile device.</li>
                </ul>

                <a href='#section2' aria-current="step">
                  <h3 className="text-yellow-600 font-Inter text-lg mt-2 font-bold">
                    Step 2. Scan QR Code:
                  </h3>
                </a>
                <ul className="mt-2 text-gray-700 text-base font-normal leading-6 font-Inter text-justify list-disc px-4">

                  <li>Open the email with authentication setup information.</li>
                  <li>Look for a QR code or setup link.</li>
                  <li>In the Google Authenticator app, tap the "+" icon and choose "Scan barcode."</li>
                  <li>Use your device's camera to scan the QR code.</li>
                </ul>

                <a href='#section3' aria-current="step">
                  <h3 className="text-yellow-600 font-Inter text-lg mt-2 font-bold">
                    Step 3. Login Attempt:
                  </h3>
                </a>
                <ul className="mt-2 text-gray-700 text-base font-normal leading-6 font-Inter text-justify list-disc px-4">

                  <li>Open the system's login page in your browser.</li>
                  <li>Enter the provided username and password.</li>
                </ul>

                <a href='#section4' aria-current="step">
                  <p className="text-yellow-600 font-Inter text-lg mt-2 font-bold">Step 4. Enter MFA Code:</p>
                </a>
                <ul className="mt-2 text-gray-700 text-base font-normal leading-6 font-Inter text-justify list-disc px-4">

                  <li>After entering credentials, use the Google Authenticator app to get a six-digit code.</li>
                  <li>Enter this code on the system's login page.</li>
                  <li>Click or tap "Proceed" to complete the login.</li>
                </ul>
              </div>

              {/* Download App Section */}
              <p className="text-gray-900 pt-4 font-semibold font-medium leading-6 font-Inter">Download the app</p>
              <p className="text-gray-700 mt-2 text-base font-normal leading-5 font-Inter">Click on the link below with your Android or iOS devices</p>
              <div className="flex gap-3 pt-4 justify-center">
                <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">
                  <img src='https://api.nrich.onepercentstartups.com/uploads/assest/appstore.svg' alt="iphone" className="" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share" target="_blank">
                  <img src='https://api.nrich.onepercentstartups.com/uploads/assest/playstore.svg' alt="android" className="" />
                </a>
              </div>
            </div>

            {/* Image Section */}
            <div className="w-full sm:w-2/3 lg:w-1/3 bg-white mt-4 md:mt-0 order-1 md:order-2 shadow-md">
              <img
                src='https://api.nrich.onepercentstartups.com/uploads/assest/googleAuth.jpg'
                alt="Your Image"
                className="w-full h-auto p-4 bg-gray-50 shadow-md"
              />
            </div>
          </div>

        </div>

        <div
          id="section1"
        >
          <div className="mt-20 md:mt-8 lg:mt-0 lg:gap-5 md:pr-16 lg:mx-16 px-4 flex flex-col lg:flex-row sm:justify-center justify-end items-center md:min-h-screen">
            <div className="w-full lg:w-3/5 order-1 md:order-1">
              <h3 className="text-yellow-600 font-Inter text-lg font-bold">
                Step 1
              </h3>
              <h1 className="mt-2 font-bold mb-4 sm:text-center lg:text-left text-indigo-800 text-3xl lg:text-4xl font-medium">
                Download Google Aunthenticator
              </h1>
              <div className="text-justify">
                <ul className="mt-2 text-gray-700 text-base font-normal leading-6 font-Inter text-justify list-disc px-4" >
                  <li>
                    Open your mobile device's app store (Google Play Store for Android or App Store for iOS).
                  </li>
                  <li> Search for "Google Authenticator" and download the app.
                  </li>
                  <li>Install and open the app on your mobile device.</li>
                </ul>
              </div>
              <p className="text-gray-900 pt-4 font-semibold font-medium leading-6 font-Inter">
                Download the app
              </p>
              <p className="text-gray-700 mt-2 text-base font-normal leading-5 font-Inter">
                Click on the link below with your android or ios devices
              </p>
              <div className="flex gap-3 pt-4 justify-center ">
                <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">
                  <img src='https://api.nrich.onepercentstartups.com/uploads/assest/appstore.svg' alt="iphone" className="" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share" target="_blank">
                  <img src='https://api.nrich.onepercentstartups.com/uploads/assest/playstore.svg' alt="android" className="" />
                </a>
              </div>
            </div>
            <div className="w-full sm:w-2/3 lg:w-1/3 bg-white mt-4 md:mt-0 order-1 md:order-2 shadow-md">
              <img
                src='https://api.nrich.onepercentstartups.com/uploads/assest/googleAuth.jpg'
                alt="Your Image"
                className="w-full h-auto p-4 bg-gray-50 shadow-md"
              />
            </div>
          </div>
        </div>

        <div
          id="section2"
        >
          <div className="mt-20 md:mt-8 lg:mt-0 lg:gap-5 md:pr-16 lg:mx-16 px-4 flex flex-col lg:flex-row sm:justify-center justify-end items-center md:min-h-screen">
            <div className="w-full lg:w-3/5 order-1 md:order-1 ">
              <h3 className="text-yellow-600 font-Inter text-lg font-bold">
                Step 2
              </h3>
              <h1 className="mt-2 font-bold mb-4 sm:text-center lg:text-left text-indigo-800 text-3xl lg:text-4xl font-medium">
                Scan QR Code From Authenticator
              </h1>
              <ul className="mt-2 text-gray-700 text-base font-normal leading-6 font-Inter text-justify list-disc px-4" >
                <li>
                  Open the email containing the authentication setup information.
                </li>
                <li> Look for a QR code or a link to set up two-factor authentication.
                </li>
                <li>Open the Google Authenticator app and tap on the "+" icon to add a new account.</li>
                <li>Choose the "Scan barcode" option and use your device's camera to scan the QR code in the email.</li>
              </ul>
              <p className="flex items-center gap-4 mt-4 justify-center lg:justify-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                  fill="none"
                >
                  <g clip-path="url(#clip0_873_460)">
                    <path
                      d="M0 22.5L28.89 40.8862C29.22 41.13 29.61 41.25 30 41.25C30.39 41.25 30.78 41.13 31.11 40.8862L60 22.5L31.125 0.374978C30.4575 -0.123772 29.5425 -0.123772 28.875 0.374978L0 22.5Z"
                      fill="#64B5F6"
                    />
                    <path
                      d="M48.75 0H11.25C9.18375 0 7.5 1.68375 7.5 3.75V45C7.5 46.035 8.34 46.875 9.375 46.875H50.625C51.66 46.875 52.5 46.035 52.5 45V3.75C52.5 1.68375 50.82 0 48.75 0Z"
                      fill="#ECEFF1"
                    />
                    <path
                      d="M16.875 11.25H43.125C44.16 11.25 45 10.41 45 9.375C45 8.34 44.16 7.5 43.125 7.5H16.875C15.84 7.5 15 8.34 15 9.375C15 10.41 15.84 11.25 16.875 11.25Z"
                      fill="#90A4AE"
                    />
                    <path
                      d="M43.125 15H16.875C15.84 15 15 15.84 15 16.875C15 17.91 15.84 18.75 16.875 18.75H43.125C44.16 18.75 45 17.91 45 16.875C45 15.84 44.16 15 43.125 15Z"
                      fill="#90A4AE"
                    />
                    <path
                      d="M31.875 22.5H16.875C15.84 22.5 15 23.34 15 24.375C15 25.41 15.84 26.25 16.875 26.25H31.875C32.91 26.25 33.75 25.41 33.75 24.375C33.75 23.34 32.91 22.5 31.875 22.5Z"
                      fill="#90A4AE"
                    />
                    <path
                      d="M31.11 40.8862C30.78 41.13 30.39 41.25 30 41.25C29.61 41.25 29.22 41.13 28.89 40.8862L0 22.5V56.25C0 58.32 1.68 60 3.75 60H56.25C58.32 60 60 58.32 60 56.25V22.5L31.11 40.8862Z"
                      fill="#1E88E5"
                    />
                    <path
                      d="M56.25 60H3.75C1.64625 60 0 58.3538 0 56.25C0 55.6537 0.285 55.0913 0.765 54.7388L28.89 35.9888C29.22 35.745 29.61 35.625 30 35.625C30.39 35.625 30.78 35.745 31.11 35.9888L59.235 54.7388C59.715 55.0913 60 55.6537 60 56.25C60 58.3538 58.3537 60 56.25 60Z"
                      fill="#2196F3"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_873_460">
                      <rect width="60" height="60" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p className="font-medium text-indigo-800 text-base font-Inter">
                  Open Email
                </p>
              </p>
              <p className="mt-2 font-bold mb-4 text-center text-indigo-800 text-3xl font-medium">
                Scan the QR Code
              </p>
              <p className="text-gray-700 text-base font-normal leading-5 font-Inter mt-2">
                Qr code has been given on your email to scan
              </p>
            </div>
            <div className="w-full sm:w-2/3 lg:w-1/3 bg-white mt-4 md:mt-0 order-1 md:order-2 shadow-md">
              <img
                src='https://api.nrich.onepercentstartups.com/uploads/assest/login_step_second.png'
                alt="Your Image"
                className="w-full h-auto p-4 bg-gray-50 shadow-md "
              />
            </div>
          </div>
        </div>


        <div
          id="section3"
        >
          <div className="mt-20 md:mt-8 lg:mt-0 lg:gap-5 md:pr-16 lg:mx-16 px-4 flex flex-col lg:flex-row sm:justify-center justify-end items-center md:min-h-screen">
            <div className="w-full lg:w-3/5 order-1 md:order-1">
              <h3 className="text-yellow-600 font-Inter text-lg font-bold">
                Step 3
              </h3>
              <h1 className="mt-2 font-bold mb-4 sm:text-center lg:text-left text-indigo-800 lg:text-4xl text-3xl font-medium">
                Login Your Account
              </h1>
              <div className="lg:gap-5 mt-2 ">
                <ul className="mt-2 text-gray-700 text-base font-normal leading-6 font-Inter text-justify list-disc px-4" >
                  <li>
                    Open the system's login page in your web browser.
                  </li>
                  <li> Enter your username and password that you received in the email.
                  </li>
                </ul>
              </div>
              <div className="flex flex-row lg:justify-start justify-center mt-6 ">
                <button className="flex flex-row px-3 py-3 rounded-lg items-center gap-2 bg-indigo-800 hover:bg-indigo-900" >
                  <a href="/" target="_blank" className='flex'>
                    <p className="font-Inter font-medium text-base leading-6 text-white">
                      Login
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      className='mt-1'
                    >
                      <path
                        d="M12.5 2.5H15.8333C16.2754 2.5 16.6993 2.67559 17.0118 2.98816C17.3244 3.30072 17.5 3.72464 17.5 4.16667V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H12.5M8.33333 14.1667L12.5 10M12.5 10L8.33333 5.83333M12.5 10H2.5"
                        stroke="white"
                        stroke-width="1.67"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </button>
              </div>
            </div>
            <div className="w-full sm:w-2/3 lg:w-1/3 bg-white mt-4 md:mt-0 order-1 md:order-2 shadow-md">
              <img
                src="https://api.nrich.onepercentstartups.com/uploads/assest/step-three.png"
                alt="Your Image"
                className="w-full h-auto p-4 bg-gray-50 shadow-md"
              />
            </div>
          </div>
        </div>
        <div
          id="section4"
          className='mb-10'
        >
          <div className="mt-20 md:mt-8 lg:mt-0 lg:gap-5 md:pr-16 lg:mx-16 px-4 flex flex-col lg:flex-row sm:justify-center justify-end items-center md:min-h-screen">
            <div className="w-full lg:w-3/5 order-1 md:order-1">
              <h3 className="text-yellow-600 font-Inter text-lg font-bold">
                Step 4
              </h3>
              <h1 className="mt-2 font-bold mb-4 sm:text-center lg:text-left text-indigo-800 text-3xl lg:text-4xl font-medium">
                Authentication Code
              </h1>
              <div className="md:gap-5 mt-2">
                <ul className="mt-2 text-gray-700 text-base font-normal leading-6 font-Inter text-justify list-disc px-4">
                  <li>Once you've entered your credentials, you will be prompted to enter a verification code.</li>
                  <li>Open the Google Authenticator app on your mobile device.</li>
                  <li>Find the entry for the system you are logging into, and note the six-digit code that is currently displayed.</li>
                  <li>Enter this code in the appropriate field on the system's login page.</li>
                  <li>Click or tap on the "Proceed" button to complete the login process.</li>
                </ul>
                <div className="my-4 flex items-center justify-center">
                  <PinInput
                    length={6}
                    initialValue={digits}
                    disabled
                    inputStyle={{
                      borderColor: '#6899F4',
                      borderWidth: '1px',
                      fontWeight: 'bold',
                      borderRadius: '12px',
                      backgroundColor: 'transparent',
                      color: '#25408F',
                      fontFamily: 'Inter',
                      cursor: 'not-allowed',
                    }}
                    className="w-full md:w-1/2 lg:w-1/3 mx-auto"
                    inputFocusStyle={{ outline: 'none', boxShadow: 'none' }}
                  />
                </div>
                <p className="flex items-center mt-4 lg:px-0 sm:px-4">
                  Secure your account with Google Auth Token, a dynamic authentication key that refreshes every minute, ensuring continuous protection against unauthorized access.
                </p>
              </div>
              <div className="flex flex-row lg:justify-start justify-center mt-6 ">
                <button className="flex flex-row px-3 py-3 rounded-lg items-center gap-2 bg-indigo-800 hover:bg-indigo-900" >
                  <a href="/" target="_blank" className='flex'>
                    <p className="font-Inter font-medium text-base leading-6 text-white">
                      Login
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      className='mt-1'
                    >
                      <path
                        d="M12.5 2.5H15.8333C16.2754 2.5 16.6993 2.67559 17.0118 2.98816C17.3244 3.30072 17.5 3.72464 17.5 4.16667V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H12.5M8.33333 14.1667L12.5 10M12.5 10L8.33333 5.83333M12.5 10H2.5"
                        stroke="white"
                        stroke-width="1.67"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </button>
              </div>
            </div>
            <div className="w-full sm:w-2/3 lg:w-1/3 bg-white mt-4 md:mt-0 order-1 md:order-2 shadow-md">
              <img
                src='https://api.nrich.onepercentstartups.com/uploads/assest/step-four.png'
                alt="Your Image"
                className="w-full h-auto p-4 bg-gray-50 shadow-md"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="fixed right-4 bottom-4">
            {isVisible && (
              <button
                className="relative bg-white p-2 rounded-full text-white transition duration-300 hover:bg-gray-300"
                onClick={scrollToTop}
              >
                <ArrowUpCircleIcon className='w-7 text-indigo-800' />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScrollablePage