import { Sidebar } from '../components/navigation/sidebar'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import app_api from '../config/api'

const Reports = () => {
  const navigate = useNavigate()
  const [countFamily, setCountFamily] = useState()
  const [invoiceCount, setInvoiceCount] = useState()
  const [paymentCount, setPaymentCount] = useState()
  const [studentCount, setStudentCount] = useState()
  const [error, setError] = useState()

  const getCounts = () => {
    let email = JSON.parse(localStorage.getItem('moldKey')).email
    let url1 = `family-master/all?page=${0}&size=${15}`
    let url2 = `crm-invoice/report/all?page=${0}&size=${15}`
    let url3 = `payment-master/report/all?page=${0}&size=${15}`
    let url4 = `family-invoice-item/student/report/all?page=${0}&size=${15}`
    // app_api.get(url1)
    //   .then((res) => {
    //     setCountFamily(res.data.count)
    //   })
    //   .catch((err) => {
    //     setError(err?.response?.data?.message || 'error getting data')
    //   })

    app_api.get(url2)
      .then((res) => {
        setInvoiceCount(res.data.count)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })

    app_api.get(url3)
      .then((res) => {
        setPaymentCount(res.data.count)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
      app_api.get(url4)
      .then((res) => {
        setStudentCount(res.data.count)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }
  const steps = [
    // { name: 'Family', href: '/reports/family', total: countFamily, type: 'Family Master' },
    { name: 'Student Per Service', href: '/reports/student', total: studentCount, type: 'Student Master'},
    { name: 'Invoices Per Service', href: '/reports/invoice', total: invoiceCount, type: 'Invoice Master' },
    { name: 'Payments Per Service', href: '/reports/payment', total: paymentCount, type: 'Payment Master' },
  ];

  useEffect(() => { getCounts() }, [])

  return (
    <Sidebar>
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">Reports</h1>
            <p className="my-2 text-sm text-gray-700">
              A list of all the reports.
            </p>
          </div>
        </div>
        <div className="mt-4 flex flex-col">
          <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-4 lg:-mx-6">
            <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8 tableUI">
              <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg tableShadow">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50 sticky top-0 z-10">
                    <tr>
                      <th scope="col" className="px-4 py-3 text-left text-sm font-semibold text-gray-900">
                        List of
                      </th>
                      <th scope="col" className="px-4 py-3 text-left text-sm font-semibold text-gray-900">
                        Type
                      </th>
                      <th scope="col" className="px-4 py-3 text-left text-sm font-semibold text-gray-900">
                        Total
                      </th>
                      <th scope="col" className="px-4 py-3 text-left text-sm font-semibold text-gray-900">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {steps.map((s, index) => (
                      <tr key={index}>
                        <td
                          className="px-4 py-3 text-sm "
                        >
                          <span
                            className='cursor-pointer hover:text-indigo-600'
                            onClick={() => {
                              navigate(`${s.href}`);
                            }}>
                            {s.name}
                          </span>
                        </td>
                        <td className="px-4 py-3 text-sm">{s.type}</td>
                        <td className="px-4 py-3 text-sm">{s.total}</td>
                        <td className="px-4 py-3 text-sm">
                          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
                            <button
                              onClick={() => {
                                navigate(`${s.href}`);
                              }}
                              size="default"
                              variant="outlined"
                            >
                              View
                              <span className="sr-only">, </span>
                            </button>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        autoClose={2000}
        hideProgressBar={false} />
    </Sidebar>
  )
}

export default Reports
