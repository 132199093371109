import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { classNames } from '../../helpers/classname'

export default function ConfirmationTextDialog(props) {
  const {
    title = 'Are you sure?',
    description = 'Do you really want to delete this data?',
    confirmationButtonText = 'Inactive',
    subheader = 'Please provide a inactivation reason:',
    open,
    setOpen,
    onDelete,
    reason = '',
    data
  } = props

  const [activeValue, setActiveValue] = useState(true)
  const [deactiveValue, setDeactiveValue] = useState('')
  const cancelButtonRef = useRef(null)

  useEffect(() => {
    setActiveValue(props.activeValue)
    setDeactiveValue(props.deactiveValue)
    setDeactiveValue(reason)
  }, [props])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {data.active != 'Active' ?
                <Dialog.Panel className="relative px-4 pt-4 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex flex flex-state deleteUI">
                    <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="w-6 h-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center flex-grow sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        {title}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">{description}</p>
                      </div>
                    </div>
                  </div>
                  <div className='mt-4'>
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        {subheader}
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <textarea name="postContent" rows={4} cols={60}
                      className='border border-gray-300 rounded-md px-1 py-2'
                      value={deactiveValue}
                      onChange={(event) => {
                        setDeactiveValue(event.target.value)
                      }}
                    />
                  </div>
                  <div className="mt-4 sm:mt-4 sm:flex deleteUI_button">
                    <button
                      type="button"
                      // className=" bg-red-700 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 mt-3 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => {
                        setOpen(false)
                        onDelete && onDelete(deactiveValue)
                      }}
                      disabled={deactiveValue != null && deactiveValue != '' ? false : true}
                      className={classNames(
                        deactiveValue != null && deactiveValue != '' ? 'focus:ring-red-500 focus:ring-offset-2 hover:bg-red-700 bg-red-700'
                          : 'bg-gray-600',
                        'cursor-pointer inline-flex justify-center px-4 py-2 text-base font-medium text-white mt-3 border-transparent rounded-md shadow-sm m:ml-3 sm:w-auto sm:text-sm'
                      )}
                    >
                      {confirmationButtonText}
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => {
                        setOpen(false)
                      }}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
                : data ?
                  <Dialog.Panel className="relative px-4 pt-4 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="sm:flex flex flex-state deleteUI">
                      <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="w-6 h-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center flex-grow sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Student <strong>{data.studentsFirstName} {data.studentsLastName}</strong> is Active
                        </Dialog.Title>
                        <div className="mt-2 text-left">
                          <p className="text-sm text-gray-500">Before voiding the invoice, please ensure that the student's is inactive. Thank you.</p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 sm:mt-4 sm:flex deleteUI_button">
                      {data.active != 'Active' && (<button
                        type="button"
                        // className=" bg-red-700 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 mt-3 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => {
                          setOpen(false)
                          onDelete && onDelete(deactiveValue)
                        }}
                        disabled={deactiveValue != null && deactiveValue != '' ? false : true}
                        className={classNames(
                          deactiveValue != null && deactiveValue != '' ? 'focus:ring-red-500 focus:ring-offset-2 hover:bg-red-700 bg-red-700'
                            : 'bg-gray-600',
                          'cursor-pointer inline-flex justify-center px-4 py-2 text-base font-medium text-white mt-3 border-transparent rounded-md shadow-sm m:ml-3 sm:w-auto sm:text-sm'
                        )}
                      >
                        {confirmationButtonText}
                      </button>)}
                      <button
                        type="button"
                        className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => {
                          setOpen(false)
                        }}
                        ref={cancelButtonRef}
                      >
                        Ok
                      </button>
                    </div>
                  </Dialog.Panel> :
                  <Dialog.Panel className="relative px-4 pt-4 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="sm:flex flex flex-state deleteUI">
                      <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="w-6 h-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center flex-grow sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          {title}
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">{description}</p>
                        </div>
                      </div>
                    </div>
                    <div className='mt-4'>
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          {subheader}
                        </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                      <textarea name="postContent" rows={4} cols={60}
                        className='border border-gray-300 rounded-md px-1 py-2'
                        value={deactiveValue}
                        onChange={(event) => {
                          setDeactiveValue(event.target.value)
                        }}
                      />
                    </div>
                    <div className="mt-4 sm:mt-4 sm:flex deleteUI_button">
                      <button
                        type="button"
                        // className=" bg-red-700 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 mt-3 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => {
                          setOpen(false)
                          onDelete && onDelete(deactiveValue)
                        }}
                        disabled={deactiveValue != null && deactiveValue != '' ? false : true}
                        className={classNames(
                          deactiveValue != null && deactiveValue != '' ? 'focus:ring-red-500 focus:ring-offset-2 hover:bg-red-700 bg-red-700'
                            : 'bg-gray-600',
                          'cursor-pointer inline-flex justify-center px-4 py-2 text-base font-medium text-white mt-3 border-transparent rounded-md shadow-sm m:ml-3 sm:w-auto sm:text-sm'
                        )}
                      >
                        {confirmationButtonText}
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => {
                          setOpen(false)
                        }}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
              }
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
