import { useState, useEffect, useMemo } from 'react'
import app_api from '../config/api'
import { Sidebar } from '../components/navigation/sidebar'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { TeacherSchedule_column } from '../components/tables/tableheader'
import Table from '../components/tables/table'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select from 'react-select'
import { InformationCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { classNames } from '../helpers/classname'
import Input from '../components/input'
import { Switch } from '@headlessui/react'
import { Formik } from 'formik'
import { locationSchema, teacherSchema } from '../schema'
import { Button } from '../components/tables/paginationButtons'
import CurrencyInput from 'react-currency-input-field'
import BigSlideover from '../components/big-slideover'
import { CiFilter } from 'react-icons/ci'


const initialScheduleData = {
  type: 'add',
  edit_id: 0,
  index: '',
  state: false,
  data: {},
}

const initialTeacherModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    firstName: '',
    lastName: '',
    email: '',
    mobileNo: '',
    wwcNo: '',
    vitNumber: '',
    ratePerHour: null,
    qualification: '',
    subjectId: '',
    accountNo: '',
    BSB: '',
    locationId: '',
    abn: '',
    tfn: '',
    membershipNumber: '',
    super: '',
    abnorusi: '',
    usi: '',
    gstValue: '',
    entityName: '',
    abnStatus: ''
  },
}

const initialLocationModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    name: '',
    address: '',
    pointOfContact: '',
    is_active: true,
    accountName: '',
    BSB: '',
    accountNumber: '',
    abn: '',
    gst: '',
    phoneNumber: '',
    emailAddress: '',
    xeroAPIKey: '',
    state: '',
    city: '',
    postCode: '',
    state: '',
    entityName: '',
    entityTypeName: '',
    status: '',
    entityTypeCode: '',
    googleurl: '',
    facebook: '',
    pointOfContact: '',
    brandingThemeID: '',
    sendGridAccount: null
  },
}

const TeacherSchedule = () => {
  const [loading, setLoading] = useState(true)
  const [modal, setModal] = useState(initialScheduleData)
  const [error, setError] = useState(null)
  const [category, setCategory] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [locationFilter, setLocationFilter] = useState('')
  const [location, setLocation] = useState([])
  const [TabData, setTabData] = useState([])
  const [family, setFamily] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  // Pagination Start
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  //pagination End
  const [events, setEvents] = useState([])
  const [teacherModal, setTeacherModal] = useState(initialTeacherModalState)
  const [enabled, setEnabled] = useState(false)
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredField, setHoveredField] = useState(null);
  const [abnStatus, setAbnStatus] = useState()
  const [entityName, setEntityName] = useState()
  const [gstValue, setGstValue] = useState()
  const [abnError, setAbnError] = useState('')
  const [subjectList, setSubjectList] = useState([])

  const [locationModal, setLocationModal] = useState(initialLocationModalState)
  const [LocationManager, setLocationManager] = useState([])
  const [postCodeError, setPostCodeError] = useState('')
  const [defaultState, setDefaultState] = useState([])
  const [studentState, setStudentState] = useState([])
  const [studentCity, setStudentCity] = useState([])

  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false)
  const [filterData, setFilterData] = useState({
    startDate: null,
    endDate: null,
    location: '',
    loading: false,
  })

  const handleFilters = () => {
    setFilterData({
      ...filterData,
      loading: true,
    })
    setIsFilterSidebarOpen(!isFilterSidebarOpen)
    setStartDate(filterData.startDate)
    setEndDate(filterData.endDate)
    setLocationFilter(filterData.location)
  }

  const handleClearFilters = () => {
    setStartDate()
    setEndDate()
    setLocationFilter([])
    setIsFilterSidebarOpen(!isFilterSidebarOpen)
  }

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  const getLocation = () => {
    let email = JSON.parse(localStorage.getItem('moldKey')).email
    let url = `location/all?&email=${email}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        setLoading(false)
        setError(null)
        setLocation(res.data)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getSubjectData()
    getLocation()
    getLocationManagerData()
    getStateDataByBackend()
  }, [])
  const getClassData = () => {
    console.log('getClassData')
    let email = JSON.parse(localStorage.getItem('moldKey')).email
    let url = `teacher-master/teacherData/${email}`
    app_api
      .get(url)
      .then((res) => {
        setLoading(false)
        setError(null)
        setCategory(res.data)
        const convertedEvents = res.data.session.map((s) => ({
          start: new Date(s.start_time),
          end: new Date(s.end_time),
          title: s.class.className,
          subject: s.class.subject.subject
        }))
        const event = [].concat(...convertedEvents)
        setEvents(event)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })

  }

  const onEditOpen = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: TabData[index],
    }))
  }

  const getScheduledEvents = () => {
    let locationValues =
      locationFilter && locationFilter.length > 0
        ? locationFilter.map((value) => value.id)
        : location.map((value) => value.id)
    let url = `class-session?page=${page}&size=${limit}&searchFilter=${searchFilter}`
    if (locationValues.length > 0) {
      url += `&location=${locationValues.join(',')}`
    }
    if (startDate != null && endDate != null) url = url + `&start=${startDate}&end=${endDate}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        setLoading(false)
        setError(null)
        setTabData(res.data)
        setFilterData({
          ...filterData,
          loading: false,
        })
        if (res.count <= limit) setPage(0)
        if (res.count < 15) { setLimit(res.count) } else { setLimit(15) }
        if (isNaN(res.count / limit)) { setTotalPages(1) } else { setTotalPages(res.count / limit) }
        setCount(res.count)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('moldKey')).role != 'teacher') {
      getScheduledEvents()
    } else {
      getClassData()
    }
  }, [
    searchFilter,
    locationFilter,
    limit,
    page,
    startDate,
    endDate,
  ])

  const localizer = momentLocalizer(moment)

  const getSubjectData = () => {
    app_api
      .get('subject-master/all')
      .then((res) => res.data)
      .then((res) => {
        let data = res.data
        setLoading(false)
        setError(null)
        setSubjectList(data)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const onTeacherOpen = (id) => {
    app_api
      .get(`/teacher-master/${id}`)
      .then((res) => {
        let data = res.data
        setError(false)
        setEnabled(data.active)
        getAbnValue(data.abn)
        setTeacherModal((prev) => ({
          ...prev,
          type: 'edit',
          edit_id: id,
          index: null,
          state: true,
          data: data,
        }))
      })
  }

  const handleMouseEnter = (field) => {
    setIsHovered(true);
    setHoveredField(field);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setHoveredField(null);
  };

  const getAbnValue = (abnValue) => {
    let guid = "220fe5cd-f965-4f2a-93ce-61fa1626d8cb";
    const url = `https://abr.business.gov.au/json/AbnDetails.aspx?abn=${abnValue}&callback=callback&guid=${guid}`;
    fetch(url)
      .then(response => response.text())
      .then(data => {
        const jsonData = extractJsonData(data);
        if (jsonData.Abn) {
          setGstValue(jsonData.Gst);
          setAbnStatus(jsonData.AbnStatus)
          setEntityName(jsonData.EntityName)
          setAbnError(null);
        }
        else {
          setAbnError('Business not found');
        }

      })
      .catch(error => {
        console.error('An error occurred:', error);
      });

  }

  function extractJsonData(responseText) {
    const startIndex = responseText.indexOf('{');
    const endIndex = responseText.lastIndexOf('}');
    const jsonString = responseText.substring(startIndex, endIndex + 1);
    const jsonData = JSON.parse(jsonString);
    return jsonData;
  }

  const cleanModalData = () => {
    setLocationModal(initialLocationModalState)
  }

  useEffect(() => {
    let payload = {
      countryCode: defaultState[0]?.countryCode.toString(),
      isoCode: defaultState[0]?.isoCode.toString(),
    }
    app_api
      .post('/state-city/city', payload)
      .then((res) => {
        let data = res.data
        if (payload.isoCode === 'VIC') {
          data.push({
            countryCode: 'AU',
            latitude: '-37.66955000',
            longitude: '144.88675000',
            name: 'Lyndhurst',
            stateCode: 'VIC',
          })
        }
        setStudentCity(data)
        
        setError(null)
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
  }, [defaultState])

  const getStateDataByBackend = () => {
    app_api
      .post('/state-city/state')
      .then((res) => {
        let data = res.data
        let defaultState = data.filter((l) => l.name == 'Victoria')
        setDefaultState(defaultState)
        setStudentState(data)
        
        setError(null)
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getCities = (countryCode, isoCode) => {
    let payload = {
      countryCode: countryCode?.toString(),
      isoCode: isoCode?.toString(),
    }
    app_api
      .post('/state-city/city', payload)
      .then((res) => {
        let data = res.data
        if (isoCode === 'VIC') {
          data.push({
            countryCode: 'AU',
            latitude: '-37.66955000',
            longitude: '144.88675000',
            name: 'Lyndhurst',
            stateCode: 'VIC',
          })
        }
        setStudentCity(data)
        
        setError(null)
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const checkPostCode = (postCode, state) => {
    if (state != undefined || postCode != undefined) {
      let booleanValue
      if (state == 'Australian Capital Territory') {
        if (postCode.startsWith('02'))
          booleanValue =
            parseInt(postCode) >= parseInt('0200') &&
            parseInt(postCode) <= parseInt('0299')
        else if (parseInt(postCode) >= 2600 && parseInt(postCode) <= 2618)
          booleanValue = true
        else if (parseInt(postCode) >= 2900 && parseInt(postCode) <= 2920)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'New South Wales') {
        if (parseInt(postCode) >= 1000 && parseInt(postCode) <= 2599)
          booleanValue = true
        else if (parseInt(postCode) >= 2619 && parseInt(postCode) <= 2899)
          booleanValue = true
        else if (parseInt(postCode) >= 2921 && parseInt(postCode) <= 2999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'Northern Territory') {
        if (postCode.startsWith('08'))
          booleanValue =
            parseInt(postCode) >= parseInt('0800') &&
            parseInt(postCode) <= parseInt('0899')
        if (postCode.startsWith('09'))
          booleanValue =
            parseInt(postCode) >= parseInt('0900') &&
            parseInt(postCode) <= parseInt('0999')
        else booleanValue = false
      }
      if (state == 'Queensland') {
        if (parseInt(postCode) >= 4000 && parseInt(postCode) <= 4999)
          booleanValue = true
        else if (parseInt(postCode) >= 9000 && parseInt(postCode) <= 9999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'South Australia') {
        if (parseInt(postCode) >= 5000 && parseInt(postCode) <= 5999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'Tasmania') {
        if (parseInt(postCode) >= 7000 && parseInt(postCode) <= 7999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'Victoria') {
        if (parseInt(postCode) >= 3000 && parseInt(postCode) <= 3999)
          booleanValue = true
        else if (parseInt(postCode) >= 8000 && parseInt(postCode) <= 8999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'Western Australia') {
        if (parseInt(postCode) >= 6000 && parseInt(postCode) <= 6797)
          booleanValue = true
        else if (parseInt(postCode) >= 6800 && parseInt(postCode) <= 6999)
          booleanValue = true
        else booleanValue = false
      }
      if (postCode == '') {
        setPostCodeError(null)
        booleanValue = true
      }

      if (booleanValue) {
        setPostCodeError(null)
        return true
      } else {
        setPostCodeError('Invalid postcode')
      }
    }
    else{
      return true
    }
  }

  const getLocationManagerData = () =>{
    app_api
      .get('users/manager-locations')
      .then((res) =>
      {
        let data = res.data.filter(l => l.is_active == true);
        setLoading(false)
        setError(null)
        setLocationManager(data)
      })
      .catch((err) =>
      {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const onEditLocationOpen = (id, index) =>{
    const data = location.filter((e)=>e.id == id)
    setLocationModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: data[0],
    }))
    if(data[0].state){
      let countryCodeName = studentState.find(l => l.name == data[0].state)?.countryCode
      let isoCodeName = studentState.find(l => l.name == data[0].state)?.isoCode
      getCities(countryCodeName, isoCodeName)
    }
  }

  const renderTeacherModal = () => {
    const { type, state, edit_id, data } = teacherModal
    data.subjectId = data.subject?.map((e) => e.subjectId)
    return (
      <Formik
        initialValues={data}
        validationSchema={teacherSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (type === 'add') {
            app_api
              .post('/teacher-master', {
                ...values, ratePerHour: parseInt(values.ratePerHour), active: enabled, gst: gstValue, abnStatus: abnStatus,
                entityName: entityName,
              })
              .then((res) => {
                toast.success("Successfully added teacher")
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {
                if (err.response.status == 424) {
                  toast.error('Duplicate Entry')
                } else {
                  toast.error('Something Went Wrong')
                }
                setSubmitting(false)
              })
          } else {
            app_api
              .patch(`/teacher-master/${edit_id}`, {
                ...values, ratePerHour: parseInt(values.ratePerHour), active: enabled, gst: gstValue, abnStatus: abnStatus,
                entityName: entityName
              })
              .then((res) => {
                toast.success("Successfully updated teacher")
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {
                toast.success("Failed to update teacher")
              })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          setFieldTouched,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <BigSlideover
            title={'Teacher'}
            open={state}
            setOpen={() => setTeacherModal((prev) => ({ ...prev, state: false }))}
            data={''}
            pageType="Teacher"
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className='flex bg-yellow-300'>

              </div>
              <div className="mt-4 text-left">
                <span className='font-bold text-xl'>Personal Details</span>
                <hr className='text-gray-400'></hr>
                <div className="mt-4">
                  <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          First Name
                        </label>
                        <span className="text-red-500 mx-1 ">*</span>
                      </div>
                      <Input
                        name="firstName"
                        value={values.firstName}
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onFocus={() => setFieldTouched('firstName', false)}
                        placeholder="Enter First Name..."
                      />
                      {touched.firstName && (
                        <p className="text-red-700 error_msg">
                          {errors.firstName}
                        </p>
                      )}
                    </div>
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Last Name
                        </label>
                        <span className="text-red-500 mx-1">*</span>
                      </div>
                      <Input
                        name="lastName"
                        value={values.lastName}
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onFocus={() => setFieldTouched('lastName', false)}
                        placeholder="Enter Last Name..."
                      />
                      {touched.lastName && (
                        <p className="text-red-700 error_msg">
                          {errors.lastName}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mt-4"></div>
                  <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Email
                        </label>
                        <span className="text-red-500 mx-1">*</span>
                      </div>
                      <Input
                        name="email"
                        // label="Parent Email"
                        value={values.email}
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onFocus={() => setFieldTouched('email', false)}
                        placeholder="Enter Parent Email..."
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                      />
                      {touched.email && (
                        <p className="text-red-700 error_msg">
                          {errors.email}
                        </p>
                      )}
                    </div>
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Mobile Number
                        </label>
                        <span className="text-red-500 mx-1">*</span>
                      </div>
                      <Input
                        name="mobileNo"
                        type="tel"
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            mobileNo: e.target.value,
                          })
                        }}
                        onFocus={() => setFieldTouched('mobileNo', false)}
                        value={values.mobileNo}
                        // onChange={(event) =>
                        //   onModalHandleChange(event.target.name, event.target.value)
                        // }
                        placeholder="Enter mobile number..."
                      />
                      {touched.mobileNo && (
                        <p className="text-red-700 error_msg">
                          {errors.mobileNo}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className='mt-10'>
                  <span className='font-bold text-xl'>Professional Details</span>
                  <hr className='text-gray-400'></hr>
                </div>
                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700 ">
                        WWC #
                      </label>
                      <InformationCircleIcon className="w-5 ml-2 " onMouseEnter={() => handleMouseEnter('wwcNo')} onMouseLeave={handleMouseLeave} />
                      {isHovered && hoveredField === 'wwcNo' && (
                        <div className="bg-gray-100">
                          <p className="mx-2 block text-sm">
                            Location Manager should check the WWC #
                          </p>
                        </div>
                      )}
                    </div>
                    <Input
                      name="wwcNo"
                      value={values.wwcNo}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={() => setFieldTouched('wwcNo', false)}
                      placeholder="Enter WWC number..."
                      prefix='WWC'
                    />
                    {touched.wwcNo && (
                      <p className="text-red-700 error_msg">
                        {errors.wwcNo}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        VIT #
                      </label>
                      <InformationCircleIcon className="w-5 ml-2 " onMouseEnter={() => handleMouseEnter('vitNumber')} onMouseLeave={handleMouseLeave} />
                      {isHovered && hoveredField === 'vitNumber' && (
                        <div className="bg-gray-100">
                          <p className="mx-2 block text-sm  ">
                            Location Manager should check the VIT #
                          </p>
                        </div>
                      )}
                    </div>
                    <Input
                      name="vitNumber"
                      value={values.vitNumber}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={() => setFieldTouched('vitNumber', false)}
                      placeholder="Enter VIT number..."
                    />
                    {touched.vitNumber && (
                      <p className="text-red-700 error_msg">
                        {errors.vitNumber}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Rate Per Hour
                      </label>
                      <span className="text-red-500 mx-1">*</span>
                    </div>
                    <CurrencyInput
                      id="ratePerHour"
                      name="ratePerHour"
                      decimalsLimit={2}
                      value={values.ratePerHour}
                      placeholder="Enter rate per hour"
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      prefix="$"
                      autoComplete='off'
                      onFocus={() => setFieldTouched('ratePerHour', false)}
                      onValueChange={(value) => {
                        setValues({
                          ...values,
                          ratePerHour: value,
                        })
                      }} />
                    {touched.ratePerHour && (
                      <p className="text-red-700 error_msg mt-2">
                        {errors.ratePerHour}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Qualification
                      </label>
                      <span className="sr-only">*</span>
                    </div>
                    <Input
                      name="qualification"
                      id="qualification"
                      placeholder="Enter qualification..."
                      value={values.qualification}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      autoComplete='off'
                      onFocus={() => setFieldTouched('qualification', false)}
                      maxMenuHeight={110}
                    />
                    {touched.qualification && (
                      <p className="text-red-700 error_msg mt-2">
                        {errors.qualification}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Subject
                      </label>
                      <span className="text-red-500 mx-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="subjectId"
                      id="subjectId"
                      loadingMessage="Getting subjects..."
                      isSearchable
                      placeholder="Select subject..."
                      isMulti={true}
                      options={subjectList?.map((l) => ({
                        ...l,
                        label: l.subject,
                        value: l.id,
                      }))}
                      value={
                        values.subjectId ?
                          values.subjectId?.map((l) => ({
                            value: l,
                            label: subjectList?.find(
                              (e) => e.id == l
                            )?.subject
                          })) : null
                      }
                      onChange={(option) => {
                        setValues({
                          ...values,
                          subjectId: option.map(l => l.value),
                        })
                      }}
                      onFocus={() => setFieldTouched('subject', false)}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                    />
                    {touched.subjectId && (
                      <p className="text-red-700 error_msg mt-2">
                        {errors.subjectId}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Location
                      </label>
                      <span className="text-red-500 mx-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="locationId"
                      id="locationId"
                      isSearchable
                      loadingMessage="Getting Location..."
                      placeholder="Select a Location"
                      isMulti={true}
                      value={
                        values.locationId
                          ? values?.locationId?.map((l) => ({
                            value: l,
                            label: location.find((e) => e.id == l)
                              ?.name,
                          }))
                          : null
                      }
                      options={location?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          locationId: option.map((l) => l.value),
                        })
                      }}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched('locationId', false)}
                    />
                    {touched.locationId && (
                      <p className="text-red-700 error_msg mt-2">
                        {errors.locationId}
                      </p>
                    )}
                  </div>
                </div>
                <div className='mt-10'>
                  <span className='font-bold text-xl'>Banking Details</span>
                  <hr className='text-gray-400'></hr>
                </div>
                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">

                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        BSB Number
                      </label>
                      <span className="text-red-500 mx-1">*</span>
                    </div>
                    <Input
                      className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="BSB"
                      id="BSB"
                      placeholder="Enter BSB Number..."
                      value={values.BSB}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                      onFocus={() => setFieldTouched('BSB', false)}
                    />
                    {touched.BSB && (
                      <p className="text-red-700 error_msg">
                        {errors.BSB}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Account Number
                      </label>
                      <span className="text-red-500 mx-1">*</span>
                    </div>
                    <Input
                      className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="accountNo"
                      id="accountNo"
                      placeholder="Enter Account Number"
                      value={values.accountNo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                      onFocus={() => setFieldTouched('accountNo', false)}
                    />
                    {touched.accountNo && (
                      <p className="text-red-700 error_msg">
                        {errors.accountNo}
                      </p>
                    )}
                  </div>
                </div>
                <div className='mt-10'>
                  <span className='font-bold text-xl'>Taxation Details</span>
                  <hr className='text-gray-400'></hr>
                </div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                  <div className="mt-4 col-span-2">
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >ABN #</label>
                      <InformationCircleIcon className="w-5 ml-2 " onMouseEnter={() => handleMouseEnter('abn')} onMouseLeave={handleMouseLeave} />
                      {isHovered && hoveredField === 'abn' && (
                        <div className="bg-gray-100">
                          <p className="mx-2 block text-sm">
                            Location Manager should check the ABN #
                          </p>
                        </div>
                      )}
                    </div>
                    <div className='flex'>
                      <input
                        className="block w-full appearance-none rounded-md border mt-1 border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="abn"
                        type="text"
                        value={values.abn}
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onFocus={() => setFieldTouched('abn', false)}
                        placeholder="Enter Australian Business Number..."
                      />
                      <p className="w-8 rounded-r-md border-y border-r border-gray-300 mt-1  appearance-none cursor-pointer" onClick={() => getAbnValue(values.abn)}><MagnifyingGlassIcon className='w-full drop-shadow-md mt-1' /></p>
                    </div>
                    {touched.abn && (
                      <p className="text-red-700 error_msg">{errors.abn}</p>
                    )}

                    {abnError && (
                      <p className="text-red-700 error_msg">{abnError}</p>
                    )}
                  </div>

                  <div className="mt-4 col-span-2">
                    <div className='flex '>
                      <label className='block text-sm font-medium text-gray-700' >GST Registeration Date</label>
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="gst"
                      label="GST"
                      type="text"
                      value={values.gstValue}
                      autoComplete="off"
                      onBlur={handleBlur}
                      disabled
                      placeholder={gstValue ? moment(gstValue).format('DD MM YYYY') : null}
                      onFocus={() => setFieldTouched('gst', false)}
                    />
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >ABN Status</label>
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="abnStatus"
                      type="text"
                      value={values.abnStatus}
                      autoComplete="off"
                      onBlur={handleBlur}
                      disabled
                      placeholder={abnStatus}

                    />
                  </div>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Entity Name</label>
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="entityName"
                      type="text"
                      value={values.entityName}
                      autoComplete="off"
                      onBlur={handleBlur}
                      disabled
                      placeholder={entityName}
                    />
                  </div>
                </div>

                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        TFN #
                      </label>
                      <InformationCircleIcon className="w-5 ml-2 " onMouseEnter={() => handleMouseEnter('tfn')} onMouseLeave={handleMouseLeave} />
                      {isHovered && hoveredField === 'tfn' && (
                        <div className="bg-gray-100">
                          <p className="mx-2 block text-sm  ">
                            Location Manager should check the TFN #
                          </p>
                        </div>
                      )}
                    </div>
                    <Input
                      name="tfn"
                      value={values.tfn}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={() => setFieldTouched('tfn', false)}
                      placeholder="Enter TFN number..."
                    />
                    {touched.tfn && (
                      <p className="text-red-700 error_msg">
                        {errors.tfn}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Super #
                      </label>
                      <InformationCircleIcon className="w-5 ml-2 " onMouseEnter={() => handleMouseEnter('super')} onMouseLeave={handleMouseLeave} />
                      {isHovered && hoveredField === 'super' && (
                        <div className="bg-gray-100">
                          <p className="mx-2 block text-sm  ">
                            Location Manager should check the Super #
                          </p>
                        </div>
                      )}
                    </div>
                    <Input
                      name="super"
                      value={values.super}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={() => setFieldTouched('super', false)}
                      placeholder="Enter super number...."
                    />
                    {touched.super && (
                      <p className="text-red-700 error_msg">
                        {errors.super}
                      </p>
                    )}
                  </div>
                </div>

                <div className='mt-4'>
                  <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Membership #
                        </label>
                        <InformationCircleIcon className="w-5 ml-2 " onMouseEnter={() => handleMouseEnter('membershipNumber')} onMouseLeave={handleMouseLeave} />
                        {isHovered && hoveredField === 'membershipNumber' && (
                          <div className="bg-gray-100">
                            <p className="mx-2 block text-sm  ">
                              Location Manager should check the Membership #
                            </p>
                          </div>
                        )}
                      </div>
                      <Input
                        name="membershipNumber"
                        value={values.membershipNumber}
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onFocus={() => setFieldTouched('membershipNumber', false)}
                        placeholder="Enter Membership number..."
                      />
                      {touched.membershipNumber && (
                        <p className="text-red-700 error_msg">
                          {errors.membershipNumber}
                        </p>
                      )}
                    </div>
                    <div className="col-span-2">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          ABN #
                        </label>
                        <InformationCircleIcon className="w-5 ml-2 " onMouseEnter={() => handleMouseEnter('abnorusi')} onMouseLeave={handleMouseLeave} />
                        {isHovered && hoveredField === 'abnorusi' && (
                          <div className="bg-gray-100">
                            <p className="mx-2 block text-sm  ">
                              Location Manager should check the ABN #
                            </p>
                          </div>
                        )}
                      </div>
                      <Input
                        name="abnorusi"
                        value={values.abnorusi}
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onFocus={() => setFieldTouched('abnorusi', false)}
                        placeholder="Enter ABN number..."
                      />
                      {touched.abnorusi && (
                        <p className="text-red-700 error_msg">
                          {errors.abnorusi}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-4">
                  <div className="col-span-4">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        USI #
                      </label>
                      <InformationCircleIcon className="w-5 ml-2 " onMouseEnter={() => handleMouseEnter('usi')} onMouseLeave={handleMouseLeave} />
                      {isHovered && hoveredField === 'usi' && (
                        <div className="bg-gray-100">
                          <p className="mx-2 block text-sm  ">
                            Location Manager should check the USI #
                          </p>
                        </div>
                      )}
                    </div>
                    <Input
                      name="usi"
                      value={values.usi}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={() => setFieldTouched('usi', false)}
                      placeholder="Enter USI number..."
                    />
                    {touched.usi && (
                      <p className="text-red-700 error_msg">
                        {errors.usi}
                      </p>
                    )}
                  </div>

                </div>


                <div className='mt-4'>
                  <Switch.Group
                    as="div"
                    className="flex items-center justify-start"
                  >
                    <span className="flex flex-col">
                      <Switch.Label
                        as="span"
                        className="text-sm font-medium text-gray-900"
                        passive
                      >
                        Active
                      </Switch.Label>
                    </span>
                    <Switch
                      checked={enabled}
                      onChange={setEnabled}
                      className={classNames(
                        enabled ? 'bg-indigo-600' : 'bg-gray-200',
                        'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          enabled ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
                {type == 'edit' &&
                  <div className="mt-8 mb-4">
                    <div className='flex mb-4'>
                      <p className='font-bold text-xl'>Attendance</p>
                      <d className='text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center mt-1'><p>{data?.teacherAtt?.filter(index => index.attendance == 1).length}</p></d>
                    </div>
                    <div className="mt-1 w-full border">
                      <div className="w-full h-10 bg-gray-200 bg-stone-300 border-b">
                        <div class="grid grid-cols-5 gap-4">
                          <div className="flex justify-center mt-2 font-semibold">
                            Class Name
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            Subject
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            Date of attended class
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            Start Time
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            End Time
                          </div>
                        </div>
                      </div>
                      {data?.teacherAtt?.filter(index => index.attendance == 1).map((c, idx) => (
                        <div className="w-full h-10 bg-gray-100 shadow-sm">
                          <div class="grid grid-cols-5 gap-4">
                            <span className="cursor-pointer hover:text-indigo-800" >
                              <div className="flex justify-center mt-2">
                                {c?.class?.className}
                              </div>
                            </span>
                            <div className="flex justify-center mt-2">
                              {c?.class?.subject.subject}
                            </div>
                            <div className="flex justify-center mt-2">
                              {moment(c.date).format('DD-MM-YYYY')}
                            </div>
                            <div className="flex justify-center mt-2">
                              {moment(c?.from_time).format('h:mm A')}
                            </div>
                            <div className="flex justify-center mt-2">
                              {moment(c?.end_time).format('h:mm A')}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                }
                <div className="mt-4 sm:mt-6 flex justify-end gap-2 items-center mb-4">
                  <div>
                    <Button
                      type="button"
                      className={'bg-red-500 hover:bg-red-700'}
                      onClick={() => { { setTeacherModal((prev) => ({ ...prev, state: false })) } }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div>
                    <Button type="submit" disabled={isSubmitting}>
                      {isSubmitting
                        ? 'Submitting'
                        : 'Submit'}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </BigSlideover>
        )}
      </Formik>
    )
  }

  const renderLocationModal = () =>{
    const { type, state, edit_id, data } = locationModal
    return (
      <Formik
        initialValues={data}
        validationSchema={locationSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) =>{
          if (type === 'add')
          {
            app_api
              .post('/location', {
                ...values, gst: gstValue, abnStatus: abnStatus,
                entityName: entityName,
              })
              .then((res) =>
              {
                getLocation()
                cleanModalData()
                setSubmitting(false)
                toast.success('Successfully added location')
                resetForm()
              })
              .catch((err) =>
              {
                toast.error(err.response.data.message)
                getLocation()
                // cleanModalData()
                setSubmitting(false)
              })
          } else
          {
            app_api
              .patch(`/location/${edit_id}`, { ...values, gst: gstValue })
              .then((res) =>
              {
                let updatedLocationData = [...location]
                updatedLocationData[modal.index] = modal.data
                setLocation(updatedLocationData)
                getLocation()
                toast.success('Successfully updated location')
                cleanModalData()
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {
                toast.error(err.response.data.message)
               })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          setFieldTouched,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
          resetForm,
        }) => (
          <BigSlideover
            open={state}
            setOpen={() =>{
              setLocationModal((prev) => ({ ...prev, state: false }))
            }}
            title={'Location'}
            handleClick={''}
            data={''}
            >
            <form onSubmit={handleSubmit} noValidate>
              <div className="text-left mt-4">
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Name</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  name="name"
                  label="Name"
                  value={values.name}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder='Enter Name...'
                  onFocus={()=>setFieldTouched('name',false)}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
                {touched.name && (
                  <p className="text-red-700 mb-1 error_msg">{errors.name}</p>
                )}
                  </div>
                  <div className="col-span-2">
                <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Point of Contact</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <Select
                      className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="pointOfContact"
                      id="pointOfContact"
                      isSearchable
                      loadingMessage="Getting User..."
                      placeholder="Select a user..."
                    value={
                      values.pointOfContact
                        ? LocationManager?.map((l) => ({
                          value: LocationManager?.find(
                            (e) => e.name == values.pointOfContact
                          )?.id,
                          label: LocationManager?.find(
                            (e) => e.name == values.pointOfContact
                          )?.name,
                        }))
                        : null
                    }
                      options={LocationManager.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.name,
                    }))}
                    onChange={(option) =>
                    {
                      console.log(option)
                      setValues({
                        ...values,
                        pointOfContact: option.value,
                        userId: option.id
                      })
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    onFocus={()=>setFieldTouched('city',false)}
                    maxMenuHeight={110}
                  />
                    {touched.city && (
                      <p className="text-red-700 error_msg mt-2">{errors.city}</p>
                    )}
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className="col-span-2">
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Phone / Mobile Number</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <input
                      name="phoneNumber"
                      label="Mobile / Phone Number"
                      placeholder='Enter Mobile / Phone Number'
                      type="tel"
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      value={values.phoneNumber}
                      autoComplete="off"
                      onFocus={()=>setFieldTouched('phoneNumber',false)}
                      onBlur={(e) =>
                      {
                        setValues({
                          ...values,
                          phoneNumber: e.target.defaultValue,
                        })
                      }}
                      onChange={handleChange}
                    />
                    {touched.phoneNumber && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.phoneNumber}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Email Address</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="emailAddress"
                  label="Email Address"
                  type="text"
                  value={values.emailAddress}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={()=>setFieldTouched('emailAddress',false)}
                  placeholder="Enter Email Address..."
                />
                {touched.emailAddress && (
                  <p className="text-red-700 error_msg">{errors.emailAddress}</p>
                )}
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Address Line 1</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="address"
                  label="Address"
                  type="text"
                  value={values.address}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={()=>setFieldTouched('address',false)}
                  placeholder="Enter Address..."
                />
                {touched.address && (
                  <p className="text-red-700 error_msg">{errors.address}</p>
                )}
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                  <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >City</label>
                    <span className='text-red-700 ml-1'>*</span>
                  </div>
                  <Select
                      className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="studentCity"
                      id="studentCity"
                    isSearchable
                      loadingMessage="Getting City..."
                      placeholder="Select City..."
                    value={
                      values.city
                        ? studentCity?.map((l) => ({
                          value: studentCity?.find(
                            (e) => e.name == values.city
                          )?.isoCode,
                          label: studentCity?.find(
                            (e) => e.name == values.city
                          )?.name,
                        }))
                        : null
                    }
                      options={studentCity.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.name,
                    }))}
                    onChange={(option) =>
                    {
                      setValues({
                        ...values,
                        city: option.value,
                        postCode: ''
                      })
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                    onFocus={()=>setFieldTouched('city',false)}
                  />
                    {touched.city && (
                      <p className="text-red-700 error_msg mt-2">{errors.city}</p>
                    )}
                  </div>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >State</label>
                    <span className='text-red-700 ml-1'>*</span>
                  </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="state"
                      id="state"
                      isSearchable
                      loadingMessage="Getting State..."
                      placeholder="Select a State..."
                      value={
                        values.state
                          ? studentState?.map((l) => ({
                            value: studentState?.find((e) => e.name == values.state)?.isoCode,
                            label: studentState?.find((e) => e.name == values.state)?.name,
                          }))
                          : defaultState ?
                            studentState?.map((l) => ({
                            value: studentState?.find((e) => e.name == defaultState[0].name)?.isoCode,
                            label: studentState?.find((e) => e.name == defaultState[0].name)?.name
                            }))
                          : null
                      }
                      options={studentState.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.name,
                      }))}
                      onChange={(option) =>
                      {
                        getCities(
                          option.countryCode,
                          option.isoCode,
                        )
                        setValues({
                          ...values,
                          state: option.value,
                        })
                        checkPostCode(values.postCode, option.value)
                      }}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                      onFocus={()=>setFieldTouched('state',false)}
                    />
                    {touched.state && (
                      <p className="text-red-700 error_msg">{errors.state}</p>
                    )}
                  </div></div>
                <div className='mt-4'></div>

                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Postcode</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="postCode"
                  label="postCode"
                  type="text"
                  value={values.postCode}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      postCode: e.target.value,
                    })
                    checkPostCode(e.target.value, values.state)
                  }}
                  placeholder="Enter postcode"
                  maxLength={4}
                  onFocus={()=>setFieldTouched('postCode',false)}
                />
                {touched.postCode && (
                  <p className="text-red-700 error_msg">{errors.postCode}</p>
                )}
                {postCodeError && (
                      <p className="text-red-700 error_msg">{postCodeError}</p>
                )}
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Account Name</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="accountName"
                  label="Account Name"
                  type="text"
                  value={values.accountName}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Account Name..."
                  onFocus={()=>setFieldTouched('accountName',false)}
                />
                {touched.accountName && (
                  <p className="text-red-700 error_msg">{errors.accountName}</p>
                )}
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >BSB</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="BSB"
                      label="BSB"
                      type="text"
                      value={values.BSB}
                      autoComplete="off"
                      maxLength="6"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Bank BSB..."
                      onFocus={()=>setFieldTouched('BSB',false)}
                    />
                    {touched.BSB && (
                      <p className="text-red-700 error_msg">{errors.BSB}</p>
                    )}
                  </div>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Account Number</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="accountNumber"
                      label="Account Number"
                      type="text"
                      value={values.accountNumber}
                      autoComplete="off"
                      maxLength="11"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Bank Account Number..."
                      onFocus={()=>setFieldTouched('accountNumber',false)}
                    />
                    {touched.accountNumber && (
                      <p className="text-red-700 error_msg">{errors.accountNumber}</p>
                    )}
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Google My Business</label>
                  {/*<span className='text-red-700 ml-1'>*</span>*/}
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="googleurl"
                  label="Account Number"
                  type="url"
                  value={values.googleurl}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="https://www.google.com/"
                  onFocus={()=>setFieldTouched('googleurl',false)}
                />
                  </div>
                  <div className='col-span-2'>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Facebook</label>
                  {/*<span className='text-red-700 ml-1'>*</span>*/}
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="facebook"
                  label="Account Number"
                  type="url"
                  value={values.facebook}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={()=>setFieldTouched('facebook',false)}
                  placeholder="https://www.facebook.com/"
                />
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >ABN</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <div className='flex'>
                  <input
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="abn"
                    type="text"
                    value={values.abn}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={()=>setFieldTouched('abn',false)}
                    placeholder="Enter Australian Business Number..."
                  />
                  <p className="w-8 rounded-r-md border-y border-r border-gray-300  py-1 appearance-none " onClick={() => getAbnValue(values.abn)}><MagnifyingGlassIcon className='w-full drop-shadow-md' /></p>
                </div>{touched.abn && (
                  <p className="text-red-700 error_msg">{errors.abn}</p>
                )}
                {abnError && (
                  <p className="text-red-700 error_msg">{abnError}</p>
                )}
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >GST Register Date</label>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="gst"
                  label="GST"
                  type="text"
                  value={values.gstValue}
                  autoComplete="off"
                  onBlur={handleBlur}
                  disabled
                  // onChange={handleChange}
                  placeholder={gstValue ? moment(gstValue).format('DD MM YYYY') : null}
                  onFocus={()=>setFieldTouched('gst',false)}
                />
                {touched.gst && (
                  <p className="text-red-700 error_msg">{errors.gst}</p>
                )}
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >ABN Status</label>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="abnStatus"
                  type="text"
                  value={values.abnStatus}
                  autoComplete="off"
                  onBlur={handleBlur}
                  disabled
                  // onChange={handleChange}
                  placeholder={abnStatus}

                />
                  </div><div className='col-span-2'>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Entity Name</label>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="entityName"
                  type="text"
                  value={values.entityName}
                  autoComplete="off"
                  onBlur={handleBlur}
                  disabled
                  placeholder={entityName}
                />
                  </div></div>
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Xero API Key</label>
                  {/* <span className='text-red-700 ml-1'>*</span> */}
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="xeroAPIKey"
                  label="Xero API Key"
                  type="text"
                  value={values.xeroAPIKey}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Xero API Key..."
                  onFocus={()=>setFieldTouched('xeroAPIKey',false)}
                />
                {touched.xeroAPIKey && (
                  <p className="text-red-700 error_msg">{errors.xeroAPIKey}</p>
                )}
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Xero Invoice Branding Theme ID</label>
                  {/* <span className='text-red-700 ml-1'>*</span> */}
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="brandingThemeID"
                  type="text"
                  value={values.brandingThemeID}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Xero Invoice Branding Theme ID..."
                  onFocus={()=>setFieldTouched('brandingThemeID',false)}
                />
                {touched.brandingThemeID && (
                  <p className="text-red-700 error_msg">{errors.brandingThemeID}</p>
                )}
                <div className="mt-4 sm:mt-6"></div>

                <div className="mt-4 sm:mt-6">
                  <Button type="submit"
                    disabled={isSubmitting || postCodeError}>
                    {isSubmitting ? (type === 'add' ? 'Adding...' : 'Saving...') : (type === 'add' ? 'Add Location' : 'Update Location')}
                  </Button>
                </div>
              </div>

            </form>
          </BigSlideover>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
      {JSON.parse(localStorage.getItem('moldKey')).role == 'teacher' ?
        <div className="max-w-[1680px] mx-auto p-4 max-h-screen">
          <div className="px-2 ">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-lg font-semibold text-gray-900 dashboardTitle">
                  Welcome {JSON.parse(localStorage.getItem('moldKey'))?.name}
                </h1>
                <p className="my-2 text-sm text-gray-700 ml-2">
                  Here's your list of classes
                </p>
              </div>
            </div>

            {loading ? (
              <div className="flex items-center justify-center h-screen">
                <div className="spinner-border animate-spin w-8 h-8 border-4 rounded-full" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="h-screen flex mb-4">
                <div className="w-full ">
                  <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: '80%' }}
                    className="bg-white rounded shadow-lg p-4"
                  />
                </div>
              </div>
            )}

          </div>
        </div>
        :
        <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky min-h-screen">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-3xl font-semibold text-gray-900">Teacher Schedule</h1>
              <p className="my-2 text-sm text-gray-700">
                A list of all the teacher's schedules .
              </p>
            </div>
            {/* <div className="sm:mt-0 sm:ml-16 sm:flex-none">
              </div> */}
          </div>
          {loading ? (
            <div className="flex items-center justify-center">
              <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) :
            <>
              {/* <div className="absolute mt-5 w-7/12 flex justify-end items-center text-right right-0 mr-2 gap-2">
                <div className="w-1/4 flex">
                  <DatePicker
                    className='text-center block py-2 px-2 border w-full appearance-none rounded-md placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                    selected={startDate}
                    dateFormat="dd-MM-yyyy"
                    isClearable
                    onChange={(date) => {
                      setStartDate(date)
                    }}
                    selectsStart
                    placeholderText='From Date'
                    startDate={startDate}
                    endDate={endDate}
                  />
                  <DatePicker
                    className='ml-1 text-center block py-2 px-2 border w-full appearance-none rounded-md placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                    selected={endDate}
                    dateFormat="dd-MM-yyyy"
                    isClearable
                    onChange={(date) => {
                      setEndDate(date)
                    }}
                    selectsEnd
                    placeholderText='To Date'
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                  />
                </div>
                <div className="w-1/4">
                  <Select
                    className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="location"
                    id="location"
                    isSearchable
                    loadingMessage="Getting Location..."
                    placeholder="Location"
                    isClearable={true}
                    isMulti
                    options={location?.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.id,
                    }))}
                    onChange={(option) => {
                      setLocationFilter(option)
                    }}
                  />
                </div>
              </div> */}
              <div className="relative mx-2">
                <div className="w-full flex md:mt-10 mt-16 absolute justify-between gap-2">
                  <div></div>
                  <div className="flex justify-between gap-4 ">
                    <div
                      onClick={() => setIsFilterSidebarOpen(!isFilterSidebarOpen)}
                      className="rounded-full py-2 px-2 cursor-pointer border shadow-sm bg-indigo-800 hover:bg-indigo-700 text-white"
                    >
                      {filterData.loading ? (
                        <div className="flex items-center justify-center">
                          <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status">
                          </div>
                        </div>) : <CiFilter className="w-6 h-6" />}
                    </div>
                  </div>
                </div>

                {/* Filter sidebar */}
                {isFilterSidebarOpen && (
                  <div className='h-full'>
                    <div className="absolute bg-white border rounded-lg shadow-lg w-full  md:w-1/2 lg:w-1/4 md:top-24 md:right-0 top-28 z-10">
                      <div className='flex justify-between p-2'>
                        <span className=" text-sm text-gray-700 ">Filters</span>
                        <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                          setFilterData({
                            startDate: null,
                            endDate: null,
                            location: '',
                            loading: false
                          })
                          handleClearFilters()
                        }}> Reset All </span>
                      </div>
                      <hr className='' />
                      <div className="w-full flex flex-col p-2">
                        <div className='flex justify-between mb-2'>
                          <span className=" text-sm text-gray-700 ">Date Range</span>
                          <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                            setFilterData({
                              ...filterData,
                              startDate: null,
                              endDate: null,
                            })
                          }}> Reset </span>
                        </div>
                        <div className="w-full flex gap-2 justify-between">
                          <div className="flex customDatePickerWidth items-center">
                            <DatePicker
                              selected={null}
                              onChange={(dates) => {
                                const [start, end] = dates
                                setFilterData({ ...filterData, startDate: start, endDate: end })
                              }}
                              showIcon
                              isClearable
                              startDate={filterData.startDate}
                              endDate={filterData.endDate}
                              dateFormat="MMM dd, yyyy"
                              selectsRange
                              className="text-center block py-2 px-2 border w-full appearance-none rounded-md placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              placeholderText="Select Date Range"
                              maxDate={new Date()}
                            />
                          </div>
                        </div>
                      </div>
                      <hr className='' />
                      <div className="w-full p-2">
                        <div className='flex justify-between mb-2'>
                          <span className=" text-sm text-gray-700 ">Location</span>
                          <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                            setFilterData({
                              ...filterData,
                              location: '',
                            })
                          }}> Reset </span>
                        </div>
                        <Select
                          className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          name="location"
                          id="location"
                          isSearchable
                          loadingMessage="Getting Location..."
                          placeholder="Location"
                          isClearable={true}
                          isMulti
                          options={location?.map((l) => ({
                            ...l,
                            label: l.name,
                            value: l.id,
                          }))}
                          value={filterData.location}
                          onChange={(option) => {
                            setFilterData({
                              ...filterData,
                              location: option,
                            })
                            // setLocationFilter(option)
                          }}
                        />
                      </div>
                      <hr className='' />
                      <div className="flex justify-between gap-2 p-2">
                        <div className="">
                          <Button
                            type="button"
                            className={'bg-red-500 hover:bg-red-700'}
                            onClick={() => {
                              setFilterData({
                                startDate: null,
                                endDate: null,
                                location: '',
                                loading: false
                              })
                              handleClearFilters()
                            }}
                          >
                            Reset All
                          </Button>
                        </div>
                        <div className="">
                          <Button
                            type="button"
                            onClick={() => {
                              handleFilters()
                            }}
                          >
                            Apply Now
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {renderTeacherModal()}
              {renderLocationModal()}
              <Table
                progPage={page}
                totalCount={count}
                columns={TeacherSchedule_column({ onEditOpen, onTeacherOpen, onEditLocationOpen })}
                data={TabData}
                setSearchFilter={setSearchFilter}
              />
              <nav
                className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                aria-label="Pagination"
              >
                <div>
                  <p className="text-sm text-gray-700">
                    Showing{' '}
                    <select
                      onChange={(e) => {
                        setLimit(e.target.value)
                        setPage(0)
                      }}
                      value={limit}
                    >
                      <option value={count}>{count}</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                    </select>{' '}
                    of <span className="font-medium">{count}</span> results
                  </p>
                </div>
                <div className="flex items-center md:justify-end">
                  <span
                    onClick={goPrev}
                    className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                  >
                    Previous
                  </span>
                  <span className="text-sm">
                    Page{' '}
                    <input
                      className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
                      type="number"
                      value={page + 1}
                      onChange={(e) => setPage(e.target.value - 1)}
                      max={totalPages}
                      min="1"
                    />
                    <span className='ml-2'></span>/ {Math.ceil(totalPages)}
                  </span>
                  <span
                    onClick={goNext}
                    className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                  >
                    Next
                  </span>
                </div>
              </nav>
            </>}
        </div>
      }

      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        autoClose={2000}
        hideProgressBar={false} />
    </Sidebar>
  )
}

export default TeacherSchedule
