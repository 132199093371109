import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition, Disclosure } from '@headlessui/react'
import {
  Bars3BottomLeftIcon,
  CalendarIcon,
  FolderIcon,
  InboxIcon,
  UsersIcon,
  XMarkIcon,
  MapIcon,
  PhoneIcon,
  TagIcon,
  UserIcon,
  AcademicCapIcon,
  KeyIcon,
  EyeSlashIcon,
  EnvelopeOpenIcon,
  CurrencyDollarIcon,
  GlobeAsiaAustraliaIcon,
  UserPlusIcon,
  ChartPieIcon,
  UserGroupIcon,
  InboxStackIcon,
  BookOpenIcon,
  VideoCameraIcon,
  UserCircleIcon,
  NewspaperIcon,
  ChevronRightIcon,
  PresentationChartBarIcon,
  BanknotesIcon, CreditCardIcon,
  ReceiptRefundIcon, ClockIcon,
  Cog8ToothIcon,
  WalletIcon,
} from '@heroicons/react/24/outline'
import { MdFamilyRestroom } from 'react-icons/md'
import { classNames } from '../../helpers/classname'
import { Button } from '../tables/paginationButtons'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import logo from '../../assets/icons/logo.png'
import WhiteLogo from '../../assets/icons/white-logo.png'

const navigation = [
  {
    name: 'CRM',
    current: false,
    children: [
      {
        name: 'Leads',
        href: '/leads',
        icon: UserPlusIcon,
        current: true,
        requiredRole: ['admin', 'manager'],
      },
      {
        name: 'Student',
        href: '/student',
        icon: AcademicCapIcon,
        current: false,
        requiredRole: ['admin'],
      },
      {
        name: 'Family',
        href: '/family',
        icon: MdFamilyRestroom,
        current: false,
        requiredRole: ['admin'],
      },
      {
        name: 'Classes',
        href: '/class-master',
        icon: ChartPieIcon,
        current: false,
        requiredRole: ['admin'],
      },
      {
        name: 'Invoices',
        href: '/invoice-master',
        icon: CurrencyDollarIcon,
        current: false,
        requiredRole: ['admin'],
      },
      {
        name: 'Payments',
        href: '/payment-master',
        icon: CurrencyDollarIcon,
        current: false,
        requiredRole: ['admin'],
      },
      {
        name: 'Credit Note',
        href: '/credit-note',
        icon: CreditCardIcon,
        current: false,
        requiredRole: ['admin'],
      },

    ],
    icon: NewspaperIcon,
    current: false,
    requiredRole: ['admin'],
  },
  {
    name: 'Online Exam',
    current: false,
    children: [
      {
        name: 'Contact',
        href: '/contact',
        icon: AcademicCapIcon,
        current: false,
        requiredRole: ['admin'],
      },
      {
        name: 'Enrolments',
        href: '/enrolments',
        icon: UsersIcon,
        current: false,
        requiredRole: ['admin'],
      },
      {
        name: 'Exams',
        href: '/exam',
        icon: FolderIcon,
        current: false,
        requiredRole: ['admin'],
      },
      {
        name: 'Batch',
        href: '/batch',
        icon: CalendarIcon,
        current: false,
        requiredRole: ['admin'],
      },
      {
        name: 'Services',
        href: '/services',
        icon: InboxIcon,
        current: false,
        requiredRole: ['admin'],
      },
      {
        name: 'Category',
        href: '/category',
        icon: TagIcon,
        current: false,
        requiredRole: ['admin'],
      },
      {
        name: 'Referral',
        href: '/referral',
        icon: EnvelopeOpenIcon,
        current: false,
        requiredRole: ['admin'],
      },
    ],
    icon: PresentationChartBarIcon,
    current: false,
    requiredRole: ['admin'],
  },
  {
    name: 'Teacher',
    current: false,
    children: [
      {
        name: 'Teacher Master',
        href: '/teacher-master',
        icon: UserCircleIcon,
        current: false,
        requiredRole: ['admin'],
      },
      {
        name: 'Teacher Schedule',
        href: '/teacher-schedule',
        icon: ClockIcon,
        current: false,
        requiredRole: ['admin'],
      },
      {
        name: 'Teacher Invoices',
        href: '/teacher-invoice',
        icon: CurrencyDollarIcon,
        current: false,
        requiredRole: ['admin'],
      },
      {
        name: 'Teacher Payments',
        href: '/teacher-payments',
        icon: CurrencyDollarIcon,
        current: false,
        requiredRole: ['admin'],
      }

    ],
    icon: UserCircleIcon,
    current: false,
    requiredRole: ['admin'],
  },
  {
    name: 'Exams',
    href: '/my-exams',
    icon: FolderIcon,
    current: true,
    requiredRole: 'student',
  },
  {
    name: 'Buy More',
    href: '/buy-more',
    icon: CurrencyDollarIcon,
    current: false,
    requiredRole: 'student',
  },
  {
    name: 'Leads',
    href: '/leads',
    icon: UserPlusIcon,
    current: false,
    requiredRole: ['manager'],
  },
  {
    name: 'Student',
    href: '/student',
    icon: AcademicCapIcon,
    current: false,
    requiredRole: ['manager'],
  },
  {
    name: 'Family',
    href: '/family',
    icon: MdFamilyRestroom,
    current: false,
    requiredRole: ['manager'],
  },
  {
    name: 'Classes',
    href: '/class-master',
    icon: ChartPieIcon,
    current: false,
    requiredRole: ['manager'],
  },
  {
    name: 'Invoice',
    href: '/invoice-master',
    icon: CurrencyDollarIcon,
    current: false,
    requiredRole: ['manager'],
  },
  {
    name: 'Payments',
    href: '/payment-master',
    icon: CurrencyDollarIcon,
    current: false,
    requiredRole: ['manager'],
  },
  {
    name: 'Credit Note',
    href: '/credit-note',
    icon: CreditCardIcon,
    current: false,
    requiredRole: ['manager'],
  },
  // {
  //   name: 'Term Dates',
  //   href: '/term-master',
  //   icon: NewspaperIcon,
  //   current: false,
  //   requiredRole: ['manager'],
  // },
  {
    name: 'Mail Log',
    href: '/email-log',
    icon: InboxStackIcon,
    current: false,
    requiredRole: ['manager'],
  },
  {
    name: 'Location',
    href: '/location',
    icon: MapIcon,
    current: false,
    requiredRole: ['admin'],
  },
  {
    name: 'Session Management',
    href: '/login-session',
    icon: GlobeAsiaAustraliaIcon,
    current: false,
    requiredRole: ['admin'],
  },
  {
    name: 'Users',
    href: '/users',
    icon: UserGroupIcon,
    current: false,
    requiredRole: ['admin'],
  },
  {
    name: 'Teacher',
    current: false,
    children: [
      {
        name: 'Teacher Master',
        href: '/teacher-master',
        icon: UserCircleIcon,
        current: false,
        requiredRole: ['manager'],
      },
      {
        name: 'Teacher Schedule',
        href: '/teacher-schedule',
        icon: ClockIcon,
        current: false,
        requiredRole: ['manager'],
      },
      {
        name: 'Teacher Invoices',
        href: '/teacher-invoice',
        icon: CurrencyDollarIcon,
        current: false,
        requiredRole: ['manager'],
      },
      {
        name: 'Teacher Payments',
        href: '/teacher-payments',
        icon: CurrencyDollarIcon,
        current: false,
        requiredRole: ['manager'],
      }

    ],
    icon: UserCircleIcon,
    current: false,
    requiredRole: ['manager'],
  },
  {
    name: 'Mail Log',
    href: '/email-log',
    icon: InboxStackIcon,
    current: false,
    requiredRole: ['admin'],
  },

  {
    name: 'Schedule',
    href: '/teacher-schedule',
    icon: ClockIcon,
    current: false,
    requiredRole: ['teacher'],
  },
  {
    name: 'My Classes',
    href: '/my-class',
    icon: BookOpenIcon,
    current: false,
    requiredRole: ['teacher'],
  },
  {
    name: 'My Invoices',
    href: '/teacher-invoice',
    icon: CurrencyDollarIcon,
    current: false,
    requiredRole: ['teacher'],
  },
  {
    name: 'My Payments',
    href: '/teacher-payments',
    icon: CurrencyDollarIcon,
    current: false,
    requiredRole: ['teacher'],
  },
  {
    name: 'My Class',
    href: '/schedule',
    icon: ClockIcon,
    current: false,
    requiredRole: ['parent'],
  },
  {
    name: 'My Child',
    href: '/my-child',
    icon: UserIcon,
    current: false,
    requiredRole: ['parent'],
  },
  {
    name: 'My Invoices',
    href: '/parent-invoice',
    icon: CurrencyDollarIcon,
    current: false,
    requiredRole: ['parent'],
  },
  {
    name: 'Reports',
    current: false,
    href: '/reports',
    icon: WalletIcon,
    requiredRole: ['admin','manager'],
  },
  {
    name: 'Settings',
    current: false,
    href: '/setting',
    icon: Cog8ToothIcon,
    requiredRole: ['admin','manager'],
  },
]

export const Sidebar = (props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [pathname, setPathname] = useState('/leads')
  const [moldKey, setmoldKey] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const location= useLocation()

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    localStorage.getItem('moldKey') ? (
      <Navigate to="/leads" />
    ) : (
      <Navigate to="/" />
    )
    setPathname(window.location.pathname)
  }, [window.location.pathname, moldKey])

  useEffect(() => {
    try {
      setmoldKey(JSON.parse(localStorage.getItem('moldKey')))
    } catch (err) { }
  }, [])

  return (
    <>
      <div className="overflow-hidden">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={() => setSidebarOpen(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex md:hidden">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-col bg-indigo-800 pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-shrink-0 items-center px-4 ">
                    <img className="h-10 w-auto" src={WhiteLogo} alt="Your Company" />
                  </div>
                  <div className="mt-5 flex-1 overflow-y-auto">
                    <nav className="flex-3 space-y-1 h-4/6 px-2">
                      {navigation.filter((item) => item.requiredRole.includes(moldKey.role))
                        .map((item) => (
                          <li key={item.name}>
                            {!item.children ? (
                              <Link
                                to={item.href}
                                className={classNames(
                                  pathname === item.href ||
                                  location.pathname.startsWith(item.href)
                                    ? 'bg-white text-blue-900 hover:text-blue-900'
                                    : 'text-white hover:bg-white-200 hover:bg-slate-500 hover:text-gray-600',
                                  'group flex items-center px-3 py-2 text-m font-medium rounded-md mt-2 ml-2 mr-2'
                                )}
                              >
                                <item.icon className={classNames(
                                  pathname === item.href ||
                                  location.pathname.startsWith(item.href)
                                    ? 'text-indigo-800'
                                    : 'text-white hover:bg-indigo-200',
                                  'mr-3 h-5 w-5 flex-shrink-0'
                                )} aria-hidden="true" />
                                {item.name}
                              </Link>
                            ) : (
                              <Disclosure as="div" defaultOpen={item.children.some(child => child.href === pathname)}>
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button
                                      className={classNames(
                                        open
                                          ? 'bg-white text-blue-900 hover:text-blue-900'
                                          : 'text-white hover:bg-white-200 hover:bg-slate-500 hover:text-gray-600',
                                        'w-11/12 group flex items-center px-3 py-2 text-m font-medium rounded-md mt-2 ml-2 mr-2'
                                      )}
                                    >
                                      <item.icon className={classNames(
                                        open ? 'text-indigo-800'
                                          : 'text-white hover:bg-indigo-200',
                                        'mr-3 h-5 w-5 flex-shrink-0'
                                      )} aria-hidden="true" />
                                      {item.name}
                                      <ChevronRightIcon
                                        className={classNames(
                                          open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                          'ml-auto h-5 w-5 shrink-0'
                                        )}
                                        aria-hidden="true"
                                      />
                                    </Disclosure.Button>
                                    <Disclosure.Panel as="ul" className="mt-1 px-2">
                                      {item.children.map((subItem) => (
                                        <li key={subItem.name}>
                                          <Link
                                            to={subItem.href}
                                            className={classNames(
                                              pathname === subItem.href
                                                ? 'bg-white text-blue-900 hover:text-blue-900'
                                                : 'text-white hover:bg-white-200 hover:bg-slate-500 hover:text-gray-600',
                                              'group flex items-center px-3 py-2 text-m font-medium rounded-md mt-2 ml-2 mr-2'
                                            )}
                                          >
                                            <subItem.icon className={classNames(
                                              pathname === subItem.href
                                                ? 'text-indigo-800'
                                                : 'text-white hover:bg-indigo-200',
                                              'mr-3 h-5 w-5 flex-shrink-0'
                                            )} aria-hidden="true" />
                                            {subItem.name}
                                          </Link>
                                        </li>
                                      ))}
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            )}
                          </li>
                        ))}
                      <li>
                        <Link
                          to="/resetpassword"
                          className="mt-3 text-white hover:bg-slate-500 hover:text-gray-600 group flex items-center px-3 py-2 text-m font-medium rounded-md"
                        >
                          <KeyIcon
                            className="mr-3 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                          Reset Password
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={'/logout'}
                          className={classNames("px-3 text-white hover:bg-indigo-200 group flex items-center px-2 py-2 text-base font-medium rounded-md")}
                        >
                          <UserIcon className="mr-4 h-6 w-6 flex-shrink-0 text-white" aria-hidden="true" />
                          Logout
                        </Link>
                      </li>

                    </nav>
                  </div>
                  <div></div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true"></div>
            </div>

          </Dialog>
        </Transition.Root>

        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          <div className="flex flex-grow flex-col overflow-y-auto border-r-2 border-gray-200 sidebarDiv">
            <div className="flex flex-shrink-0 px-4 w-full mt-2">
              <img
                className="h-12 items-center w-auto"
                src={WhiteLogo}
                alt="Your Company"
              />
            </div>
            <div className="flex flex-1 flex-col mt-4">
              <nav className="flex-3 space-y-1 h-4/6 absolute overflow-y-auto w-full px-2 pb-4">
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.filter((item) => item.requiredRole.includes(moldKey.role))
                    .map((item) => (
                      <li key={item.name}>
                        {!item.children ? (
                          <Link
                            to={item.href}
                            className={classNames(
                              pathname === item.href  ||
                              location.pathname.startsWith(item.href)
                                ? 'bg-white text-blue-900 hover:text-blue-900'
                                : 'text-white hover:bg-white-200 hover:bg-slate-500 hover:text-gray-600',
                              'group flex items-center px-3 py-2 text-m font-medium rounded-md mt-2 ml-2 mr-2'
                            )}
                          >
                            <item.icon className={classNames(
                              pathname === item.href  ||
                              location.pathname.startsWith(item.href)
                                ? 'text-indigo-800'
                                : 'text-white hover:bg-indigo-200',
                              'mr-3 h-5 w-5 flex-shrink-0'
                            )} aria-hidden="true" />
                            {item.name}
                          </Link>
                        ) : (
                          <Disclosure as="div" defaultOpen={item.children.some(child => child.href === pathname)}>
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  className={classNames(
                                    open
                                      ? 'bg-white text-blue-900 hover:text-blue-900'
                                      : 'text-white hover:bg-white-200 hover:bg-slate-500 hover:text-gray-600',
                                    'w-11/12 group flex items-center px-3 py-2 text-m font-medium rounded-md mt-2 ml-2 mr-2'
                                  )}
                                >
                                  <item.icon className={classNames(
                                    open ? 'text-indigo-800'
                                      : 'text-white hover:bg-indigo-200',
                                    'mr-3 h-5 w-5 flex-shrink-0'
                                  )} aria-hidden="true" />
                                  {item.name}
                                  <ChevronRightIcon
                                    className={classNames(
                                      open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                      'ml-auto h-5 w-5 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel as="ul" className="mt-1 px-2">
                                  {item.children.map((subItem) => (
                                    <li key={subItem.name}>
                                      <Link
                                        to={subItem.href}
                                        className={classNames(
                                          pathname === subItem.href
                                            ? 'bg-white text-blue-900 hover:text-blue-900'
                                            : 'text-white hover:bg-white-200 hover:bg-slate-500 hover:text-gray-600',
                                          'group flex items-center px-3 py-2 text-m font-medium rounded-md mt-2 ml-2 mr-2'
                                        )}
                                      >
                                        <subItem.icon className={classNames(
                                          pathname === subItem.href
                                            ? 'text-indigo-800'
                                            : 'text-white hover:bg-indigo-200',
                                          'mr-3 h-5 w-5 flex-shrink-0'
                                        )} aria-hidden="true" />
                                        {subItem.name}
                                      </Link>
                                    </li>
                                  ))}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        )}
                      </li>
                    ))}
                </ul>
              </nav>
              <div className="mt-auto group items-center px-2 py-2 text-sm font-medium rounded-md">
                <p className="user_role_msg">{moldKey.role}</p>
                <Link
                  to="/resetpassword"
                  className="mt-3 text-white hover:bg-slate-500 hover:text-gray-600 group flex items-center px-3 py-2 text-m font-medium rounded-md"
                >
                  <KeyIcon
                    className="mr-3 h-6 w-6 flex-shrink-0 text-white"
                    aria-hidden="true"
                  />
                  Reset Password
                </Link>
                <Link
                  to="/logout"
                  className="mt-3 text-white hover:bg-slate-500 hover:text-gray-600 group flex items-center px-3 py-2 text-m font-medium rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="mr-3 h-6 w-6 flex-shrink-0 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                    />
                  </svg>
                  {/* <UserIcon
                  className="mr-3 h-6 w-6 flex-shrink-0 text-indigo-400"
                  aria-hidden="true"
                /> */}
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col md:pl-64 minHeight">
          <div className="sticky top-0 z-10 md:hidden flex h-16 flex-shrink-0 bg-white shadow">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <main className="">{props.children}</main>
        </div>
      </div>

    </>
  )
}
