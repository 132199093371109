import { useState, useEffect, useCallback, useMemo } from 'react'
import Modal from '../components/modals'
import Input from '../components/input'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { contact_columns } from '../components/tables/tableheader'
import Label from '../components/label'
import { Sidebar } from '../components/navigation/sidebar'
import moment from 'moment/moment'
import ConfirmationDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import { familyStudentSchema, independentStudentSchema } from '../schema'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import HalfGeneralSlideover from '../components/half-general-slideover'

const initialModalState = {
  type: '',
  modalState: false,
  index: null,
  edit_id: '',
  data: {
    firstName: '',
    lastName: '',
    email: '',
    studentPhone: '',
    address1: '',
    address2: '',
    parentFirstName: '',
    parentLastName: '',
    parentEmail: '',
    parentPhone: '',
    studentState: '',
    city: '',
    postCode: '',
    selectFamily: '',
  },
}

const GradeList = [
  { id: 'Prep', name: 'Prep' },
  { id: 'Year 1', name: 'Year 1' },
  { id: 'Year 2', name: 'Year 2' },
  { id: 'Year 3', name: 'Year 3' },
  { id: 'Year 4', name: 'Year 4' },
  { id: 'Year 5', name: 'Year 5' },
  { id: 'Year 6', name: 'Year 6' },
  { id: 'Year 7', name: 'Year 7' },
  { id: 'Year 8', name: 'Year 8' },
  { id: 'Year 9', name: 'Year 9' },
  { id: 'Year 10', name: 'Year 10' },
  { id: 'Year 11', name: 'Year 11' },
  { id: 'Year 12', name: 'Year 12' },
]

const Contacts = () =>
{
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [contacts, setContacts] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [studentStates, setStudentState] = useState([])
  const [family, setFamily] = useState([])
  var today = new Date().toISOString().split('T')[0]
  const [gradeFilter, setGradeFilter] = useState('')
  const [cityFilter, setCityFilter] = useState('')
  const [city, setCity] = useState([])
  const [showFamilyDropdown, setShowFamilyDropdown] = useState(false)
  const [newFamily, setNewFamily] = useState(false)
  const [studentCity, setStudentCity] = useState([])
  const [TabData, setTabData] = useState([])
  const [pagingData, setPagingData]= useState({
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: "",
    sort: {
      order: "",
      key: "",
    },
  })

  useEffect(() => {
    setPagingData({
      ...pagingData,
      total: TabData.length
    })
  },[TabData])

  const renderTable = useCallback(() => {
    return (
      <>
        <Table
          onPaginationChange={onPaginationChange}
          pagingData={tableData}
          columns={contact_columns({ onEditOpen, onDeleteOpen })}
          data={
            TabData
          }
          onEditClose={cleanModalData}
        />
      </>
    )
  }, [TabData])

  const { pageIndex, pageSize, sort, query, total } = pagingData;

  const tableData = useMemo(
    () => ({ pageIndex, pageSize, sort, query, total }),
    [pageIndex, pageSize, sort, query, total]
  );

  const onPaginationChange = (page) => {
    let newService = [...contacts]
    setTabData(newService.slice((page-1) * pageSize))
    const newTableData = {...tableData};
    newTableData.pageIndex = page;
    setPagingData(newTableData)
  };

  useEffect(() => {
    if(pageIndex != 1){
      const newService = [...contacts]
      setTabData(newService.slice((pageIndex - 1) * pageSize))
    }
  }, [contacts])

  const onEditOpen = (id, index) =>
  {
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      modalState: true,
      data: TabData[index],
    }))
    if(contacts[index].studentState){
      let countryCodeName = studentStates.find(l => l.name == contacts[index].studentState)?.countryCode
      let isoCodeName = studentStates.find(l => l.name == contacts[index].studentState)?.isoCode
      console.log(countryCodeName, isoCodeName)
      getCities(countryCodeName, isoCodeName)
    }
  }

  const onDeleteOpen = (id, index) =>
  {
    setModal((prev) => ({ ...prev, id: id, index: index }))
    setConfirmationDialog(true)
  }

  const cleanModalData = () =>
  {
    setModal(initialModalState)
  }

  const onDeleteContact = () =>
  {
    const { id, index } = modal
    app_api
      .delete(`/contact/${id}`)
      .then((res) =>
      {
        let updateContacts = [...contacts]
        updateContacts.splice(index, 1)
        setContacts(updateContacts)
        setConfirmationDialog(false)
      })
      .catch((err) =>
      {
        setError(err.toString())
      })
  }

  const onNewContactChange = (e) =>
  {
    setShowFamilyDropdown(e.target.value === 'true')
    if (e.target.value === 'true')
    {
      setModal((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          contactId: '',
        },
      }))
    }
  }

  const getContactData = () =>
  {
    app_api
      .get('/contact')
      .then((res) =>
      {
        let data = res.data
        let temp_city = [
          ...new Set(
            data.map((l) =>
            {
              if (l.city == null)
              {
                return 'No City'
              } else
              {
                return l.city
              }
            })
          ),
        ]
        setCity(temp_city)
        setLoading(false)
        setError(null)
        setContacts(res.data)
        setTabData(data)
        setPagingData({
          ...pagingData,
          total: data.length
        })
      })
      .catch((err) =>
      {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getFamilyData = () =>
  {
    app_api
      .get('/family-master')
      .then((res) =>
      {
        let data = res.data.filter(l => l.archive == 0)
        setLoading(false)
        setError(null)
        setFamily(data)
      })
      .catch((err) =>
      {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getStateDataByBackend = () => {
    app_api
      .post('/state-city/state')
      .then((res) => {
        let data = res.data
        setStudentState(data)
        console.log(data)
        setLoading(false)
        setError(null)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }
  

  const getCities = (countryCode, isoCode) => {
    let payload = {
      countryCode: countryCode.toString(),
      isoCode: isoCode.toString()
    }
    console.log(payload)
    app_api
      .post('/state-city/city',payload)
      .then((res) => {
        let data = res.data
        if(isoCode === 'VIC'){
          data.push({
            countryCode: "AU",
            latitude: "-37.66955000",
            longitude: "144.88675000",
            name: "Lyndhurst",
            stateCode: "VIC"
          })
        }
        setStudentCity(data)
        console.log(data)
        setLoading(false)
        setError(null)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() =>
  {
    getStateDataByBackend()
    getContactData()
    getFamilyData()
  }, [])

  const renderModal = () =>
  {
    const { type, modalState, data, edit_id } = modal
    const {
      firstName,
      lastName,
      email,
      studentPhone,
      address1,
      address2,
      parentFirstName,
      parentLastName,
      parentEmail,
      parentPhone,
      studentState,
      city,
      postCode,
      selectFamily,
    } = data

    return (
      <Formik
        initialValues={data}
        validationSchema={showFamilyDropdown ? independentStudentSchema : familyStudentSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) =>
        {
          if (type === 'add')
          {
            if (showFamilyDropdown)
            {
              app_api
                .post('/contact', {
                  studentsFirstName: values.firstName,
                  studentsLastName: values.lastName,
                  studentsEmail: values.email,
                  studentPhone: values.studentPhone,
                  address1: values.address1, address2: values.address2,
                  parentFirstName: values.parentFirstName,
                  parentLastName: values.parentLastName,
                  parentEmail: values.parentEmail,
                  parentMobileNo: values.parentPhone,
                  state: values.studentState,
                  city: values.city,
                  postCode: values.postCode,
                  family: values.selectFamily,
                  type: 'independent'
                })
                .then((res) =>
                {
                  toast.success('Contact Added Successfully')
                  cleanModalData()
                  getContactData()
                  setSubmitting(false)
                  resetForm()
                })
                .catch((err) =>
                {
                  setError(
                    err?.response?.data?.message || 'error creating contact'
                  )
                  if (err.response.data.statusCode == 424)
                  {
                    toast.error('Contact Already Exist')
                    // cleanModalData()
                    getContactData()
                    setSubmitting(false)
                    resetForm()
                  } else
                  {
                    cleanModalData()
                    getContactData()
                    setSubmitting(false)
                    resetForm()
                  }
                })
            }
            else
            {
              app_api
                .post('/contact', {
                  studentsFirstName: values.firstName,
                  studentsLastName: values.lastName,
                  studentsEmail: values.email,
                  studentPhone: values.studentPhone,
                  address1: values.address1, address2: values.address2,
                  parentFirstName: values.parentFirstName,
                  parentLastName: values.parentLastName,
                  parentEmail: values.parentEmail,
                  parentMobileNo: values.parentPhone,
                  state: values.studentState,
                  city: values.city,
                  postCode: values.postCode,
                  family: values.selectFamily,
                  type: 'family'
                })
                .then((res) =>
                {
                  toast.success('Contact Added Successfully')
                  cleanModalData()
                  getContactData()
                  setSubmitting(false)
                  resetForm()
                })
                .catch((err) =>
                {
                  setError(
                    err?.response?.data?.message || 'error creating contact'
                  )
                  if (err.response.data.statusCode == 424)
                  {
                    toast.error('Contact Already Exist')
                    // cleanModalData()
                    getContactData()
                    setSubmitting(false)
                    resetForm()
                  } else
                  {
                    cleanModalData()
                    getContactData()
                    setSubmitting(false)
                    resetForm()
                  }
                })
            }
          } else
          {
            app_api
              .patch(`/contact/${edit_id}`, {
                parentFirstName: values.parentFirstName,
                parentLastName: values.parentLastName,
                parentEmail: values.parentEmail,
                parentMobileNo: values.parentMobileNo.toString(),
                studentsFirstName: values.studentsFirstName,
                studentsLastName: values.studentsLastName,
              })
              .then((res) =>
              {
                cleanModalData()
                getContactData()
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => { })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          setFieldTouched,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <HalfGeneralSlideover
            // onSubmit={handleSubmit}
            title={type === 'add' ? 'Add Contact' : 'Edit Contact'}
            open={modalState}
            setOpen={() =>
            {
              setModal((prev) => ({ ...prev, modalState: false }))
              cleanModalData()
            }}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="text-left">
                <div>
                <div className="relative flex items-start mt-4">
                { /*  <div className="flex h-5 items-center">
                      <input
                        id="new-contact"
                        aria-describedby="comments-description"
                        name="new-contact"
                        value="true"
                        type="radio"
                        onClick={onNewContactChange}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="new-contact"
                        className="font-medium text-gray-700"
                      >
                        Independent Student
                      </label>
                    </div>*/}
          </div>
                  <div className="relative flex items-start mt-2 mb-4">
                    <div className="flex h-5 items-center">
                      <input
                        id="existing-contact"
                        aria-describedby="comments-description"
                        value="false"
                        name="new-contact"
                        type="radio"
                        onClick={onNewContactChange}
                        defaultChecked
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="existing-contact"
                        className="font-medium text-gray-700"
                      >
                        Family
                      </label>
                    </div>
                  </div>
                  <hr></hr>

                 {/* {showFamilyDropdown && (
                    <>
                      <div className="mt-4"></div>
                      <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                        <div className='col-span-2'>
                      <div className="flex">
                        <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium"> First Name</label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                      <Input
                        name="firstName"
                        id="firstName"
                        // label="First Name"
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName}
                        className="mb-2"
                        placeholder="Enter First Name"
                        // onChange={(event) =>
                        //   onModalHandleChange(event.target.name, event.target.value)
                        // }
                      />
                      {touched.firstName && (
                        <p className="text-red-700 error_msg mb-2">
                          {errors.firstName}
                        </p>
                          )}</div>

                        <div className="col-span-2">
                      <div className="flex">
                        <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium"> Last Name</label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                      <Input
                        name="lastName"
                        // label="Last Name"
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastName}
                        className="mb-2"
                        placeholder="Enter Last Name"
                        // onChange={(event) =>
                        //   onModalHandleChange(event.target.name, event.target.value)
                        // }
                      />
                      {touched.lastName && (
                        <p className="text-red-700 error_msg mb-2">
                          {errors.lastName}
                        </p>
                      )}
                        </div></div> 
                      <div className="mt-4"></div>
                      <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                        <div className="col-span-2">
                      <div className="flex">
                        <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium">Email Address </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                      <Input
                        name="email"
                        // label="Email Address"
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        className="mb-2"
                        placeholder="Enter Email address"
                        // onChange={(event) =>
                        //   onModalHandleChange(event.target.name, event.target.value)
                        // }
                      />
                      {touched.email && (
                        <p className="text-red-700 error_msg mb-2">
                          {errors.email}
                        </p>
                      )}
                        </div>
                        <div className="col-span-2">
                      <div className="flex">
                            <label htmlFor="" className="text-sm text-gray-700 font-medium">Phone No. </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                          <input
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            name="studentPhone"
                        type="tel"
                      value={values.studentPhone}
                        placeholder='Enter phone number'
                       // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                      autoComplete="off"
                      onBlur={(e) => {
                        setValues({
                          ...values,
                          studentPhone: e.target.defaultValue,
                        })
                      }}
                      onChange={handleChange}
                    />
                    {touched.studentPhone && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.studentPhone}
                      </p>
                    )}
                        </div>
                      </div>
                      <div className="mt-4"></div>
                      <div className="flex">
                        <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium"> Address Line 1 </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                      <Input
                        name="address1"
                        // label="Address Line 1"
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.address1}
                        className="mb-2"
                        placeholder="Enter Address Line 1"
                        // onChange={(event) =>
                        //   onModalHandleChange(event.target.name, event.target.value)
                        // }
                      />
                      {touched.address1 && (
                        <p className="text-red-700 error_msg mb-2">
                          {errors.address1}
                        </p>
                      )}
                      <div className="mt-4"></div>
                      <Input
                        name="address2"
                        label="Address Line 2"
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.address2}
                        className="mb-2"
                        placeholder="Enter Email address"
                        // onChange={(event) =>
                        //   onModalHandleChange(event.target.name, event.target.value)
                        // }
                      />
                      {touched.address2 && (
                        <p className="text-red-700 error_msg mb-2">
                          {errors.address2}
                        </p>
                      )}
                      <div className="mt-4"></div>
                      <div className="grid grid-cols-4 gap-4">
                        <div className='col-span-2'>
                      <div className="flex">
                        <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium">Parent's First Name </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                      <Input
                        name="parentFirstName"
                        // label="Parent's First Name"
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.parentFirstName}
                        className="mb-2"
                        placeholder="Enter Parent First Name"
                        // onChange={(event) =>
                        //   onModalHandleChange(event.target.name, event.target.value)
                        // }
                      />
                      {touched.parentFirstName && (
                        <p className="text-red-700 error_msg mb-2">
                          {errors.parentFirstName}
                        </p>
                      )}
                        </div>

                        <div className='col-span-2'>
                      <div className="flex">
                        <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium">Parent's Last Name </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                      <Input
                        name="parentLastName"
                        // label="Parent's Last Name"
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.parentLastName}
                        className="mb-2"
                        placeholder="Enter Parent Last Name"
                        // onChange={(event) =>
                        //   onModalHandleChange(event.target.name, event.target.value)
                        // }
                      />
                      {touched.parentLastName && (
                        <p className="text-red-700 error_msg mb-2">
                          {errors.parentLastName}
                        </p>
                      )}
                        </div>
                      </div>
                      <div className="mt-4"></div>
                      <div className="grid grid-cols-4 gap-4">
                        <div className='col-span-2'>
                      <div className="flex">
                        <label htmlFor="" className="mb-1 text-sm text-gray-700 font-medium">  Parent Email Address</label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                      <Input
                        name="parentEmail"
                        // label="Parent Email Address"
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.parentEmail}
                        className="mb-2"
                        placeholder="Enter Parent Email Address"
                        // onChange={(event) =>
                        //   onModalHandleChange(event.target.name, event.target.value)
                        // }
                      />
                      {touched.parentEmail && (
                        <p className="text-red-700 error_msg mb-2">
                          {errors.parentEmail}
                        </p>
                          )}</div>
                        <div className='col-span-2'>
                      <div className="flex">
                        <label htmlFor="contactId" className="mb-1 text-sm text-gray-700 font-medium">Parent's Phone No. </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                          <input
                      name="parentPhone"
                        type="tel"
                      value={values.parentPhone}
                        placeholder='Enter parent phone number'
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      autoComplete="off"
                      onBlur={(e) => {
                        setValues({
                          ...values,
                          parentPhone: e.target.defaultValue,
                        })
                      }}
                      onChange={handleChange}
                    />
                    {touched.parentPhone && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.parentPhone}
                      </p>
                          )}
                        </div>
                      </div>
                      <div className="mt-4"></div>
                      <div className="flex">
                        <label htmlFor="City" className="mb-1 text-sm text-gray-700 font-medium"> City </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                      <Select
                      className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="studentCity"
                      id="studentCity"
                      isSearchable
                      loadingMessage="Getting City..."
                      placeholder="Select City..."
                      value={
                        values.city
                          ? studentCity?.map((l) => ({
                            value: studentCity?.find(
                              (e) => e.name == values.city
                            )?.isoCode,
                              label: studentCity?.find(
                                (e) => e.name == values.city
                              )?.name,
                            }))
                          : null
                      }
                      options={studentCity.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.name,
                      }))}
                      onChange={(option) =>
                      {
                        setValues({
                          ...values,
                          city: option.value,
                        })
                      }}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                    />
                      {touched.city && (
                        <p className="text-red-700 error_msg">{errors.city}</p>
                      )}
                      <div className="mt-4">
                        <div className="flex">
                          <label htmlFor="State" className="mb-1 text-sm text-gray-700 font-medium"> State </label>
                          <span className="text-red-700 ml-1">*</span>
                        </div>
                        <Select
                          className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          name="state"
                          id="state"
                          isSearchable
                          loadingMessage="Getting State..."
                          placeholder="Select State..."
                          value={
                            values.studentState
                              ? studentStates?.map((l) => ({
                                  value: studentStates?.find(
                                    (e) => e.name == values.studentState
                                  )?.isoCode,
                                  label: studentStates?.find(
                                    (e) => e.name == values.studentState
                                  )?.name,
                                }))
                              : null
                          }
                          options={studentStates.map((l) => ({
                            ...l,
                            label: l.name,
                            value: l.name,
                          }))}
                          onChange={(option) => {
                            getCities(
                              option.countryCode,
                              option.isoCode,
                            )
                            setValues({
                              ...values,
                              studentState: option.value,
                            })
                          }}
                          onBlur={handleBlur}
                          menuPlacement="auto"
                          maxMenuHeight={110}
                        />
                      </div>
                      {touched.studentState && (
                        <p className="text-red-700 error_msg">{errors.studentState}</p>
                      )}

                      <div className="mt-4"></div>
                      <div className="flex">
                        <label htmlFor="postCode" className="mb-1 text-sm text-gray-700 font-medium">  Postcode</label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                      <Input
                        name="postCode"
                        // label="Postcode"
                        type="number"
                        className="mb-1.5"
                        autoComplete="off"
                        onBlur={handleBlur}
                        placeholder="Enter Postcode..."
                        onChange={handleChange}
                        value={values.postCode}
                        // onChange={(event) =>
                        //   onModalHandleChange(event.target.name, event.target.value)
                        // }
                      />
                      {touched.postCode && (
                        <p className="text-red-700 mb-2 error_msg">
                          {errors.postCode}
                        </p>
                      )}
                    </>
                  )}*/}
                  {/* End Independent Student */}
                      

                  {/* Start Existing Parent */}
                  {!showFamilyDropdown && (
                    <>
                      <div className="mt-4">
                        <div className="flex">
                          <label htmlFor="selectFamily" className="mb-1 text-sm text-gray-700 font-medium"> Select Family </label>
                          <span className="text-red-700 ml-1">*</span>
                        </div>
                        {values.famil}
                        <Select
                          className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          name="selectFamily"
                          id="selectFamily"
                          isSearchable
                          loadingMessage="Getting Family Details..."
                          placeholder="Select Family..."
                          value={
                            values.selectFamily
                              ? family?.map((l) => ({
                                value: family?.find(
                                  (e) => e.id == values.selectFamily
                                )?.id,
                                label: family?.find(
                                  (e) => e.id == values.selectFamily
                                )?.title + '. ' + family?.find(
                                  (e) => e.id == values.selectFamily
                                )?.lastName + '.....' + family?.find(
                                  (e) => e.id == values.selectFamily
                                )?.email,
                              }))
                              : null
                          }
                          options={family.map((l) => ({
                            ...l,
                            label: l.title + '. ' + l.lastName + '.....' + l.email,
                            value: l.id,
                          }))}
                          onChange={(option) =>
                          {
                            setValues({
                              ...values,
                              selectFamily: option.value,
                            })
                          }}
                          onBlur={handleBlur}
                          menuPlacement="auto"
                          maxMenuHeight={110}
                          onFocus={()=>setFieldTouched('selectFamily',false)}
                        />
                      </div>
                      {touched.selectFamily && (
                        <p className="text-red-700 error_msg">{errors.selectFamily}</p>
                      )}
                      <div className="mt-4"></div>
                      <div className="flex">
                        <label htmlFor="selectFamily" className="mb-1 text-sm text-gray-700 font-medium"> Student First Name </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                      <Input
                        name="firstName"
                        // label="Student First Name"
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName}
                        className="mb-2"
                        placeholder="Enter First Name"
                        onFocus={()=>setFieldTouched('firstName',false)}
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                      />
                      {touched.firstName && (
                        <p className="text-red-700 error_msg mb-2">
                          {errors.firstName}
                        </p>
                      )}
                      <div className="mt-4"></div>
                      <div className="flex">
                        <label htmlFor="selectFamily" className="mb-1 text-sm text-gray-700 font-medium"> Student Last Name </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                      <Input
                        name="lastName"
                        // label="Student Last Name"
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastName}
                        className="mb-2"
                        placeholder="Enter Last Name"
                        onFocus={()=>setFieldTouched('lastName',false)}
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                      />
                      {touched.lastName && (
                        <p className="text-red-700 error_msg mb-2">
                          {errors.lastName}
                        </p>
                      )}
                      <div className="mt-4"></div>
                      <Input
                        name="email"
                        label="Student Email Address"
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        className="mb-2"
                        onFocus={()=>setFieldTouched('email',false)}
                        placeholder="Enter Email address"
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                      />
                      {touched.email && (
                        <p className="text-red-700 error_msg mb-2">
                          {errors.email}
                        </p>
                      )}
                      <div className="mt-4"></div>
                      <label
                        htmlFor="contactId"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Student's Phone No.
                      </label>
                      <Input
                        name="mobileNumber"
                        // label="Student's Phone No."
                        value={values.mobileNumber}
                        placeholder='Enter student phone number'
                        // className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        // onChange={(event) =>
                        //   onModalHandleChange(event.target.name, event.target.value)
                        // }
                        autoComplete="off"
                        onFocus={()=>setFieldTouched('mobileNumber',false)}
                        onBlur={(e) =>
                        {
                          setValues({
                            ...values,
                            mobileNumber: e.target.defaultValue,
                          })
                        }}
                        onChange={handleChange}
                      />
                      {touched.mobileNumber && (
                        <p className="text-red-700 mb-2 error_msg">
                          {errors.mobileNumber}
                        </p>
                      )}
                    </>
                  )}
                  {/* End Existing Parent */}
                </div>
                <div className="mt-4 sm:mt-6">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                  // onClick={() => {
                  //   type === 'add' ? createContact() : updateContact(edit_id)
                  // }}
                  >
                    {isSubmitting
                      ? type === 'add'
                        ? 'Adding...'
                        : 'Saving...'
                      : type === 'add'
                        ? 'Add Contact'
                        : 'Update Contact'}
                  </Button>
                </div>
              </div>
            </form>
          </HalfGeneralSlideover>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
      <ConfirmationDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteContact}
      />
      {renderModal()}
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky min-h-screen">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">Contacts</h1>
            <p className="my-2 text-sm text-gray-700">
              A list of all the contacts.
            </p>
          </div>
          <div className="sm:mt-0 sm:ml-16 sm:flex-none">
            <Button
              onClick={() =>
                setModal((prev) => ({ ...prev, type: 'add', modalState: true }))
              }
              className="flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mt-0 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Add
            </Button>
          </div>
        </div>
        <div className="absolute mt-5 w-7/12 flex justify-end items-center text-right right-0 mr-2">
        </div>
        {/* <Table
          columns={contact_columns({ onEditOpen, onDeleteOpen })}
          data={
            contacts
              ? contacts
                .filter((l) =>
                {
                  if (cityFilter)
                  {
                    if (cityFilter.label != 'No City')
                    {
                      return l?.city == cityFilter.label
                    } else return l?.city == null
                  } else return true
                })
                .filter((l) =>
                {
                  if (gradeFilter)
                  {
                    return l?.grade == gradeFilter.name
                  } else return true
                })
              : null
          }
          onEditClose={cleanModalData}
        /> */}
        {renderTable()}
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false} 
        autoClose={2000}
        hideProgressBar={false}/>
    </Sidebar>
  )
}

export default Contacts
