export const dummy_services = [
  {
    name: 'Meejo',
    cost: 1,
    exam: 'HASL',
  },
  {
    name: 'Eidel',
    cost: 2,
    exam: 'OPMT',
  },
  {
    name: 'Geba',
    cost: 3,
    exam: null,
  },
  {
    name: 'Meeveo',
    cost: 4,
    exam: 'LEAB',
  },
  {
    name: 'Zooveo',
    cost: 5,
    exam: 'KCWC',
  },
  {
    name: 'Talane',
    cost: 6,
    exam: null,
  },
  {
    name: 'Youtags',
    cost: 7,
    exam: 'CYKJ',
  },
  {
    name: 'Twitterbridge',
    cost: 8,
    exam: 'KSBS',
  },
  {
    name: 'Skyba',
    cost: 9,
    exam: 'VGEG',
  },
  {
    name: 'Brainsphere',
    cost: 10,
    exam: 'NTGU',
  },
  {
    name: 'Tagchat',
    cost: 11,
    exam: 'FZAG',
  },
  {
    name: 'Aimbu',
    cost: 12,
    exam: 'SKNA',
  },
  {
    name: 'Tazzy',
    cost: 13,
    exam: 'WSSS',
  },
  {
    name: 'Roodel',
    cost: 14,
    exam: 'EPBP',
  },
  {
    name: 'Tagcat',
    cost: 15,
    exam: 'OIAD',
  },
  {
    name: 'Zoomcast',
    cost: 16,
    exam: 'KBCE',
  },
  {
    name: 'Tekfly',
    cost: 17,
    exam: 'KPMB',
  },
  {
    name: 'Skalith',
    cost: 18,
    exam: 'EDDS',
  },
  {
    name: 'Fliptune',
    cost: 19,
    exam: 'ZYHE',
  },
  {
    name: 'Blogtags',
    cost: 20,
    exam: 'YSHG',
  },
  {
    name: 'Gevee',
    cost: 21,
    exam: 'AYCG',
  },
  {
    name: 'Agivu',
    cost: 22,
    exam: 'GOGS',
  },
  {
    name: 'Avavee',
    cost: 23,
    exam: 'OOKB',
  },
  {
    name: 'Yadel',
    cost: 24,
    exam: 'SVVP',
  },
  {
    name: 'Browsedrive',
    cost: 25,
    exam: 'KLCH',
  },
  {
    name: 'Vimbo',
    cost: 26,
    exam: 'AYWU',
  },
  {
    name: 'Gabcube',
    cost: 27,
    exam: 'KOKK',
  },
  {
    name: 'Midel',
    cost: 28,
    exam: 'CYBA',
  },
  {
    name: 'Jaxnation',
    cost: 29,
    exam: 'KLEM',
  },
  {
    name: 'Vitz',
    cost: 30,
    exam: 'ENHV',
  },
  {
    name: 'Kwimbee',
    cost: 31,
    exam: 'USNN',
  },
  {
    name: 'Tazz',
    cost: 32,
    exam: null,
  },
  {
    name: 'Livetube',
    cost: 33,
    exam: 'CYCW',
  },
  {
    name: 'Browsetype',
    cost: 34,
    exam: 'ZSJJ',
  },
  {
    name: 'Jamia',
    cost: 35,
    exam: 'EDQG',
  },
  {
    name: 'Photolist',
    cost: 36,
    exam: null,
  },
  {
    name: 'Livepath',
    cost: 37,
    exam: 'KSTP',
  },
  {
    name: 'Zoovu',
    cost: 38,
    exam: null,
  },
  {
    name: 'Photolist',
    cost: 39,
    exam: null,
  },
  {
    name: 'Wikizz',
    cost: 40,
    exam: 'OYAT',
  },
  {
    name: 'Livepath',
    cost: 41,
    exam: null,
  },
  {
    name: 'Leenti',
    cost: 42,
    exam: 'KSDL',
  },
  {
    name: 'Rhybox',
    cost: 43,
    exam: 'SSTO',
  },
  {
    name: 'Babbleopia',
    cost: 44,
    exam: null,
  },
  {
    name: 'Eimbee',
    cost: 45,
    exam: 'SAHZ',
  },
  {
    name: 'Quimm',
    cost: 46,
    exam: 'FLKL',
  },
  {
    name: 'Trilia',
    cost: 47,
    exam: 'GATB',
  },
  {
    name: 'Devcast',
    cost: 48,
    exam: 'FTTP',
  },
  {
    name: 'Photobean',
    cost: 49,
    exam: 'AYYO',
  },
  {
    name: 'Linklinks',
    cost: 50,
    exam: 'EFKS',
  },
  {
    name: 'Teklist',
    cost: 51,
    exam: 'ZHAY',
  },
  {
    name: 'Blogtag',
    cost: 52,
    exam: 'OPMP',
  },
  {
    name: 'Twiyo',
    cost: 53,
    exam: 'CYZP',
  },
  {
    name: 'Oodoo',
    cost: 54,
    exam: 'VNJI',
  },
  {
    name: 'Realbridge',
    cost: 55,
    exam: 'KDLN',
  },
  {
    name: 'Dabvine',
    cost: 56,
    exam: 'KOXD',
  },
  {
    name: 'Browsebug',
    cost: 57,
    exam: 'KCIR',
  },
  {
    name: 'Demizz',
    cost: 58,
    exam: 'FNSA',
  },
  {
    name: 'Riffpedia',
    cost: 59,
    exam: 'NTGT',
  },
  {
    name: 'Tagcat',
    cost: 60,
    exam: null,
  },
  {
    name: 'Katz',
    cost: 61,
    exam: 'KHWV',
  },
  {
    name: 'BlogXS',
    cost: 62,
    exam: 'KBUR',
  },
  {
    name: 'Divanoodle',
    cost: 63,
    exam: 'NTKH',
  },
  {
    name: 'Twiyo',
    cost: 64,
    exam: 'SKSV',
  },
  {
    name: 'Yodoo',
    cost: 65,
    exam: 'MYCI',
  },
  {
    name: 'Bluezoom',
    cost: 66,
    exam: 'WBKG',
  },
  {
    name: 'Quaxo',
    cost: 67,
    exam: 'KGDW',
  },
]

export const dummy_location = [
  {
    name: 'Reg',
    address: '23018 Rieder Alley',
    is_active: 'true',
  },
  {
    name: 'Sharia',
    address: '4591 Lillian Junction',
    is_active: 'false',
  },
  {
    name: 'Dalia',
    address: '7030 Di Loreto Park',
    is_active: 'true',
  },
  {
    name: 'Paola',
    address: '52 Westport Trail',
    is_active: 'false',
  },
  {
    name: 'Bliss',
    address: '94571 New Castle Terrace',
    is_active: 'false',
  },
  {
    name: 'Nady',
    address: '60434 Spaight Circle',
    is_active: 'false',
  },
  {
    name: 'Leonid',
    address: '3 Eagle Crest Circle',
    is_active: 'true',
  },
  {
    name: 'Pam',
    address: '7 South Place',
    is_active: 'false',
  },
  {
    name: 'Irwin',
    address: '7 Burning Wood Parkway',
    is_active: 'true',
  },
  {
    name: 'Micky',
    address: '484 Center Place',
    is_active: 'true',
  },
  {
    name: 'Cornie',
    address: '44 Farragut Circle',
    is_active: 'false',
  },
  {
    name: 'Gabie',
    address: '49 Corben Point',
    is_active: 'false',
  },
  {
    name: 'Jacinta',
    address: '8138 Saint Paul Park',
    is_active: 'true',
  },
]

export const dummy_batch = [
  {
    name: 'Judd',
    start_timestamp: '5/31/2022',
    end_timestamp: '12/6/2021',
    location: 1,
    is_active: 'true',
  },
  {
    name: 'Tye',
    start_timestamp: '7/28/2022',
    end_timestamp: '3/24/2022',
    location: 2,
    is_active: 'true',
  },
  {
    name: 'Maynord',
    start_timestamp: '2/19/2022',
    end_timestamp: '6/10/2022',
    location: 3,
    is_active: false,
  },
  {
    name: 'Linda',
    start_timestamp: '2/4/2022',
    end_timestamp: '6/22/2022',
    location: 4,
    is_active: false,
  },
  {
    name: 'Carly',
    start_timestamp: '1/3/2022',
    end_timestamp: '2/22/2022',
    location: 5,
    is_active: 'true',
  },
  {
    name: 'Nichole',
    start_timestamp: '5/23/2022',
    end_timestamp: '6/30/2022',
    location: 6,
    is_active: false,
  },
  {
    name: 'Arlena',
    start_timestamp: '11/20/2022',
    end_timestamp: '11/3/2022',
    location: 7,
    is_active: 'true',
  },
  {
    name: 'Sharona',
    start_timestamp: '8/13/2022',
    end_timestamp: '11/4/2022',
    location: 8,
    is_active: false,
  },
  {
    name: 'Bancroft',
    start_timestamp: '6/27/2022',
    end_timestamp: '11/28/2021',
    location: 9,
    is_active: 'true',
  },
  {
    name: 'Fidelio',
    start_timestamp: '12/13/2021',
    end_timestamp: '8/9/2022',
    location: 10,
    is_active: 'true',
  },
  {
    name: 'Petey',
    start_timestamp: '7/8/2022',
    end_timestamp: '7/6/2022',
    location: 11,
    is_active: false,
  },
  {
    name: 'Wyndham',
    start_timestamp: '12/29/2021',
    end_timestamp: '4/8/2022',
    location: 12,
    is_active: false,
  },
  {
    name: 'Murielle',
    start_timestamp: '11/29/2021',
    end_timestamp: '2/5/2022',
    location: 13,
    is_active: 'true',
  },
]

export const bgColor = [
  'bg-pink-600',
  'bg-purple-600',
  'bg-yellow-500',
  'bg-green-500',
]

export const exams = [
  {
    name: 'Exam One',
    initials: 'EO',
    id: '1',
    members: 16,
    bgColor: 'bg-pink-600',
  },
  {
    name: 'Exam Two',
    initials: 'ET',
    id: '2',
    members: 12,
    bgColor: 'bg-purple-600',
  },
  {
    name: 'Exam Three',
    initials: 'ET',
    id: '3',
    members: 16,
    bgColor: 'bg-yellow-500',
  },
  {
    name: 'Exam Four',
    initials: 'EF',
    id: '4',
    members: 8,
    bgColor: 'bg-green-500',
  },
]

export const single_exam_data = [
  {
    id: 1,
    question: 'What are we doing here and why?',
    answers: [
      {
        answer: 'Maza aa raha hai',
        is_correct: false,
      },
      {
        answer: 'I love it',
        is_correct: true,
      },
      {
        answer: 'Paisa hi Paisa',
        is_correct: false,
      },
      {
        answer: 'Mauj Kardi',
        is_correct: false,
      },
    ],
  },
  {
    id: 2,
    question: 'Who is the poorest man and woman in this entire universe?',
    answers: [
      {
        answer: 'Loading',
        is_correct: false,
      },
      {
        answer: 'Man and Woman',
        is_correct: true,
      },
      {
        answer: 'Let me check',
        is_correct: false,
      },
      {
        answer: 'Help Google',
        is_correct: false,
      },
    ],
  },
  {
    id: 3,
    question:
      'Kya gyaani baba banke baitha hai thoda padh bhi le bhai.. sahi bola na?',
    answers: [
      {
        answer: 'Nahi',
        is_correct: false,
      },
      {
        answer: 'Tu to gaya',
        is_correct: false,
      },
      {
        answer: 'Tu hi dede gyan',
        is_correct: true,
      },
      {
        answer: 'Noted!',
        is_correct: false,
      },
    ],
  },
]
