import { useState, useEffect, useCallback, useMemo } from 'react'
import Modal from '../components/modals'
import Input from '../components/input'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { mail_log_columns } from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import { categorySchema, locationSchema } from '../schema'
import HalfGeneralSlideover from '../components/half-general-slideover'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import moment from 'moment'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import BigSlideover from '../components/big-slideover'
import { CiFilter } from 'react-icons/ci'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    category: '',
  },
}

const initialLocationModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    name: '',
    address: '',
    pointOfContact: '',
    is_active: true,
    accountName: '',
    BSB: '',
    accountNumber: '',
    abn: '',
    gst: '',
    phoneNumber: '',
    emailAddress: '',
    xeroAPIKey: '',
    state: '',
    city: '',
    postCode: '',
    state: '',
    entityName: '',
    entityTypeName: '',
    status: '',
    entityTypeCode: '',
    googleurl: '',
    facebook: '',
    pointOfContact: '',
    brandingThemeID: '',
    sendGridAccount: null
  },
}

const MailLog = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [category, setCategory] = useState([])
  const [locationFilter, setLocationFilter] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [location,setLocation]=useState([])
  const [TabData, setTabData] = useState([])
  // Pagination Start
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  // Pagination End
  const [abnStatus, setAbnStatus] = useState()
  const [entityName, setEntityName] = useState()
  const [gstValue, setGstValue] = useState()
  const [abnError, setAbnError] = useState('')
  const [locationModal, setLocationModal] = useState(initialLocationModalState)
  const [LocationManager, setLocationManager] = useState([])
  const [postCodeError, setPostCodeError] = useState('')
  const [defaultState, setDefaultState] = useState([])
  const [studentState, setStudentState] = useState([])
  const [studentCity, setStudentCity] = useState([])
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false)
  const [filterData, setFilterData] = useState({
    location: '',
    loading: false,
  })

  const handleFilters = () => {
    setFilterData({
      ...filterData,
      loading: true,
    })
    console.log(filterData)
    setLocationFilter(filterData.location)
    setIsFilterSidebarOpen(false)
  }

  const handleClearFilters = () => {
    setLocationFilter()
    setIsFilterSidebarOpen(false)
  }
  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  useEffect(() => {
    getCategoryData()
  }, [page, limit, searchFilter, locationFilter])

  const getLocationData = () => {
    app_api
      .get('/location')
      .then((res) => {

        let data = res.data.filter((l) => l.archive == 0)
        setLoading(false)
        setError(null)
        let locationId = JSON.parse(localStorage.getItem('moldKey')).locationId
        let temp = []
        if (locationId.length == 0) {
          setLocation(data)
        } else {
          for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < locationId.length; j++) {
              if (data[i].id == locationId[j]) {
                temp.push({
                  id: data[i].id,
                  name: data[i].name,
                })
              }
            }
          }
          if (temp) {
            setLocation(temp)
          } else {
            setLocation(data)
          }
        }
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getLocationData()
    getStateDataByBackend()
    getLocationManagerData()
  }, [])

  const getAbnValue = (abnValue) => {
    let guid = "220fe5cd-f965-4f2a-93ce-61fa1626d8cb";
    const url = `https://abr.business.gov.au/json/AbnDetails.aspx?abn=${abnValue}&callback=callback&guid=${guid}`;
    fetch(url)
      .then(response => response.text())
      .then(data => {
        const jsonData = extractJsonData(data);
        if (jsonData.Abn) {
          setGstValue(jsonData.Gst);
          setAbnStatus(jsonData.AbnStatus)
          setEntityName(jsonData.EntityName)
          setAbnError(null);
        }
        else {
          setAbnError('Business not found');
        }

      })
      .catch(error => {
        console.error('An error occurred:', error);
      });

  }

  function extractJsonData(responseText) {
    const startIndex = responseText.indexOf('{');
    const endIndex = responseText.lastIndexOf('}');
    const jsonString = responseText.substring(startIndex, endIndex + 1);
    const jsonData = JSON.parse(jsonString);
    return jsonData;
  }

  useEffect(() => {
    let payload = {
      countryCode: defaultState[0]?.countryCode.toString(),
      isoCode: defaultState[0]?.isoCode.toString(),
    }
    app_api
      .post('/state-city/city', payload)
      .then((res) => {
        let data = res.data
        if (payload.isoCode === 'VIC') {
          data.push({
            countryCode: 'AU',
            latitude: '-37.66955000',
            longitude: '144.88675000',
            name: 'Lyndhurst',
            stateCode: 'VIC',
          })
        }
        setStudentCity(data)
        
        setError(null)
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
  }, [defaultState])

  const getStateDataByBackend = () => {
    app_api
      .post('/state-city/state')
      .then((res) => {
        let data = res.data
        let defaultState = data.filter((l) => l.name == 'Victoria')
        setDefaultState(defaultState)
        setStudentState(data)
        setError(null)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getCities = (countryCode, isoCode) => {
    let payload = {
      countryCode: countryCode?.toString(),
      isoCode: isoCode?.toString(),
    }
    app_api
      .post('/state-city/city', payload)
      .then((res) => {
        let data = res.data
        if (isoCode === 'VIC') {
          data.push({
            countryCode: 'AU',
            latitude: '-37.66955000',
            longitude: '144.88675000',
            name: 'Lyndhurst',
            stateCode: 'VIC',
          })
        }
        setStudentCity(data)
        setError(null)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const checkPostCode = (postCode, state) => {
    if (state != undefined || postCode != undefined) {
      let booleanValue
      if (state == 'Australian Capital Territory') {
        if (postCode.startsWith('02'))
          booleanValue =
            parseInt(postCode) >= parseInt('0200') &&
            parseInt(postCode) <= parseInt('0299')
        else if (parseInt(postCode) >= 2600 && parseInt(postCode) <= 2618)
          booleanValue = true
        else if (parseInt(postCode) >= 2900 && parseInt(postCode) <= 2920)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'New South Wales') {
        if (parseInt(postCode) >= 1000 && parseInt(postCode) <= 2599)
          booleanValue = true
        else if (parseInt(postCode) >= 2619 && parseInt(postCode) <= 2899)
          booleanValue = true
        else if (parseInt(postCode) >= 2921 && parseInt(postCode) <= 2999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'Northern Territory') {
        if (postCode.startsWith('08'))
          booleanValue =
            parseInt(postCode) >= parseInt('0800') &&
            parseInt(postCode) <= parseInt('0899')
        if (postCode.startsWith('09'))
          booleanValue =
            parseInt(postCode) >= parseInt('0900') &&
            parseInt(postCode) <= parseInt('0999')
        else booleanValue = false
      }
      if (state == 'Queensland') {
        if (parseInt(postCode) >= 4000 && parseInt(postCode) <= 4999)
          booleanValue = true
        else if (parseInt(postCode) >= 9000 && parseInt(postCode) <= 9999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'South Australia') {
        if (parseInt(postCode) >= 5000 && parseInt(postCode) <= 5999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'Tasmania') {
        if (parseInt(postCode) >= 7000 && parseInt(postCode) <= 7999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'Victoria') {
        if (parseInt(postCode) >= 3000 && parseInt(postCode) <= 3999)
          booleanValue = true
        else if (parseInt(postCode) >= 8000 && parseInt(postCode) <= 8999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'Western Australia') {
        if (parseInt(postCode) >= 6000 && parseInt(postCode) <= 6797)
          booleanValue = true
        else if (parseInt(postCode) >= 6800 && parseInt(postCode) <= 6999)
          booleanValue = true
        else booleanValue = false
      }
      if (postCode == '') {
        setPostCodeError(null)
        booleanValue = true
      }

      if (booleanValue) {
        setPostCodeError(null)
        return true
      } else {
        setPostCodeError('Invalid postcode')
      }
    }
    else{
      return true
    }
  }

  const getLocationManagerData = () =>{
    app_api
      .get('users/manager-locations')
      .then((res) =>
      {
        let data = res.data.filter(l => l.is_active == true);
        setLoading(false)
        setError(null)
        setLocationManager(data)
      })
      .catch((err) =>
      {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const onEditLocationOpen = (id, index) =>{
    const data = location.filter((e)=>e.id == id)
    setLocationModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: data[0],
    }))
    if(data[0].state){
      let countryCodeName = studentState.find(l => l.name == data[0].state)?.countryCode
      let isoCodeName = studentState.find(l => l.name == data[0].state)?.isoCode
      getCities(countryCodeName, isoCodeName)
    }
  }

  const onInvoiceResend = (id) => {
      app_api
      .get(`/crm-invoice/resendXeroEmail/${id}`)
      .then((res) => {
        toast.success('Successfully')
      })
      .catch((err) => {
        toast.error('Mail not sent')
      })
  }

  const onEditModal = async (id, index) => {
    setModal((prev) => ({
      edit_id: id,
      index: index,
      type: 'add',
      state: true,
      data: TabData[index],
    }))
  }

  const onViewInvoice = async (id, index) => {
    const item = category.find(item => item.id === id);
    let invoiceLink
    if (item) {
        const htmlSnippet = item.emailTemplate
        const regex = /<a\s+href=['"]([^'"]+)['"]\s*>/;
        const match = htmlSnippet.match(regex);
        invoiceLink=match[1];
        window.open(invoiceLink);
    }
  }

  const onResendMail =(id)=>{
    app_api
    .get(`/email-log/resendMail/${id}`)
    .then((res) => {
      toast.success('Successfully')
    })
    .catch((err) => {
      toast.error('Mail not sent')
    })
  }

  const onDeleteOpen = (id, index) => {
    setModal((prev) => ({ ...prev, id: id, index: index }))
    setConfirmationDialog(true)
  }

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const onDeleteCategory = () => {
    const { id, index } = modal
    app_api
      .delete(`/login-session/${id}`)
      .then((res) => {
        toast.success('Deleted Successfully')
        getCategoryData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
      })
  }

  const onModalHandleChange = (name, value) => {
    setModal((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [name]: value,
      },
    }))
  }

  const getCategoryData = () => {
    let email = JSON.parse(localStorage.getItem('moldKey')).email
    let url = `email-log/all?page=${page}&size=${limit}&searchFilter=${searchFilter}&email=${email}`
    if(locationFilter?.value) {
      url = url+`&location=${locationFilter?.value}`
    }
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        setLoading(false)
        setError(null)
        setCategory(res.data)
        setFilterData({
          ...filterData,
          loading: false,
        })
        if(res.count <= limit) setPage(0)
        if (res.count < 15) { setLimit(res.count) } else { setLimit(15) }
        setTabData(res.data)
        if(isNaN(res.count / limit)){setTotalPages(1)}else{setTotalPages(res.count / limit)}
        setCount(res.count)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getCategoryData()
  }, [])



  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    const { category } = data
    return (
      <Formik
        initialValues={data}
        validationSchema={null}
        enableReinitialize
        onSubmit={(values, {setSubmitting}) => {
          
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <HalfGeneralSlideover
            title={type === 'add' ? 'Mail Log' : 'Mail Log'}
            open={state}
            setOpen={() => cleanModalData()}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
                <div className='flex items-center'>
                  <label>Recipent:</label><p className='ml-2 rounded-full bg-orange-200 px-2'>{data.sendTo}</p>
                </div>
                <hr className='mt-3'></hr>
                <div className='flex items-center mt-3'>
                  <label>Subject:</label><p className='ml-2 font-semibold px-2'>{data.subject}</p>
                </div>
                <hr className='mt-3'></hr>
                <div className=""
                  dangerouslySetInnerHTML={{
                    __html: `${data.emailTemplate}`,
                  }}
                ></div>
              </div>
            </form>
          </HalfGeneralSlideover>
        )}
      </Formik>
    )
  }

  const renderLocationModal = () =>{
    const { type, state, edit_id, data } = locationModal
    return (
      <Formik
        initialValues={data}
        validationSchema={locationSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) =>{
          if (type === 'add')
          {
            app_api
              .post('/location', {
                ...values, gst: gstValue, abnStatus: abnStatus,
                entityName: entityName,
              })
              .then((res) =>
              {
                getLocationData()
                cleanModalData()
                setSubmitting(false)
                toast.success('Successfully added location')
                resetForm()
              })
              .catch((err) =>
              {
                toast.error(err.response.data.message)
                getLocationData()
                // cleanModalData()
                setSubmitting(false)
              })
          } else
          {
            app_api
              .patch(`/location/${edit_id}`, { ...values, gst: gstValue })
              .then((res) =>
              {
                let updatedLocationData = [...location]
                updatedLocationData[locationModal.index] = modal.data
                setLocation(updatedLocationData)
                getLocationData()
                toast.success('Successfully updated location')
                cleanModalData()
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {
                toast.error(err.response.data.message)
               })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          setFieldTouched,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
          resetForm,
        }) => (
          <BigSlideover
            open={state}
            setOpen={() =>{
              setLocationModal((prev) => ({ ...prev, state: false }))
            }}
            title={'Location'}
            handleClick={''}
            data={''}
            >
            <form onSubmit={handleSubmit} noValidate>
              <div className="text-left mt-4">
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Name</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  name="name"
                  label="Name"
                  value={values.name}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder='Enter Name...'
                  onFocus={()=>setFieldTouched('name',false)}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
                {touched.name && (
                  <p className="text-red-700 mb-1 error_msg">{errors.name}</p>
                )}
                  </div>
                  <div className="col-span-2">
                <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Point of Contact</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <Select
                      className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="pointOfContact"
                      id="pointOfContact"
                      isSearchable
                      loadingMessage="Getting User..."
                      placeholder="Select a user..."
                    value={
                      values.pointOfContact
                        ? LocationManager?.map((l) => ({
                          value: LocationManager?.find(
                            (e) => e.name == values.pointOfContact
                          )?.id,
                          label: LocationManager?.find(
                            (e) => e.name == values.pointOfContact
                          )?.name,
                        }))
                        : null
                    }
                      options={LocationManager.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.name,
                    }))}
                    onChange={(option) =>
                    {
                      console.log(option)
                      setValues({
                        ...values,
                        pointOfContact: option.value,
                        userId: option.id
                      })
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    onFocus={()=>setFieldTouched('city',false)}
                    maxMenuHeight={110}
                  />
                    {touched.city && (
                      <p className="text-red-700 error_msg mt-2">{errors.city}</p>
                    )}
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className="col-span-2">
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Phone / Mobile Number</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <input
                      name="phoneNumber"
                      label="Mobile / Phone Number"
                      placeholder='Enter Mobile / Phone Number'
                      type="tel"
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      value={values.phoneNumber}
                      autoComplete="off"
                      onFocus={()=>setFieldTouched('phoneNumber',false)}
                      onBlur={(e) =>
                      {
                        setValues({
                          ...values,
                          phoneNumber: e.target.defaultValue,
                        })
                      }}
                      onChange={handleChange}
                    />
                    {touched.phoneNumber && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.phoneNumber}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Email Address</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="emailAddress"
                  label="Email Address"
                  type="text"
                  value={values.emailAddress}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={()=>setFieldTouched('emailAddress',false)}
                  placeholder="Enter Email Address..."
                />
                {touched.emailAddress && (
                  <p className="text-red-700 error_msg">{errors.emailAddress}</p>
                )}
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Address Line 1</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="address"
                  label="Address"
                  type="text"
                  value={values.address}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={()=>setFieldTouched('address',false)}
                  placeholder="Enter Address..."
                />
                {touched.address && (
                  <p className="text-red-700 error_msg">{errors.address}</p>
                )}
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                  <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >City</label>
                    <span className='text-red-700 ml-1'>*</span>
                  </div>
                  <Select
                      className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="studentCity"
                      id="studentCity"
                    isSearchable
                      loadingMessage="Getting City..."
                      placeholder="Select City..."
                    value={
                      values.city
                        ? studentCity?.map((l) => ({
                          value: studentCity?.find(
                            (e) => e.name == values.city
                          )?.isoCode,
                          label: studentCity?.find(
                            (e) => e.name == values.city
                          )?.name,
                        }))
                        : null
                    }
                      options={studentCity.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.name,
                    }))}
                    onChange={(option) =>
                    {
                      setValues({
                        ...values,
                        city: option.value,
                        postCode: ''
                      })
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                    onFocus={()=>setFieldTouched('city',false)}
                  />
                    {touched.city && (
                      <p className="text-red-700 error_msg mt-2">{errors.city}</p>
                    )}
                  </div>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >State</label>
                    <span className='text-red-700 ml-1'>*</span>
                  </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="state"
                      id="state"
                      isSearchable
                      loadingMessage="Getting State..."
                      placeholder="Select a State..."
                      value={
                        values.state
                          ? studentState?.map((l) => ({
                            value: studentState?.find((e) => e.name == values.state)?.isoCode,
                            label: studentState?.find((e) => e.name == values.state)?.name,
                          }))
                          : defaultState ?
                            studentState?.map((l) => ({
                            value: studentState?.find((e) => e.name == defaultState[0].name)?.isoCode,
                            label: studentState?.find((e) => e.name == defaultState[0].name)?.name
                            }))
                          : null
                      }
                      options={studentState.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.name,
                      }))}
                      onChange={(option) =>
                      {
                        getCities(
                          option.countryCode,
                          option.isoCode,
                        )
                        setValues({
                          ...values,
                          state: option.value,
                        })
                        checkPostCode(values.postCode, option.value)
                      }}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                      onFocus={()=>setFieldTouched('state',false)}
                    />
                    {touched.state && (
                      <p className="text-red-700 error_msg">{errors.state}</p>
                    )}
                  </div></div>
                <div className='mt-4'></div>

                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Postcode</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="postCode"
                  label="postCode"
                  type="text"
                  value={values.postCode}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      postCode: e.target.value,
                    })
                    checkPostCode(e.target.value, values.state)
                  }}
                  placeholder="Enter postcode"
                  maxLength={4}
                  onFocus={()=>setFieldTouched('postCode',false)}
                />
                {touched.postCode && (
                  <p className="text-red-700 error_msg">{errors.postCode}</p>
                )}
                {postCodeError && (
                      <p className="text-red-700 error_msg">{postCodeError}</p>
                )}
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Account Name</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="accountName"
                  label="Account Name"
                  type="text"
                  value={values.accountName}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Account Name..."
                  onFocus={()=>setFieldTouched('accountName',false)}
                />
                {touched.accountName && (
                  <p className="text-red-700 error_msg">{errors.accountName}</p>
                )}
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >BSB</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="BSB"
                      label="BSB"
                      type="text"
                      value={values.BSB}
                      autoComplete="off"
                      maxLength="6"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Bank BSB..."
                      onFocus={()=>setFieldTouched('BSB',false)}
                    />
                    {touched.BSB && (
                      <p className="text-red-700 error_msg">{errors.BSB}</p>
                    )}
                  </div>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Account Number</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="accountNumber"
                      label="Account Number"
                      type="text"
                      value={values.accountNumber}
                      autoComplete="off"
                      maxLength="11"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Bank Account Number..."
                      onFocus={()=>setFieldTouched('accountNumber',false)}
                    />
                    {touched.accountNumber && (
                      <p className="text-red-700 error_msg">{errors.accountNumber}</p>
                    )}
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Google My Business</label>
                  {/*<span className='text-red-700 ml-1'>*</span>*/}
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="googleurl"
                  label="Account Number"
                  type="url"
                  value={values.googleurl}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="https://www.google.com/"
                  onFocus={()=>setFieldTouched('googleurl',false)}
                />
                  </div>
                  <div className='col-span-2'>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Facebook</label>
                  {/*<span className='text-red-700 ml-1'>*</span>*/}
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="facebook"
                  label="Account Number"
                  type="url"
                  value={values.facebook}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={()=>setFieldTouched('facebook',false)}
                  placeholder="https://www.facebook.com/"
                />
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >ABN</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <div className='flex'>
                  <input
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="abn"
                    type="text"
                    value={values.abn}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={()=>setFieldTouched('abn',false)}
                    placeholder="Enter Australian Business Number..."
                  />
                  <p className="w-8 rounded-r-md border-y border-r border-gray-300  py-1 appearance-none " onClick={() => getAbnValue(values.abn)}><MagnifyingGlassIcon className='w-full drop-shadow-md' /></p>
                </div>{touched.abn && (
                  <p className="text-red-700 error_msg">{errors.abn}</p>
                )}
                {abnError && (
                  <p className="text-red-700 error_msg">{abnError}</p>
                )}
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >GST Register Date</label>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="gst"
                  label="GST"
                  type="text"
                  value={values.gstValue}
                  autoComplete="off"
                  onBlur={handleBlur}
                  disabled
                  // onChange={handleChange}
                  placeholder={gstValue ? moment(gstValue).format('DD MM YYYY') : null}
                  onFocus={()=>setFieldTouched('gst',false)}
                />
                {touched.gst && (
                  <p className="text-red-700 error_msg">{errors.gst}</p>
                )}
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >ABN Status</label>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="abnStatus"
                  type="text"
                  value={values.abnStatus}
                  autoComplete="off"
                  onBlur={handleBlur}
                  disabled
                  // onChange={handleChange}
                  placeholder={abnStatus}

                />
                  </div><div className='col-span-2'>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Entity Name</label>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="entityName"
                  type="text"
                  value={values.entityName}
                  autoComplete="off"
                  onBlur={handleBlur}
                  disabled
                  placeholder={entityName}
                />
                  </div></div>
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Xero API Key</label>
                  {/* <span className='text-red-700 ml-1'>*</span> */}
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="xeroAPIKey"
                  label="Xero API Key"
                  type="text"
                  value={values.xeroAPIKey}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Xero API Key..."
                  onFocus={()=>setFieldTouched('xeroAPIKey',false)}
                />
                {touched.xeroAPIKey && (
                  <p className="text-red-700 error_msg">{errors.xeroAPIKey}</p>
                )}
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Xero Invoice Branding Theme ID</label>
                  {/* <span className='text-red-700 ml-1'>*</span> */}
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="brandingThemeID"
                  type="text"
                  value={values.brandingThemeID}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Xero Invoice Branding Theme ID..."
                  onFocus={()=>setFieldTouched('brandingThemeID',false)}
                />
                {touched.brandingThemeID && (
                  <p className="text-red-700 error_msg">{errors.brandingThemeID}</p>
                )}
                <div className="mt-4 sm:mt-6"></div>

                <div className="mt-4 sm:mt-6">
                  <Button type="submit"
                    disabled={isSubmitting || postCodeError}>
                    {isSubmitting ? (type === 'add' ? 'Adding...' : 'Saving...') : (type === 'add' ? 'Add Location' : 'Update Location')}
                  </Button>
                </div>
              </div>

            </form>
          </BigSlideover>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
    <ConfirmDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteCategory}
      />
      {renderModal()}
      {renderLocationModal()}
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky min-h-screen">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">Mail Log</h1>
          </div>
        </div>
        {loading ? (
          <div className="flex items-center justify-center">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) :
          <>
        <div className="relative mx-2">
              <div className="w-full flex md:mt-10 mt-16 absolute justify-between gap-2">
                <div></div>
                <div className="flex justify-between gap-4 ">
                  <div
                    onClick={() => setIsFilterSidebarOpen(!isFilterSidebarOpen)}
                    className="rounded-full py-2 px-2 cursor-pointer border shadow-sm bg-indigo-800 hover:bg-indigo-700 text-white"
                  >
                    {filterData.loading ? (
                      <div className="flex items-center justify-center">
                        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status">
                        </div>
                      </div>) : <CiFilter className="w-6 h-6" />}
                  </div>
                </div>
              </div>

              {/* Filter sidebar */}
              {isFilterSidebarOpen && (
                <div className='h-full'>
                  <div className={`absolute bg-white  border rounded-lg shadow-lg w-full  md:w-1/2 lg:w-1/4 md:top-24 md:right-0 top-28 z-50`}>
                    <div className='flex justify-between p-2'>
                      <span className=" text-sm text-gray-700 ">Filters</span>
                      <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                        setFilterData({
                          location: '',
                          loading: false
                        })
                        handleClearFilters()
                      }}> Reset All </span>
                    </div>
                    <hr className='' />
                    <div className="w-full p-2">
                      <div className='flex justify-between mb-2'>
                        <span className=" text-sm text-gray-700 ">Location</span>
                        <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                          setFilterData({
                            ...filterData,
                            location: '',
                          })
                        }}> Reset </span>
                      </div>
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="location"
                        id="location"
                        isSearchable
                        loadingMessage="Getting Location..."
                        placeholder="Location"
                        isClearable={true}
                        options={location?.map((l) => ({
                          ...l,
                          label: l.name,
                          value: l.id,
                        }))}
                        value={filterData.location}
                        onChange={(option) => {
                          setFilterData({
                            ...filterData,
                            location: option,
                          })
                        }}
                      />
                    </div>
                    <hr className='' />
                    <div className="flex justify-between gap-2 p-2">
                      <div className="">
                        <Button
                          type="button"
                          className={'bg-red-500 hover:bg-red-700'}
                          onClick={() => {
                            setFilterData({
                              location: '',
                              loading: false
                            })
                            handleClearFilters()
                          }}
                        >
                          Reset All
                        </Button>
                      </div>
                      <div className="">
                        <Button
                          type="button"
                          onClick={() => {
                            handleFilters()
                          }}
                        >
                          Apply Now
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
        {/* <Table
          columns={login_session_columns({ onDeleteOpen })}
          data={category}
          className="-striped -highlight"
        /> */}
        <Table
          progPage={page}
          totalCount={count}
          columns={mail_log_columns({onEditModal,onResendMail,onViewInvoice,onInvoiceResend,onEditLocationOpen})}
          data={TabData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        />
          <nav
        className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination"
      >
        <div>
          <p className="text-sm text-gray-700">
            Showing{' '}
            <select
              onChange={(e) => {
                setLimit(e.target.value)
                setPage(0)
              }}
              value={limit}
            >
              <option value={count}>{count}</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
            </select>{' '}
            of <span className="font-medium">{count}</span> results
          </p>
        </div>
        <div className="flex items-center md:justify-end">
          <span
            onClick={goPrev}
            className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
          >
            Previous
          </span>
          <span className="text-sm">
            Page{' '}
            <input
              className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
              type="number"
              value={page + 1}
              onChange={(e) => setPage(e.target.value - 1)}
              max={totalPages}
              min="1"
            />
            <span className='ml-2'></span>/ {Math.ceil(totalPages)}
          </span>
          <span
            onClick={goNext}
            className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
          >
            Next
          </span>
        </div>
      </nav>
      </>}
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false} 
        autoClose={2000}
        hideProgressBar={false}/>
    </Sidebar>
  )
}

export default MailLog
