import React, { useState, useRef, useEffect, Fragment } from 'react'
import { Sidebar } from '../components/navigation/sidebar'
import { classNames } from '../helpers/classname'
import { Link, useNavigate } from 'react-router-dom'
import app_api from '../config/api'
import {
  CheckCircleIcon,
  TrashIcon,
  ShoppingCartIcon,
  ArrowRightIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Transition } from '@headlessui/react'
import ResetPasswordStudent from '../components/dialog/reset_pass_student'
import { Dialog } from '@headlessui/react'
import moment from 'moment'

const BuyMore = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [service, setService] = useState([])
  const [cartData, setCartData] = useState([])
  const [isCartPage, setIsCartPage] = useState(false)
  const [resetDialog, setResetDialog] = useState(false)
  const [studentResetData, setStudentResetData] = useState([])
  const [discount, setDiscount] = useState(0)
  const [contactID, setContactID] = useState(0)
  const [exams, setExams] = useState([])
  const [total, setTotal] = useState();

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('moldKey'))
    if (user.email) {
      app_api
        .post('contact/email', { email: user.email })
        .then((res) => {
          setContactID(res.data)
        })
        .catch((err) => {})
    }
    if (!JSON.parse(localStorage.getItem('moldKey')).last_reset_password) {
      setResetDialog(true)
    }
    setStudentResetData(JSON.parse(localStorage.getItem('moldKey')))
    getExamData()
  }, [])

  const Navigate = (value) => {
    setButtonLoading(true)
    let data = {}
    let finalData = []
    value.map((l) => {
      finalData.push(l.id)
    })
    data = {
      enrolment_type: 'online',
      serviceId: finalData,
      contactId: contactID.id,
      sendEmail: true,
    }
    app_api
      .post('/enrolment', {
        enrolment_type: 'online',
        serviceId: finalData,
        contactId: parseInt(contactID.id),
        sendEmail: true,
      })
      .then((res) => {
        if (res) {
          window.location.href = res.data.payment_link_url
        } else {
          toast.error('Something went wrong')
        }
      })
      .catch((err) => {
        toast.error('Something went wrong')
      })
  }

  const getExamData = () => {
    app_api
      .get('/service')
      .then((res) => {
        let data = res.data.filter((l) => l.archive == 0 && l.exams.length == 1)
        try {
          let user = JSON.parse(localStorage.getItem('moldKey'))
          app_api
            .post('enrol-exam/contact/email', { email: user.email })
            .then((res) => {
              setExams(res.data)
              let thirdArray = data.filter((elem) => {
                return !res.data.some((ele) => {
                  return (
                    ele.exam?.id == elem.exams[0].examId &&
                    moment(ele.resettable_expiry) >= moment()
                  )
                })
              })
              setService(thirdArray)
              setLoading(false)
            })
            .catch((err) => {})
        } catch (err) {}
      })
      .catch((err) => {})
  }

  const removeItem = (examId, index) => {
    let data = Array.from(cartData)
    data.splice(index, 1)
    if (data.length == 3 || data.length == 6) {
      setDiscount(5)
    } else {
      setDiscount(0)
    }
    setCartData(data)
  }

  const removeItemSelect = (examId, index) => {
    let data = Array.from(cartData)
    let filteredArray = data.filter((e) => e.id !== examId)
    if (filteredArray.length == 3 || filteredArray.length == 6) {
      setDiscount(5)
    } else {
      setDiscount(0)
    }
    let total = data.reduce((s, { cost }) => s + cost, 0)
    setTotal(total)
    setCartData(filteredArray)
  }

  const setCartDataFunction = (id, index) => {
    let data = service.find((l) => l.id === id)
    // tabdata.push(data)
    let thisCartData = Array.from(cartData)
    thisCartData.push(data)
    if (thisCartData.length == 3 || thisCartData.length == 6) {
      setDiscount(5)
    } else {
      setDiscount(0)
    }
    let total = thisCartData.reduce((s, { cost }) => s + cost, 0)
    setTotal(total)
    setCartData(thisCartData)
  }

  return (
    <>
      <ResetPasswordStudent
        setOpen={setResetDialog}
        open={resetDialog}
        data={studentResetData}
      />
      <Sidebar>
        <div className="max-w-[1640px ] mx-auto p-4">
          <div className="px-2 sm:px-6 lg:px-8">
            {loading ? (
              <div
                className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <>
                <div className="flex flex-wrap mt-6 justify-between items-center">
                  <div className="flex justify-start items-center">
                    <p className="text-3xl font-sans font-bold">
                      Paid Practice Exams for Success
                    </p>
                    {/* Spacer */}
                    <div className="flex hidden md:flex lg:flex xl:flex">
                      <div className="ml-5"></div>
                      <div className="h-6 mt-1 rounded-xl shadow bg-indigo-100 flex justify-start items-center px-1 py-2">
                        <div className="rounded-xl bg-white px-1 text-xs font-semibold text-indigo-700">
                          Discount
                        </div>
                        <div className="ml-2"></div>
                        <p className="text-indigo-600 text-xs pr-1">
                          Buy 3 in $25
                        </p>
                      </div>
                      <div className="ml-5"></div>
                      <div className="h-6 mt-1 rounded-xl shadow bg-indigo-100 flex justify-start items-center px-2 py-2">
                        <p className="text-indigo-600 text-xs">Buy 6 in $55</p>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="hidden sm:ml-6 sm:flex sm:items-center">
                      <button
                        type="button"
                        className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          setIsCartPage(true)
                        }}
                      >
                        <div style={{ position: 'relative' }}>
                          <ShoppingCartIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                          <span className="absolute -mt-10 ml-1 bg-red-800 rounded-2xl w-6 h-6 pb-6 text-white">
                            {cartData ? cartData.length : 0}
                          </span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="container my-1 px-4 md:px-12">
                  <div class="flex flex-wrap -mx-1 lg:-mx-8">
                    {service.map((exam, index) => {
                      return (
                        <>
                          <div
                            class="my-2 px-2 py-2 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3  bg-white"
                            key={exam.name}
                          >
                            <article class="overflow-hidden rounded-lg px-2 border">
                              <p className="text-md font-semibold px-2 py-2">
                                {exam.name}
                              </p>
                              <p className="text-gray-500 text-sm px-2 font-mono"></p>
                              <hr class="h-px mx-2 bg-gray-300 border-0" />
                              <div className="flex justify-between p-2 md:p-2">
                                <p className="text-gray-500 text-sm">
                                  Questions
                                </p>
                                <p className="text-gray-500 text-sm">
                                  {exam?.exams[0]?.exam?.questions?.length}
                                </p>
                              </div>
                              <div className="flex justify-between p-2 md:p-2">
                                <p className="text-gray-500 text-sm">
                                  Max Marks
                                </p>
                                <p className="text-gray-500 text-sm">
                                  {exam?.exams[0]?.exam?.questions?.reduce(
                                    (s, { marks }) => s + marks,
                                    0
                                  )}
                                </p>
                              </div>
                              <div className="flex justify-between p-2 md:p-2">
                                <p className="text-gray-500 text-sm">Time</p>
                                <p className="text-gray-500 text-sm">
                                  {exam?.exams[0]?.exam?.duration} Min
                                </p>
                              </div>
                              <div className="flex justify-between p-2 md:p-2">
                                <p className="text-gray-500 text-sm">
                                  Validity
                                </p>
                                <p className="text-gray-500 text-sm">
                                  {exam?.exams[0]?.exam?.expireInDays} Days
                                </p>
                              </div>
                              {cartData.find((l) => l.id === exam.id) ? (
                                <>
                                  <div className="flex justify-between w-full px-2 rounded-xl mb-2 mt-4">
                                    <span className="flex items-start justify-start">
                                      <CheckCircleIcon className="text-green-800 w-6 h-6" />
                                      <p className="text-green-800 ml-2 font-mono">
                                        {' '}
                                        Added to Cart
                                      </p>
                                    </span>
                                    <button
                                      className="text-red-600 hover:bg-red-700 hover:text-white w-8 items-center justify-center flex h-8 hover:rounded-lg"
                                      onClick={() => {
                                        removeItemSelect(exam.id, index)
                                      }}
                                    >
                                      <TrashIcon className="w-5 h-5" />
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <button
                                  class="flex items-center justify-between bg-indigo-800 text-white pointer w-full p-1 px-4 rounded-xl mb-2 mt-4"
                                  onClick={() =>
                                    setCartDataFunction(exam.id, index)
                                  }
                                >
                                  <p class="text-sm text-center inline-flex justify-center">
                                    Add to cart
                                  </p>
                                  <p className="inline-flex text-sm">
                                    ${exam.cost}
                                  </p>
                                </button>
                              )}
                            </article>
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <Transition.Root show={isCartPage} as={Fragment}>
          <Dialog as="div" className="relative z-40" onClose={setIsCartPage}>
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                      <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              Cart Details
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() => setIsCartPage(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="relative mt-6 flex-1 px-4 sm:px-6">
                          {cartData?.length > 0 ? (
                            <></>
                          ) : (
                            <p className="text-left font-mono text-sm text-gray-500">
                              Nothing added to the cart
                            </p>
                          )}
                          {cartData?.map((l, index) => {
                            return (
                              <>
                                <div className="flex flex-col mt-2">
                                  <div className="w-full bg-white rounded-lg border px-2 py-2 shadow">
                                    <div className="flex items-center justify-between">
                                      <p className="text-md font-semibold">
                                        {l.name}
                                      </p>
                                      <button
                                        className="text-red-600 hover:bg-red-700 hover:text-white w-8 items-center justify-center flex h-8 hover:rounded-lg"
                                        onClick={() => {
                                          removeItem(l.id, index)
                                        }}
                                      >
                                        <TrashIcon className="w-5 h-5" />
                                      </button>
                                    </div>
                                    <hr class="h-px my-2 mx-0 bg-gray-300 border-0" />
                                    <div className="flex justify-between text-sm text-gray-400 mt-2">
                                      <p>Price</p>
                                      <p className="text-indigo-800">
                                        ${l.cost}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          })}
                          {cartData.length > 0 && (
                            <div className="bg-gray-200 rounded-lg shadow border mt-4">
                              <div className="flex justify-between px-2 text-sm font-mono text-gray-700 mt-2">
                                <p>Cart Subtotal:</p>
                                <p>
                                  $
                                  {(cartData.reduce(
                                    (s, { cost }) => s + cost,
                                    0
                                  ))?.toFixed(2)}
                                </p>
                              </div>
                              <div className="flex justify-between px-2 text-sm font-mono text-gray-700 mt-2">
                                <p>Processing fees:</p>
                                <p>
                                ${(((1.75 / 100) * total) + 0.3)?.toFixed(2)}
                                </p>
                              </div>

                              {discount > 0 && (
                                <div className="flex justify-between px-2 text-sm font-mono text-gray-700 pt-1">
                                  <p>Discount:</p>
                                  <p>${discount}</p>
                                </div>
                              )}
                              <hr class="h-px my-2 mx-2 bg-black border-0" />
                              <div className="flex justify-between px-2 text-sm font-mono text-gray-700 pt-1 mb-2">
                                <p>Total Amount Payable:</p>
                                <p>
                                $
                                {((
                                  total +
                                  (((1.75 / 100) * total) + 0.3)
                                ) - discount)?.toFixed(2)}
                                </p>
                              </div>
                            </div>
                          )}
                          {cartData.length > 0 && (
                            <div>
                              <button
                                className="bg-indigo-800 w-full mt-3 rounded-lg text-white px-2 py-2"
                                onClick={() => {
                                  Navigate(cartData)
                                }}
                              >
                                {buttonLoading ? (
                                  <div
                                    role="status"
                                    className=" inline-flex justify-center"
                                  >
                                    <svg
                                      aria-hidden="true"
                                      className="inline-block w-6 h-6 mr-2 text-white mt-1 animate-spin animate-spin-center fill-indigo-800 border-indigo-800 content-center"
                                      viewBox="0 0 100 101"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                      />
                                    </svg>
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                ) : (
                                  <>
                                    <div className="flex justify-between">
                                      <p>Buy Now</p>
                                      <ArrowRightIcon className="w-5 h-5" />
                                    </div>
                                  </>
                                )}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false} 
        autoClose={2000}
        hideProgressBar={false}/>
      </Sidebar>
    </>
  )
}

export default BuyMore
